<template>
  <div v-if="hasPermission(permissions.expensesList)">
    <content-header>{{ $t('expenses') }}</content-header>
    <v-content>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t('fromDate') }}</label>
          <flat-pickr id="fromDate" v-model="fromDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t('toDate') }}</label>
          <flat-pickr id="toDate" v-model="toDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
      </b-row>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
          @on-create="$router.push({name: 'expenses.create'})"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="qty" slot-scope="props">
          {{ props.row.qty.toLocaleString() }}
        </template>
        <template slot="mcTotalPrice" slot-scope="props">
          {{ props.row.mcTotalPrice.toLocaleString() }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format('YYYY-MM-DD') }}
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment :attachment="props.row.attachment" :title="props.row.attachment" />
        </template>
        <template slot="controls" slot-scope="props" v-if="hasPermission(permissions.expensesEdit) || hasPermission(permissions.expensesDelete)">
          <control-buttons
              :props="props"
              :edit-link="hasPermission(permissions.expensesEdit) && 'expenses.edit'"
              :delete-link="hasPermission(permissions.expensesDelete) && 'expenses/DELETE'"
              v-on:successAction="loadData(true)"
          />
          <b-button v-if="hasPermission(permissions.expensesPrint)" @click="prePrintVoucher(props.row.id)" variant="success"><i class="fas fa-print"></i></b-button>
          <div :id="'expenseVoucherReceipt_' + props.row.id" style="display: none">
            <div v-if="projectTenant() === 'Candle'">
              <withdraw-voucher-candle
                  :paidTo="props.row.title"
                  :voucherNo="props.row.id"
                  :voucherDate="props.row.date"
                  :amount="props.row.mcPrice"
                  :reason="props.row.expenseType.name"
                  :note="props.row.note"
                  type="خەرجی"
              />
              <withdraw-voucher-candle
                  :paidTo="props.row.title"
                  :voucherNo="props.row.id"
                  :voucherDate="props.row.date"
                  :amount="props.row.mcPrice"
                  :reason="props.row.expenseType.name"
                  :note="props.row.note"
                  type="خەرجی"
              />
            </div>
            <withdraw-voucher-royal
                v-else
                :voucherNo="props.row.id"
                :voucherDate="props.row.date"
                :paidTo="props.row.withdrawer"
                :amount="props.row.mcPrice"
                :reason="props.row.expenseType.name"
                :note="props.row.note"
            />
          </div>
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {Expense} from "@/models/Expense";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {permissions, serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";
import WithdrawVoucherCandle from "@/views/voucherDesigns/WithdrawVoucherCandle";
import WithdrawVoucherRoyal from "@/views/voucherDesigns/WithdrawVoucherRoyal";
import mixins from "@/mixins";
import flatPickr from "vue-flatpickr-component";
import moment from 'moment';

export default {
  name: "ExpenseList",
  components: {WithdrawVoucherRoyal, WithdrawVoucherCandle, ShowAttachment, ControlButtons, IsLoading, 'v-content': Content, ContentHeader, BootstrapTable, flatPickr},
  data() {
    return {
      fromDate: moment().startOf('month').format('YYYY-MM-DD'),
      toDate: moment().endOf('month').format('YYYY-MM-DD'),
      isLoading: false,
      model: new Expense(),
      columns: [],
      rows: [],
      actions: tableActions(mixins.projectTenant() !== 'MassHills' && this.hasPermission(permissions.expensesCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t('expenseList')),
      serverParams: serverParams,
      selectedRows: [],
      permissions: permissions
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  watch: {
    fromDate: function () {
      this.resetServerParams()
      this.loadData()
    },
    toDate: function () {
      this.resetServerParams()
      this.loadData()
    },
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store.dispatch('expenses/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start         = (queryParams.page - 1) * queryParams.per_page
      serverParams._end           = queryParams.page * queryParams.per_page
      serverParams._sort          = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order         = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search        = queryParams.global_search ?? ''
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate}
      this.loadData()
    },
    resetServerParams() {
      serverParams._start = 0
      serverParams._end   = 10
      serverParams._sort  = ''
      serverParams._order = ''
      serverParams._search = ''
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate}
    },
    prePrintVoucher(index) {
      let printContents = document.getElementById(`expenseVoucherReceipt_${index}`).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  }
}
</script>
