import CryptoJS from "crypto-js";
import moment from "moment";
import Techeye from "./../assets/Techeye.png";
import MassHills from "./../assets/MassHills.png";
import DiplomatiSafiran from "./../assets/DiplomatiSafiran.png";
import Cantal from "./../assets/Cantal.png";
import Candle from "./../assets/Candle.png";
import ShaqlawaView from "./../assets/ShaqlawaView.png";
import RoyalTower from "./../assets/RoyalTower.png";
import ZagrosTowers from "./../assets/ZagrosTowers.png";
import DefaultProfileImage from "./../assets/images/default.svg";
import defaultPermissions from "@/permissions";
import candlePermissions from "@/permissions/candle";
import royalPermissions from "@/permissions/royal";
import _ from "lodash";
import router from "@/router";
// import axios from "axios";
import { HTTP } from "@/plugins/axios";

export default {
  projectTenant() {
    return process.env.VUE_APP_PROJECT_NAME;
  },
  projectTenantName() {
    return this.getLanguage() === "en"
      ? this.projectTenantNameEnglish()
      : this.getLanguage() === "ar"
      ? this.projectTenantNameArabic()
      : this.projectTenantNameKurdish();
  },
  projectTenantNameKurdish() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "تێک ئای";
        break;
      case "MassHills":
        name = "ماس هیڵس";
        break;
      case "DiplomatiSafiran":
        name = "دیپلۆماتی سەفیران";
        break;
      case "Cantal":
        name = "کانتاڵ";
        break;
      case "Candle":
        name = "کاندڵ & کەمپەنیسکی";
        break;
      case "ShaqlawaView":
        name = "شەقڵاوە ڤیو";
        break;
      case "RoyalTower":
        name = "ڕۆیاڵ تاوەر";
        break;
      case "ZagrosTowers":
        name = "زاگرۆس تاوەرس";
        break;
      default:
        name = "تێک ئای";
        break;
    }
    return name;
  },
  projectTenantNameArabic() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "تیک ئای";
        break;
      case "MassHills":
        name = "ماس هیلس";
        break;
      case "DiplomatiSafiran":
        name = "دیبلومات السفراء";
        break;
      case "Cantal":
        name = "کانتال";
        break;
      case "Candle":
        name = "کاندل & کەمپەنیسکی";
        break;
      case "ShaqlawaView":
        name = "شەقڵاوە ڤیو";
        break;
      case "RoyalTower":
        name = "مجمع الرويال ستي";
        break;
      case "ZagrosTowers":
        name = "زاکروس تاورس";
        break;
      default:
        name = "تیک ئای";
        break;
    }
    return name;
  },
  projectTenantNameEnglish() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "Techeye";
        break;
      case "MassHills":
        name = "Mass Hills";
        break;
      case "DiplomatiSafiran":
        name = "Diplomati Safiran";
        break;
      case "Cantal":
        name = "Cantal";
        break;
      case "Candle":
        name = "Candle & Kempeniski";
        break;
      case "ShaqlawaView":
        name = "Shaqlawa View";
        break;
      case "RoyalTower":
        name = "Royal Tower";
        break;
      case "ZagrosTowers":
        name = "Zagros Towers";
        break;
      default:
        name = "Techeye";
        break;
    }
    return name;
  },
  projectTenantEmail() {
    let name;
    switch (this.projectTenant()) {
      case "Techeye":
        name = "info@tech-eye.com";
        break;
      case "Candle":
        name = "info@lelavgroup.com";
        break;
      default:
        name = "Techeye";
        break;
    }
    return name;
  },
  projectTenantLogo() {
    let logo;
    switch (this.projectTenant()) {
      case "Techeye":
        logo = Techeye;
        break;
      case "MassHills":
        logo = MassHills;
        break;
      case "DiplomatiSafiran":
        logo = DiplomatiSafiran;
        break;
      case "Cantal":
        logo = Cantal;
        break;
      case "Candle":
        logo = Candle;
        break;
      case "ShaqlawaView":
        logo = ShaqlawaView;
        break;
      case "RoyalTower":
        logo = RoyalTower;
        break;
      case "ZagrosTowers":
        logo = ZagrosTowers;
        break;
      default:
        logo = Techeye;
        break;
    }
    return logo;
  },
  getAuthUser() {
    return localStorage.getItem("fullName");
  },
  getAuthUsername() {
    return localStorage.getItem("userName");
  },
  getAuthUserProfileImage() {
    return (
      localStorage.getItem("image"),
      localStorage.getItem("image") !== null &&
      localStorage.getItem("image") !== undefined &&
      localStorage.getItem("image") !== ""
        ? this.getImageUrl("/" + localStorage.getItem("image"))
        : DefaultProfileImage
    );
  },
  getCurrentDate(add = 0, type = "M", time = false) {
    if (time) {
      return moment().add(add, type).format("YYYY-MM-DD hh:mm:ss A");
    }
    return moment().add(add, type).format("YYYY-MM-DD");
  },
  differanceBetweenDates(fDate, sDate, type = "months") {
    fDate = new Date(fDate);
    sDate = new Date(sDate);
    if (type === "days") {
      let t1 = fDate.getTime();
      let t2 = sDate.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    } else if (type === "weeks") {
      let t1 = fDate.getTime();
      let t2 = sDate.getTime();
      return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    } else if (type === "months") {
      let d1Y = fDate.getFullYear();
      let d2Y = sDate.getFullYear();
      let d1M = fDate.getMonth();
      let d2M = sDate.getMonth();
      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    } else {
      /// Years
      return sDate.getFullYear() - fDate.getFullYear();
    }
  },
  formatDate(date, format = "YYYY-MM-DD") {
    return moment(date).format(format);
  },
  convertNumbers(number, lang = "ku") {
    if (lang === "ku") {
      let special = [
        "یەکەم",
        "دووەم",
        "سێیەم",
        "چوارەم",
        "پێنجەم",
        "شەشەم",
        "حەوتەم",
        "هەشتەم",
        "نۆیەم",
        "دەیەم",
        "یازدە",
        "دوازده",
        "سێزدە",
        "چواردە",
        "پازدە",
        "شازدە",
        "حەڤدە",
        "هەژدە",
        "نۆزدە",
        "بیست",
        "بیست",
        "بیست و یەك",
        "بست و دوو",
        "بیست و سێ",
        "بیست و چوار",
        "بیست و پێنج",
        "بیست و شەش",
        "بیست و حەوت",
        "بیست و هەشت",
        "بیست و نۆ",
        "سی",
        "سی و یەك",
        "سی و دوو",
        "سی و سێ",
        "سی و چوار",
        "سی و پێنج",
        "سی و شەش",
        "سی و حەوت",
        "سی و هەشت",
        "سی و نۆ",
        "چل",
        "چل و یەك",
        "چل و دوو",
        "چل و سێ",
        "چل و چوار",
        "چل و پێنج",
        "چل وشەش",
        "چل و حەوت",
        "چل و هەشت",
        "چل و نۆ",
        "پەنجا",
        "پەنجاو یەك",
        "پەنجاو دوو",
        "پەنجاو سێ",
        "پەنجاو چوار",
        "پەنجاو پێنج",
        "پەنجاو شەش",
        "پەنجاو حەوت",
        "پەنجاو هەشت",
        "پەنجاو نۆ",
        "شەست",
      ];
      return special[number - 1];
    } else if (lang === "ar") {
      let special = [
        "الأول",
        "الثاني",
        "الثالث",
        "الرابع",
        "الخامس",
        "السادس",
        "السابع",
        "الثامن",
        "التاسع",
        "العاشر",
        "احدة عشر",
        "اثنا عشر",
        "ثلاثة عشر",
        "اربعة عشر",
        "خمسة عشر",
        "ستة عشر",
        "سبعة عشر",
        "ثمانية عشر",
        "تسعة عشر",
        "عشرون",
        "واحد وعشرون",
        "اثنان وعشرون",
        "ثلاثة وعشرون",
        "أربعة وعشرون",
        "خمسة وعشرون",
        "ستة وعشرون",
        "سبعة وعشرون",
        "ثمانية وعشرون",
        "تسعة وعشرون",
        "ثلاثون",
        "واحد وثلاثون",
        "اثنان وثلاثون",
        "ثلاثة وثلاثون",
        "أربعة وثلاثون",
        "خمسة وثلاثون",
        "ستة وثلاثون",
        "سبعة وثلاثون",
        "ثمانية وثلاثون",
        "تسعة وثلاثون",
        "أربعون",
        "واحد وأربعون",
        "اثنان وأربعون",
        "ثلاثة وأربعون",
        "أربعة وأربعون",
        "خمسة وأربعون",
        "ستة وأربعون",
        "سبعة وأربعون",
        "ثمانية وأربعون",
        "تسعة وأربعون",
        "خمسون",
        "واحد وخمسون",
        "اثنان وخمسون",
        "ثلاثة وخمسون",
        "أربعة وخمسون",
        "خمسة وخمسون",
        "ستة وخمسون",
        "سبعة وخمسون",
        "ثمانية وخمسون",
        "تسعة وخمسون",
        "ستون",
      ];
      return special[number - 1];
    }
  },
  getImageUrl(url) {
    if (url) {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      } else {
        return process.env.VUE_APP_BASE_URL + url;
      }
    }
    return "-";
  },
  async getCurrentDateTimeServer() {
    let datetime = "";
    // await axios
    //   .get(`https://worldtimeapi.org/api/timezone/Asia/Baghdad`)
    //   .then((response) => {
    //     datetime = response.data;
    //   })
    await HTTP.get(`/Dashboard/GetCurrentDateTime`)
      .then((response) => {
        datetime = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return datetime;
  },
  async getAmountInText(number, lang = "ku") {
    let language =
      lang === "ku"
        ? `NumberToKurdishText`
        : lang === "ar"
        ? "NumberToTextAr"
        : "NumberToTextEn";
    let amountInText = "";
    await HTTP.get(`/Dashboard/${language}?number=${number}`)
      .then((response) => {
        amountInText = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return amountInText;
  },
  async changeFileToBase64(file) {
    if (file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
    return new Promise((resolve) => {
      resolve(null);
    });
  },
  isNumeric(evt) {
    evt = evt ? evt : window.event;
    let charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  },
  convertArrayToObject(array, index = 0) {
    let returnObj = {};
    array.map((x) => {
      returnObj[x[index]] = "";
    });
    return returnObj;
  },
  dateBetween(
    date,
    fDate,
    sDate,
    fFormat = "YYYY-MM-DDT00:00:00",
    sFormat = "YYYY-MM-DDT23:59:59"
  ) {
    if (!fDate) {
      fDate = new Date().toISOString().slice(0, 10);
    }
    if (!sDate) {
      sDate = new Date().toISOString().slice(0, 10);
    }
    date = moment(date).format(fFormat);
    return moment(date).isBetween(
      moment(fDate).format(fFormat),
      moment(sDate).format(sFormat)
    );
  },
  getLanguage() {
    return (
      localStorage.getItem("locale") ?? process.env.VUE_APP_I18N_LOCALE_DEFAULT
    );
  },
  getLanguageNotEn() {
    let lang =
      localStorage.getItem("locale") ?? process.env.VUE_APP_I18N_LOCALE_DEFAULT;
    if (lang === "en") {
      lang = "ar";
    }
    return lang;
  },
  number2Text(value, lang) {
    if (lang === "en") {
      return "Only " + this.ucFirst(this.writtenNumber(value)) + " Dollars";
    } else if (lang === "ar") {
      return this.writtenNumber(value, { lang: "ar" }) + " دولارا فقط لا غیر ";
    } else {
      return (
        "تەنها " + this.writtenNumber(value, { lang: "ku" }) + " دۆلاری ئەمریکی"
      );
    }
  },
  ucFirst(string, separator = " ") {
    const arr = string.replaceAll("-", separator).split(separator);
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  },
  replacePhone(phoneNumber, oldString = "+964", newString = "0") {
    if (phoneNumber) {
      return phoneNumber.replace(oldString, newString);
    }
    return "";
  },
  removeEmptyAndNull(obj) {
    for (let propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName].length === 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  },
  encrypt(message) {
    return CryptoJS.AES.encrypt(
      message,
      process.env.VUE_APP_ENCRYPTION_KEY
    ).toString();
  },
  decrypt(message) {
    return CryptoJS.AES.decrypt(
      message,
      process.env.VUE_APP_ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
  },
  authUserType() {
    if (localStorage.getItem("userType")) {
      return this.decrypt(localStorage.getItem("userType")).toLowerCase();
    }
    router.push({ name: "login" });
    return false;
  },
  numberFormat(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  hasPartialPayment() {
    return process.env.VUE_APP_PARTIAL_PAYMENT_MODULE === "true";
  },
  hasBankPayment() {
    return process.env.VUE_APP_BANK_PAYMENT_MODULE === "true";
  },
  hasPermission(permission) {
    let userType = this.authUserType().toLowerCase();
    let permissions = [];
    if (this.projectTenant() === "Candle") {
      permissions = candlePermissions;
    } else if (this.projectTenant() === "RoyalTower") {
      permissions = royalPermissions;
    } else {
      permissions = defaultPermissions;
    }
    if (!Array.isArray(permission)) {
      permission = [permission];
    }
    return (
      _.intersection(permission, permissions[userType]).length > 0 ||
      permissions[userType].includes("*")
    );
    // return permissions.every(item => permission.includes(item)) || permissions[userType].includes('*');
  },
  pluck(array, key) {
    return array.map((o) => o[key]);
  },
  prepareTrans(text) {
    return text
      .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  },
  checkString(string) {
    return string !== undefined && string !== null && string !== "";
  },
  separatorString(string, separator = " + ") {
    if (this.checkString(string)) {
      console.log(string.split(separator));
      return [string.split(separator)[0], string.split(separator)[1]];
    }
    return [null, null];
  },
};
