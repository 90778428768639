<template>
  <div v-if="hasPermission(permissions.reportsPayment)">
    <content-header>{{ $t("upcomingPayments") }}</content-header>
    <v-content>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t("fromDate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="fromDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t("toDate") }}</label>
          <flat-pickr
            id="toDate"
            v-model="toDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
      </b-row>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          ${{
            (
              parseFloat(props.row.mcPrice)
            ).toLocaleString()
          }}
        </template>
        <template slot="id" slot-scope="props">
          {{ props.row.id }}
        </template>
        <template slot="date" slot-scope="props">
          {{ formatDate(props.row.date) }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { UpcomingPaymentReport } from "@/models/UpcomingPaymentReport";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "UpcommingPaymentReport",
  components: {
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      model: new UpcomingPaymentReport(),
      columns: [],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t("upcomingPayments"), true, false),
      serverParams: serverParams,
      selectedRows: [],
      selectedTypes: [],
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(),
      permissions: permissions,
    };
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  mounted() {
    this.loadData(true);
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
        serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
      }
      this.isLoading = true;
      this.$store
        .dispatch("reports/GET_UPCOMING_PAYMENT_REPORT", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
      this.loadData();
    },
    resetServerParams() {
      serverParams._start = 0;
      serverParams._end = 10;
      serverParams._sort = "";
      serverParams._order = "";
      serverParams._search = "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
      };
    },
  },
};
</script>
