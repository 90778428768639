<template>
  <div class="buy-contract">
    <content-header>{{ $t("createBuyContract") }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col>
              <select-input
                id="property"
                :name="$t('property')"
                label="name"
                api-link="properties/GET_ALL"
                :api-params="{ OnlyAvailable: true }"
                @itemSelected="propertySelected"
                :return-object="true"
              />
              <select-input
                id="customers"
                :name="$t('customers')"
                label="firstName,middleName,lastName,+phone"
                api-link="customers/GET_ALL"
                @itemSelected="customerSelected"
              />
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('area')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('area')"
                    v-model="property.area"
                  ></b-form-input>
                  <b-form-input
                    :placeholder="$t('zone')"
                    v-model="property.zone"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('extraProperty1')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('extraProperty1')"
                    v-model="property.extraProperty1"
                  ></b-form-input>
                  <b-form-input
                    :placeholder="$t('extraProperty2')"
                    v-model="property.extraProperty2"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('contractNumber')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('contractNumber')"
                    v-model="contractNumber"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('propertyPrice')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    :placeholder="$t('propertyPrice')"
                    v-model="property.finalPrice"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('finalPrice')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :placeholder="$t('finalPrice')"
                    v-model="finalPrice"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('noOfInstallments')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-select
                    v-model="installmentsNumber"
                    :options="installmentsNumberOptions"
                    @change="calculateInstallments"
                  ></b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('periodOfInstallments')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-select
                    v-model="installmentPeriod"
                    :options="installmentPeriodOptions"
                    @change="calculateInstallments"
                  ></b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox switch v-model="isCash">{{
                  $t("isCash")
                }}</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form-checkbox switch v-model="changeInstallments">{{
                  $t("changeInstallments")
                }}</b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!isCash">
            <b-col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("installment") }}</th>
                    <th>{{ $t("percentage") }}</th>
                    <th>{{ $t("percentageOfInstallments") }}</th>
                    <th>{{ $t("date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>{{ $t("prepayment") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="prePayment1Percent"
                          @blur="calculatePrePaymentPercentage"
                          :disabled="!prePayment1IsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="prePayment1"
                          @blur="calculateInstallments"
                          :disabled="prePayment1IsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                id="percentCheckbox"
                                name="percentCheckbox"
                                :value="true"
                                :unchecked-value="false"
                                v-model="prePayment1IsPercent"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="prePayment1Date"
                          @change="calculateInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <tr>
                    <th>{{ $t("installments") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="currentInstallmentsPercent"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('contractNote')">
                        <b-form-textarea
                          id="note"
                          v-model="buyContractNote"
                        ></b-form-textarea>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                  <tr
                    v-for="installment in installments"
                    v-bind:key="installment.index"
                  >
                    <th>{{ installment.index }}</th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="installment.currentPayPrice"
                          :disabled="!changeInstallments"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th colspan="2">
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="installment.currentPayDate"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                  <!-- Loop Here -->
                  <tr>
                    <th>{{ $t("deliveringKeys") }}</th>
                    <th>
                      <b-input-group prepend="%">
                        <b-form-input
                          type="number"
                          v-model="deliveryKeyPercent"
                          @blur="calculateDeliveryKeyPercentage"
                          :disabled="!deliveryKeyIsPercent"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group prepend="$">
                        <b-form-input
                          type="number"
                          v-model="deliveryKey"
                          @blur="calculateInstallments"
                          :disabled="deliveryKeyIsPercent"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-button-group>
                            <b-button variant="info">
                              <b-form-checkbox
                                v-model="deliveryKeyIsPercent"
                                id="deliveryKeyCheckbox"
                                name="deliveryKeyCheckbox"
                                :value="true"
                                :unchecked-value="false"
                              >
                                {{ $t("percent") }}
                              </b-form-checkbox>
                            </b-button>
                          </b-button-group>
                        </b-input-group-append>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          type="date"
                          v-model="deliveryKeyDate"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-form-group>
            <b-input-group
              :prepend="$t('discount')"
              class="mb-2 mr-sm-2 mb-sm-0"
            >
              <b-form-input
                :placeholder="$t('propertyPrice')"
                v-model="discount"
                @change="finalCalculation"
              ></b-form-input>
              <b-form-checkbox
                v-model="showDiscount"
                name="check-button"
                switch
              >
                {{ $t("showDiscount") }}
              </b-form-checkbox>
            </b-input-group>
          </b-form-group>
          <button
            class="btn btn-outline-primary col-md-3"
            type="submit"
            :disabled="isLoading"
            @click="onSubmit"
          >
            <i class="fas fa-plus"></i> {{ $t("create") }}
          </button>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import SelectInput from "@/components/Inputs/SelectInput";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "BuyContractsCreateRoyalTower",
  components: { ContentHeader, "v-content": Content, SelectInput },
  data() {
    return {
      isLoading: false,
      installments: [],
      property: "",
      customer: 0,
      contractNumber: "0",
      installmentsNumber: 8,
      installmentsNumberOptions: [
        { value: 0, text: 0 },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
        { value: 8, text: 8 },
        { value: 9, text: 9 },
        { value: 10, text: 10 }
      ],
      installmentPeriod: 4,
      installmentPeriodOptions: [
        { value: 4, text: 4 },
        { value: 5, text: 5 },
        { value: 6, text: 6 },
        { value: 7, text: 7 },
      ],
      prePayment1: 0,
      prePayment1Percent: 20,
      prePayment1IsPercent: true,
      prePayment1Date: this.$helpers.getCurrentDate(),
      installmentsPercent: 60,
      buyContractNote: "",
      deliveryKey: 0,
      deliveryKeyPercent: 20,
      deliveryKeyIsPercent: true,
      deliveryKeyDate: this.$helpers.getCurrentDate(32),
      currentInstallmentsPercent: 60,
      discount: 0,
      finalPrice: 0,
      isCash: false,
      showDiscount: false,
      changeInstallments: false,
    };
  },
  watch: {
    prePayment1Date: {
      handler() {
        this.calculateInstallments();
      },
      deep: true,
    },
  },
  mounted() {
    this.calculateInstallments();
  },
  methods: {
    customerSelected(customerId) {
      this.customer = customerId;
    },
    propertySelected(property) {
      this.property = property;
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/GET_BUY_CONTRACT_NUMBER")
        .then((response) => {
          this.contractNumber = response.data;
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
      this.finalCalculation();
    },
    finalCalculation() {
      this.calculatePrePaymentPercentage();
      this.calculateDeliveryKeyPercentage();
      this.calculateInstallments();
    },
    calculatePrePaymentPercentage() {
      this.prePayment1 = (this.prePayment1Percent / 100) * this.finalPrice;
      this.calculateInstallments();
    },
    calculateDeliveryKeyPercentage() {
      this.deliveryKey = (this.deliveryKeyPercent / 100) * this.finalPrice;
      this.calculateInstallments();
    },
    calculateInstallments() {
      if (!this.property) {
        return;
      }
      let customInstallments = [];
      let installmentPrice =
        (this.property.finalPrice -
          this.discount -
          this.prePayment1 -
          this.deliveryKey) /
        this.installmentsNumber;
      for (let i = 1; i <= this.installmentsNumber; i++) {
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Installment",
          index: i,
          currentPayPrice: installmentPrice.toFixed(4),
          currentPayDate: moment(this.prePayment1Date)
            .add(this.installmentPeriod * i, "M")
            .format("YYYY-MM-DD"),
        });
      }
      this.installments = customInstallments;
      this.deliveryKeyDate = moment(
        customInstallments[customInstallments.length - 1].currentPayDate
      )
        .add(this.installmentPeriod, "M")
        .format("YYYY-MM-DD");
      if (this.prePayment1IsPercent) {
        this.prePayment1 = (
          (this.prePayment1Percent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.prePayment1Percent =
          (this.prePayment1 / (this.property.finalPrice - this.discount)) * 100;
      }
      if (this.deliveryKeyIsPercent) {
        this.deliveryKey = (
          (this.deliveryKeyPercent / 100) *
          (this.property.finalPrice - this.discount)
        ).toFixed(4);
      } else {
        this.deliveryKeyPercent =
          (this.deliveryKey / (this.property.finalPrice - this.discount)) * 100;
      }
      this.finalPrice = (this.property.finalPrice ?? 0) - this.discount;
      this.currentInstallmentsPercent =
        100 -
        (parseFloat(this.prePayment1Percent) +
          parseFloat(this.deliveryKeyPercent));
    },
    onSubmit() {
      let customInstallments = [];
      if (this.isCash) {
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          currentPayPrice: this.property.finalPrice - this.discount,
          currentPayDate: this.prePayment1Date,
        });
      } else {
        customInstallments = this._.clone(this.installments);
        customInstallments.push({
          isItPrePayment: true,
          isItKeyPayment: false,
          isPayed: false,
          paymentProcessType: "Cash",
          index: 1,
          currentPayPrice: this.prePayment1,
          currentPayDate: this.prePayment1Date,
        });
      }
      if (!this.isCash) {
        customInstallments.push({
          isItPrePayment: false,
          isItKeyPayment: true,
          isPayed: false,
          paymentProcessType: "DeliveryKey",
          currentPayPrice: this.deliveryKey,
          currentPayDate: this.deliveryKeyDate,
        });
      }
      let sum = this._.sumBy(customInstallments, (val) => {
        return parseFloat(val.currentPayPrice);
      });
      if (
        !(
          sum <= this.property.finalPrice + 1 - parseFloat(this.discount) &&
          sum >= this.property.finalPrice - 1 - parseFloat(this.discount)
        )
      ) {
        Swal.fire({
          position: "middle-center",
          icon: "error",
          showConfirmButton: true,
        });
        return;
      }
      if (
        !(
          parseFloat(sum.toFixed(4)) + parseFloat(this.discount) <=
            this.property.finalPrice + 1 &&
          parseFloat(sum.toFixed(4)) + parseFloat(this.discount) >=
            this.property.finalPrice - 1
        )
      ) {
        Swal.fire({
          position: "middle-center",
          icon: "error",
          html:
            this.$t(
              "pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice"
            ) +
            "  <br />" +
            this.$t("propertyPrice") +
            ": " +
            this.property.finalPrice.toLocaleString() +
            " <br />" +
            this.$t("installments") +
            ": " +
            sum.toLocaleString() +
            "  <br />" +
            this.$t("difference") +
            ": " +
            (this.property.finalPrice - sum).toLocaleString(),
          showConfirmButton: true,
        });
        return false;
      }
      let data = {
        buyContract: {
          contractNumber: this.contractNumber,
          buyingDate: this.prePayment1Date,
          buyingPrice: this.property.finalPrice - this.discount,
          buyType: this.isCash ? "Cash" : "Installment",
          isCancelled: false,
          isPrePaymentPayed: false,
          discount: this.discount,
          showDiscount: this.showDiscount,
          propertyId: this.property.id,
          customerId: this.customer,
          paymentProcess: customInstallments,
          note: this.buyContractNote
        },
        propertyExpense: null,
      };
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/CREATE", data)
        .then(() => {
          this.$router.push({ name: "buyContracts" });
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
