<template>
  <div v-if="hasPermission(permissions.expensesCreate)">
    <content-header>{{ $t('createExpense') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="title" :name="$t('title')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <select-input
                id="expenseType"
                :name="$t('expenseType')"
                :validate="$v"
                :required="true"
                api-link="expenseTypes/GET_ALL"
                label="name"
                @itemSelected="e => form.expenseTypeId = e"
            />
            <number-input id="mcPrice" :name="$t('price')" :show-locale-string="true" :validate="$v" :required="true" :is-loading="isLoading" />
            <div class="form-group row">
              <label for="id" class="col-sm-2 col-form-label text-right required-field">{{ $t('safeBox') }}</label>
              <div class="col-sm-10">
                <select class="form-control" v-model="safeBoxId">
                  <option v-for="safeBox in safeBoxes" :key="safeBox.id" :value="safeBox.id">{{ safeBox.name }} ({{ safeBox.mcCurrentBalance.toLocaleString() }})</option>
                </select>
              </div>
            </div>
            <date-time-input id="date" :name="$t('date')" :default-value="getCurrentDate()" :validate="$v" :required="true" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <file-input
                id="attachment"
                :name="$t('attachment')"
                @fileAdded="base64File => form.attachment = base64File"
            />
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required, maxLength } from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {Expense} from "@/models/Expense";
import SubmitInput from "@/components/Inputs/SubmitInput";
import FileInput from "@/components/Inputs/FileInput";
import SelectInput from "@/components/Inputs/SelectInput";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import {permissions, serverParams} from "@/plugins/constants";
import Swal from 'sweetalert2';

export default {
  name: "ExpenseCreate",
  components: {TextAreaInput, DateTimeInput, NumberInput, TextInput, SubmitInput, ContentHeader, 'v-content': Content, IsLoading, FileInput, SelectInput},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Expense().fillAbles),
      isLoading: false,
      selectedItem: {},
      safeBoxId: localStorage.getItem('defaultExpenseSafeBox') ?? 1,
      permissions: permissions,
      serverParams: serverParams,
      safeBoxes: [],
      expenseSafeBox: {}
    }
  },
  mounted() {
    this.$store.dispatch('safeBoxes/GET_ALL', this.serverParams)
      .then((response) => {
        this.safeBoxes = response.data;
        this.expenseSafeBox = this.safeBoxes.find(safeBox => safeBox.id === this.safeBoxId);
      }).catch(() => {});
  },
  validations: {
    form: {
      title: {required, maxLength: maxLength(255)},
      expenseTypeId: {required},
      safeboxId: {},
      mcPrice: {required},
      date: {required},
      note: {},
      attachment: {},
    }
  },
  methods: {
    onSubmit() {
      if (this.form.mcPrice > this.expenseSafeBox.mcCurrentBalance) {
        Swal.fire({
          title: this.$t('notEnoughMoney'),
          html: this.$t('yourCurrentSafeBoxBalanceLowerThanExpense') + '  <br />'
              + this.$t('safeBoxBalance') + ": " + this.expenseSafeBox.mcCurrentBalance.toLocaleString() + " <br />"
              + this.$t('expense') + ": " + parseFloat(this.form.mcPrice).toLocaleString() + "  <br />"
              + this.$t('difference') + ": " + (this.expenseSafeBox.mcCurrentBalance - this.form.mcPrice).toLocaleString() + " <br />"
              + this.$t('areYouSureToContinue'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no'),
        }).then((result) => {
          if (result.isConfirmed) {
            delete this.form.mcTotalPrice;
            this.$v.$touch();
            if (this.$v.$pending || this.$v.$error) return false;
            this.form.safeBoxId = this.safeBoxId;
            this.form.qty = 1;
            this.isLoading = true;
            this.$store.dispatch('expenses/CREATE', this.form).then(() => {
              this.$v.$reset();
              this.$router.push({name: "expenses"})
            }).catch(() => this.isLoading = false);
          }
        })
      } else {
        delete this.form.mcTotalPrice;
        this.$v.$touch();
        if (this.$v.$pending || this.$v.$error) return false;
        this.form.safeBoxId = this.safeBoxId;
        this.form.qty = 1;
        this.isLoading = true;
        this.$store.dispatch('expenses/CREATE', this.form).then(() => {
          this.$v.$reset();
          this.$router.push({name: "expenses"})
        }).catch(() => this.isLoading = false);
      }
    },
  }
}
</script>
