<template>
  <div>
    <content-header>{{ $t("showContractAttachments") }}</content-header>
    <v-content>
      <div class="row">
        <div class="col-md-12">
          <b-button
              variant="outline-primary"
              class="float-right"
              @click.prevent="$router.push({name: 'buyContract.attachment.create', params: { id: $route.params.id },})"
          >
            <i class="fas fa-plus-circle"></i> {{ $t("create") }}
          </b-button>
        </div>
        <div class="col-md-12">
          <b-row>
            <b-col lg="3" md="4" sm="6" v-for="attachment in allAttachments" :key="attachment.id">
              <b-card img-top style="max-width: 20rem;">
                <b-card-header>{{ attachment.title }}</b-card-header>
                <b-link :href="url + attachment.attachment" target="_blank">
                  <b-card-img :src="url + attachment.attachment" :img-alt="attachment.name" width="200" height="200" class="mb-2"/>
                </b-link>
                <boolean-input
                    :show-label="false"
                    id="ShareableForCustomer"
                    :name="$t('sharable')"
                    :load-on-mount="false"
                    :default-value="attachment.shareableForCustomer"
                    :checked-label="$t('share')"
                    :un-checked-label="$t('no')"
                    label-class="col-md-4"
                    input-class="col-md-8"
                    @switched="(e) => switched(e, attachment.id, 'shareable')"
                />
                <boolean-input
                    :show-label="false"
                    id="Is360"
                    :name="$t('is360')"
                    :load-on-mount="false"
                    :default-value="attachment.is360"
                    :checked-label="$t('is360')"
                    :un-checked-label="$t('no')"
                    label-class="col-md-4"
                    input-class="col-md-8"
                    @switched="(e) => switched(e, attachment.id, 'is360')"
                />
                <b-button href="#" variant="danger" @click.prevent="deleteAttachment(attachment.id)">{{ $t('delete') }}</b-button>
              </b-card>
            </b-col>
            <is-loading v-if="isLoading" />
          </b-row>
        </div>
      </div>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import {swalMixin} from "@/plugins/constants";
import BooleanInput from "@/components/Inputs/BooleanInput";
import {permissions} from "@/plugins/constants";

export default {
  name: "BuyContractAttachmentsList",
  components: {BooleanInput, IsLoading, ContentHeader, "v-content": Content,},
  data() {
    return {
      allAttachments: [],
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false,
      permissions: permissions
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store
          .dispatch("buyContracts/GET_ONE", this.$route.params.id)
          .then(async (response) => {
            this.allAttachments = response.data.buyContractAttachments;
            this.isLoading = false;
          })
          .catch(() => (this.isLoading = false));
    },
    switched(value, id, name) {
      value = true;
      this.$store.dispatch('buyContractsAttachments/TOGGLE_DATA', `?id=${id}&${name}=${value}`)
    },
    deleteAttachment(id) {
      this.$store.dispatch('buyContractsAttachments/DELETE', id).then(() => {
        swalMixin('success', this.$t('deletedSuccessfully'));
        this.loadData();
      })
    }
  }
}
</script>
