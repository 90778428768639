<template>
  <div class="contract" dir="rtl">
    <div :style="{ 'padding-top': contractTopSpaces }"></div>
    <h4 class="text-center">بسم الله الرحمن الرحيم</h4>
    <h4 class="text-center">عقد بيع وحدة السكنية</h4>
    <h4 class="text-center">مشروع / الرويال ستي السكني</h4>
    <h5 class="text-center">
      {{ buyType === "Cash" ? "" : "(بشكل أقساط)" }}
    </h5>
    <p class="lead" style="text-align: right; font-size: 1.2rem !important">
      <b>رقم العقد:</b> {{ contractNo }}
    </p>
    <p class="lead" style="text-align: right; font-size: 1.2rem !important">
      <b>التاريخ العقد:</b> {{ formatDate(contract.buyingDate) }}
    </p>
    <br />
    <p class="lead">
      المقدمة: بموجب أجازة الاستثمار المرقمة (144/2017) والصادرة من الهيئة
      الوطنية للاستثمار بتاريخ (25/10/2017) بتشييد مجمع الرويال ستي السكني على
      قطعه الأرض المرقمة (7/20) مقاطعه (30) البيجية وتكليف شركه كه ل بتشييد
      المشروع بموجب العقد الموقع بينهما ويشار إليهما في هذا العقد بالطرف الاول
      وقرارهما بالشروع ببيع الوحدات السكنية والتجارية وبعد اطلاع المشتري, ويشار
      إليه بالطرف الثاني في هذا العقد, على مخططات المشروع وموقع المشروع ومواصفات
      الوحدة السكنية التي يروم شرائها فقد اتفق الطرفان على ما يلي:
    </p>
    <br />
    <!-- Start Royal Tower Info -->
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th colspan="4" class="text-left">الطرف الأول</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">الإسم</th>
          <th>العنوان</th>
          <th>الجنسیة</th>
          <th>رقم الموبایل / قسم العقود</th>
        </tr>
        <tr>
          <td class="text-left">مشروع الرويال ستي السكني</td>
          <td>بغداد البيجية – رقم القطعة 7/20 – رقم المقاطعة 30</td>
          <td>العراق</td>
          <td>07844402004</td>
        </tr>
      </tbody>
    </table>
    <!-- End Royal Tower Info -->

    <!-- Start Customer Info -->
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th colspan="5" class="text-left">الطرف الثاني</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">الإسم</th>
          <th>العنوان</th>
          <th>رقم الهوية</th>
          <th>الجنسیة</th>
          <th>رقم الموبایل</th>
        </tr>
        <tr>
          <td class="text-left">
            {{
              `${customer.firstName} ${customer.middleName} ${customer.lastName}`
            }}
          </td>
          <td>{{ customer.address }}</td>
          <td>{{ customer.idcard }}</td>
          <td>{{ customer.nationality }}</td>
          <td>{{ replacePhone(customer.phone) }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Start Customer Info -->

    <!-- Start Docs Info -->
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th colspan="4" class="text-left">الوثائق والاستمارات</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">نوع الوثيقة/الاستمارة</th>
          <th>مصدرها</th>
          <th>رقمها</th>
          <th>تاريخها</th>
        </tr>
        <tr>
          <td class="text-left">إجازة استثمار</td>
          <td>الهيئة الوطنية للاستثمار</td>
          <td>144/2017</td>
          <td>25-10-2017</td>
        </tr>
      </tbody>
    </table>
    <!-- Start Docs Info -->

    <!-- Start Bank Account Info -->
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th colspan="3" class="text-left">الحساب البنكي</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">أسم البنك</th>
          <th>رقم الحساب</th>
          <th>صاحب الحساب</th>
        </tr>
        <tr>
          <td>المصرف العراقي للتجارة TBI</td>
          <td>IQ67TRIQ993006010234001</td>
          <td>مجمع الرويال ستي السكني</td>
        </tr>
      </tbody>
    </table>
    <!-- Start Bank Account Info -->

    <!-- Start Property Info -->
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th colspan="7" class="text-left">بيانات العقار</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>اسم المبنى</th>
          <th>رقم العقار</th>
          <th>فئة العقار</th>
          <th>رقم الطابق</th>
          <th>نوع العقار</th>
          <th>واجهة العقار</th>
          <th>إطلالة العقار</th>
        </tr>
        <tr>
          <td>{{ property.name }}</td>
          <td>{{ property.block }}</td>
          <td>{{ property.propertyType.name }}</td>
          <td>{{ property.name.split("-")[1] }}</td>
          <td>{{ property.propertyType.name }}</td>
          <td>{{ property.extraProperty1 }}</td>
          <td>{{ property.extraProperty2 }}</td>
        </tr>
        <tr>
          <th>المدينة</th>
          <th>حي</th>
          <th>مقاطعة</th>
          <th>قيمة العقار</th>
          <th>مساحة كلية</th>
          <th>مساحة البناء</th>
          <th>عدد الغرف</th>
        </tr>
        <tr>
          <td>بغداد</td>
          <td>علي الصالح</td>
          <td>البيجية</td>
          <td>
            {{ contract.buyingPrice && contract.buyingPrice.toLocaleString() }}
          </td>
          <td>{{ property.buildUpArea }}</td>
          <td>{{ property.plotArea }}</td>
          <td>{{ property.numberOfRooms }}</td>
        </tr>
        <tr>
          <th>عنوان مشروع الرويال ستي</th>
          <td colspan="6">
            المحافظة: بغداد - حي: علي الصالح – رقم القطعة: 7/20 – رقم المقاطعة:
            30 – أسم المقاطعة: البيجية
          </td>
        </tr>
        <tr>
          <th>مساحة مشروع الرويال ستي</th>
          <td colspan="6">
            29 دونم و 14 اولك و180 متر مربع وهي عبارة عن ملك صرف.
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Start Property Info -->
    <br />
    <br />
    <br />
    <p class="lead">البند الاول: هذا العقد وثيقة ضمان للطرف الاول والثاني.</p>
    <p class="lead">
      البند الثاني: يلتزم الطرف الاول بتسليم الوحدة السكنية للطرف الثاني خلال
      مدة أقصاها (36) ستة وثلاثون شهراً من تاريخ إصدار العقد وحسب خريطة الوحدة
      السكنية المرفقة بهذا العقد.
    </p>
    <div v-if="buyType === 'Installment'">
      <div :style="{ 'padding-top': contractTopSpaces }"></div>
      <div v-if="buyType === 'Installment'">
        <p class="lead" style="text-align: right">
          البند الثالث: نظام الدفعات:
        </p>
        <p class="lead" style="text-align: right">
          يلتزم الطرف الثاني بتسديد المبلغ الاجمالي للوحدة السكنية أعلاه البالغة
          ({{ contract.buyingPrice && contract.buyingPrice.toLocaleString() }})$
          دولار امريكيا حسب النقاط التالية:
        </p>
        <p class="lead" style="text-align: right">
          أ‌- تسديد {{ prePaymentPercent }}% {{ prePaymentPriceText }} بالمائة
          بمبلغ ({{
            contractPrePayments[0].currentPayPrice &&
            contractPrePayments[0].currentPayPrice.toLocaleString()
          }})$ عند توقيع هذا العقد.
        </p>
        <p class="lead" style="text-align: right">
          ب‌- تسديد {{ installementPercent }}%
          {{ installementPriceText }} بالمائة على شكل ({{
            contract.paymentProcess.length - 2
          }}) {{ installemntCountText }} أقساط وحسب الجدول الاتي:
        </p>
        <!-- Start Installments Table -->
        <table class="table table-bordered text-center text-center">
          <thead>
            <tr>
              <th style="background-color: #ccc">جدول الاقساط</th>
              <th style="background-color: #ccc">النسبة</th>
              <th style="background-color: #ccc">المبلغ</th>
              <th style="background-color: #ccc">التاریخ</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(installment, index) in contractPayments"
              :key="installment.id"
            >
              <td style="background-color: #ccc">{{ ++index }}</td>
              <td>
                %
                {{
                  (
                    (installment.currentPayPrice / contract.buyingPrice) *
                    100
                  ).toFixed(1)
                }}
              </td>
              <td>
                ${{
                  installment.currentPayPrice &&
                  installment.currentPayPrice.toLocaleString()
                }}
              </td>
              <td>{{ formatDate(installment.currentPayDate) }}</td>
            </tr>
          </tbody>
        </table>
        <!-- End Installments Table -->
      </div>
      <p class="lead">
        ج- تسديد {{ deliveryKeyPercent }}%
        {{ deliveryPaymentPriceText }} بالمائة بمبلغ ({{
          contractDeliveryKeyPayments.currentPayPrice &&
          contractDeliveryKeyPayments.currentPayPrice.toLocaleString()
        }})$ عند أستلام مفتاح الشقة ويحق للطرف الاول مطالبة الطرف الثاني بكامل
        الاقساط المتبقية قبل التواريخ المحددة في هذا البند في حالة جاهزية الوحدة
        السكنية للتسليم.
      </p>
    </div>

    <p class="lead">
      البند {{ buyType === "Installment" ? "الرابع" : "الثالث" }}: ان الطرف
      الثاني على علم تام بموقع الوحدة السكنية ومواصفاتها والمجمع السكني الذي تقع
      ضمنه الوحدة السكنية.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الخامس" : "الرابع" }} : يلتزم الطرف
      
      الثاني بدفع غرامة تأخيرية قدرها (25$) خمسة وعشرون دولارًا أمريكيًا عن كل
      يوم تأخير للطرف الأول في حال عدم تسديد الاقساط أو احداها وفقًا لجدول
      التسديد المذكور أعلاه. وتصبح الغرامة نافذة المفعول بعد عشرة أيام من تاريخ
      موعد التسديد ، وفي حال لم يتم دفع القسط المستحق خلال مدة أقصاها شهر من
      تاريخ الاستحقاق يعتبر الطرف الثاني ناكلاً لبنود العقد ومن دون الحاجة إلى
      إنذار رسمي أو حكم قضائي مكتسب الدرجة القطعية وقد تراضى الطرفان على ذلك ،
      وعندها للطرف الأول الحق ببيع الوحدة السكنية بالسعر السائد بالسوق ، ويتحمل
      الطرف الثاني فرق الأسعار في حالة هبوطها وفي حالة زيادة السعر عن السعر
      السابق يكون هذا الفرق من نصيب الطرف الأول في هذا العقد ويحق للطرف الأول
      عند بيع الشقة خصم (10%) من قيمة بيع الشقة الى الغير على ان لايتجاوز المبلغ
      المخصوم عن (20%) من اجمالي دفعات الطرف الثاني وتسليم باقي مستحقات الطرف
      الثاني بعد الخصم وعند استلام المبلغ المتفق عليه من المشتري الجديد .
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "السادس" : "الخامس" }}: في حالة طلب
      الطرف الثاني إلغاء العقد بعد توقيعهُ وذلك بطلب رسمي موقع من قبل الطرف
      الثاني يطلب فيه إلغاء العقد عندها يتحمل غرامة مالية قدرها (25%) خمسة
      وعشرون بالمئة من المبالغ المدفوعة بموجب الوصولات المستلمة وتستقطع كتعويض
      عن المصاريف الإدارية والخدمات المتعلقة بالإجراءات التعاقدية ويرد له المبلغ
      المتبقي بعد إعادة بيع الوحدة السكنية إلى أي طرف آخر بدون الحاجة لإنذار
      رسمي أو حكم قضائي مكتسب الدرجة القطعية ويتم ذلك بعد موافقة الطرف الاول على
      الغاء العقد.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "السابع" : "السادس" }}: يلتزم الطرف
      الاول بدفع غرامة تأخيريه قدرها ($25) خمسة وعشرون دولارا امريكيا عن كل يوم
      تأخير للطرف الثاني في حالة تأخر الطرف الأول عن تسليم الوحدة السكنية إلى
      الطرف الثاني بعد انتهاء المدة المتفق عليها بموجب البند الثاني من هذا العقد
      ويتحملها المستثمر (الشركة المنفذة).
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الثامن" : "السابع" }}: في حال تعذر
      الطرف الاول عن تنفيذ هذا العقد نتيجة لظروف طارئة أو قوة قاهرة خارجة عن
      إرادتهُ فلا يتم اعتبارها ضمن فترة العقد.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "التاسع" : "الثامن" }}: عند استمرار
      الظروف الطارئة او القوة القاهرة لأكثر من الفترة الواردة في البند الثاني
      تعلق التزامات الطرفين بما فيها الالتزامات المالية ولا يطبق عليه ما جاء في
      البند الخامس والبند السابع لحين انتهاء الظروف الطارئة او القوة القاهرة
      لتضاف الالتزامات المعلقة فيما بعد إلى مدة ملحق العقد وهذا يكون ملزماً
      للطرفين فور صدور الملحق وإرسال نسخه منه الى الطرف الثاني من خلال إحدى
      وسائل الإرسال الأربعة ألمبينه في البند (15) من هذا العقد.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "العاشر" : "التاسع" }}: يلتزم الطرف
      الاول بتسليم الوحدة السكنية للطرف الثاني بموجب أحكام هذا العقد بمحضر
      تحريري ويلتزم الطرف الثاني باستلام الوحدة السكنية والتوقيع على شهادة
      الانجاز بعد اكمالها مع الأخذ بنظر الاعتبار أن منفعة السكن في الوحدة
      السكنية يعتمد على تعليمات وتوجيهات هيئة الاستثمار.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الحادي عشر" : "العاشر" }}: يلتزم
      الطرف الثاني بدفع كآفة الأجور والرسوم والمصاريف الأخرى المتعلقة بدائرة
      التسجيل العقاري المختصة، وغيرها من المؤسسات المعنية في إطار هذا العقد.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الثاني عشر" : "الحادي عشر" }}: يلتزم
      الطرف الثاني بدفع مستحقات شركة الصيانة الدورية (التي أنشأها الطرف الأول)
      وهي وحدها مسؤولة عن إجراءات الصيانة والأعمال المدنية والصحية والكهربائية
      في الوحدات السكنية والبناية والمناطق المشتركة داخل المشروع.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الثالث عشر" : "الثاني عشر" }}: يلتزم
      الطرف الثاني بدفع الرسوم الدورية الخاصة بخدمات المجمع السكني إلى ادارة
      مجمع رويال ستي السكني من تاريخ استلام الوحدة السكنية وينتقل هذا الالتزام
      الى الطرف الأخر في حالة بيع العقار او تأجيره.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الرابع عشر" : "الثالث عشر" }}: يلتزم
      الطرف الثاني بتقديم طلب موافقة الى الطرف الاول في حال بيع أو تأجير الوحدة
      السكنية بعد شرائها من قبل الطرف الثاني حيث لا يجوز استغلالها او استعمالها
      لأمور مخالفة للنظام العام والآداب والتعليمات الصادرة من ادارة المشروع
      ويتحمل البائع والمشتري الجديد مصاريف التنازل 500$ خمسمائة دولار من كل طرف.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الخامس عشر" : "الرابع عشر" }}: وافق
      الطرف الثاني على أن يتم توجيه أي إشعار أو مراسلة من قبل الطرف الأول بموجب
      هذا العقد بشكل خطي وباللغة العربية أو الانكليزية أو الكردية وتسلم شخصياً
      أو ترسل من خلال خدمة إرسال الطرود أو البريد المسجل في العقد أو خدمة
      الرسائل القصيرة SMS إلى رقم الجوال المسجل في العقد عند الحاجة ويعد الإشعار
      او الإنذار من كاتب العدل من قبل الطرف الأول الى الطرف الثاني قد تم استلامه
      على وفق الشروط الأتية:
    </p>
    <p class="lead">(1) اذا تم تسليمه بشكل شخصي.</p>
    <p class="lead">
      (2) بعد ثلاثة ايام من ارساله من خلال خدمة إرسال الطرود أو البريد المسجل أو
      خدمة الرسائل القصيرة.
    </p>
    <p class="lead">
      (3) عند إرساله عن طريق تطبيقات التواصل الاجتماعي (فايبر أو واتس آب أو
      غيره).
    </p>
    <p class="lead">
      (4) عند ارساله بالبريد الإلكتروني حسب ما يظهر في تقرير الإرسال (تم
      الارسال).
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "السادس عشر" : "الخامس عشر" }} :
      يلتزم الطرف الاول على الموافقة بتسجيل الوحدة السكنية بدائرة التسجيل
      العقاري باسم الطرف الثاني بعد دفع المبلغ الكلي للوحدة السكنية من قبل الطرف
      الثاني .
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "السابع عشر" : "السادس عشر" }} :
      يلتزم الطرف الاول بتسليم الوحدة السكنية الى الطرف الثاني مجهزة بتبريد
      وكاونتر مطبخ وطباخ وفرن ومرشحه مطبخ مع تجهيز الحمامات والصحيات اللازمة.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الثامن عشر" : "السابع عشر" }} :
      يلتزم الطرف الأول المستثمر بصيانة الوحدة السكنية مجاناً لمدة (12 شهراً)
      أثنا عشر شهرا من تاريخ استلام الوحدة السكنية وذلك يشمل ( المرافق والصحيات
      ووحدات التدفئة والتبريد ) ويكون الطرف الأول غير مسؤولٍ عن أية أضرار ناتجة
      عن سوء الاستخدام ويتحمل الطرف الثاني ذلك.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "التاسع عشر" : "الثامن عشر" }} :
      يلتزم الطرف الثاني بعدم اجراء أي تعديل على الوحدة السكنية الا بموافقة
      تحريرية من قبل الطرف الأول.
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "العشرون" : "التاسع عشر" }} : يلتزم
      الطرف الثاني بدفع كافة رسوم الاشتراك بعداد الكهرباء وعداد الغاز وعداد
      المياه والخدمات .
    </p>
    <p class="lead">
      البند {{ buyType === "Installment" ? "الحادي والعشرون" : "العشرون" }} :
      يلتزم الطرف الثاني بإيداع المبالغ المستحقة عليه لحساب مشروع مجمع رويال ستي
      السكني في الرقم الحسابي البنكي المدرج في الصفحة الاولى من هذا العقد حصراً.
    </p>
    <p class="lead">
      البند
      {{ buyType === "Installment" ? "الثاني والعشرون" : "الحادي والعشرون" }} :
      في حال حصول نزاع بين الطرفين بشأن تفسير هذا العقد وتنفيذه فيتم حله ودياً
      خلال مدة لا تتجاوز (30) ثلاثين يوماً من تاريخ تبليغ أحد الطرفين الآخر
      بحصول النزاع وخلافه يمكن اللجوء الى المحكمة المختصة لحل النزاع.
    </p>
    <p class="lead">
      البند
      {{ buyType === "Installment" ? "الثالث والعشرون" : "الثاني والعشرون" }} :
      تطبق أحكام القانون العراقي على هذا العقد وتكون محكمة البداءة التي يقع
      ضمنها العقار هي المختصة بالنظر بالدعاوى الناشئة عن العقد.
    </p>
    <p class="lead">
      البند
      {{ buyType === "Installment" ? "الرابع والعشرون" : "الثالث والعشرون" }} :
      تعتبر المراسلات التي تتم بين الطرفين والتي تتعلق بتنفيذ العقد جزء لا يتجزأ
      منهُ .
    </p>
    <p class="lead">
      البند
      {{ buyType === "Installment" ? "الخامس والعشرون " : "الرابع والعشرون" }} :
      يتكون العقد من (4) صفحات وبـ (3) نسخ أصلية لها القوة القانونية .
    </p>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <table class="table table-bordered signatureTable text-center">
      <thead>
        <tr>
          <th>توقيع الطرف الثاني (أو من يمثله)</th>
          <th>توقيع الطرف الأول</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            أسم المشتري:
            {{
              `${customer.firstName} ${customer.middleName} ${customer.lastName}`
            }}
          </td>
          <td>الاســــــــــم :مشروع الرويال ستي السكني</td>
        </tr>
        <tr>
          <td>العنــــــــــوان: {{ customer.address }}</td>
          <td>العنــــــــوان: بغداد</td>
        </tr>
        <tr>
          <td>رقم الـهاتف: {{ replacePhone(customer.phone) }}</td>
          <td>رقم الهاتف : 07844402004</td>
        </tr>
        <tr>
          <td></td>
          <td>
            تنويه: يتم تسليم العقد الى المشتري بعد مرور 7 أيام من تسديده لدفعة
            المقدمة الى قسم المبيعات العائد للمشروع.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { HTTP } from "../../plugins/axios";
export default {
  name: "ArabicContractRoyalTower",
  props: {
    contract: {},
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    contractNo: {},
    contractPayments: [],
    contractPrePayments: [],
    contractDeliveryKeyPayments: [],
  },
  data() {
    return {
      contractTopSpaces:
        localStorage.getItem("contractTopSpaces") + "px" ?? 100 + "px",
      prePaymentPriceText: "",
      deliveryPaymentPriceText: "",
      prePaymentPercent: (
        (this.contractPrePayments[0].currentPayPrice /
          this.contract.buyingPrice) *
        100
      ).toFixed(),
      deliveryKeyPercent: (
        (this.contractDeliveryKeyPayments.currentPayPrice /
          this.contract.buyingPrice) *
        100
      ).toFixed(),
      installementPercent: "",
      installementPriceText: "",
      installemntCountText: "",
    };
  },
  async mounted() {
    // console.log(this.contract);
    // console.log("sdlojdis",this.contractPrePayments);
    this.installementPercent =
      100 - this.prePaymentPercent - this.deliveryKeyPercent;
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?suffix=false&number=${this.prePaymentPercent}`
    )
      .then((resp) => {
        this.prePaymentPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?suffix=false&number=${this.deliveryKeyPercent}`
    )
      .then((resp) => {
        this.deliveryPaymentPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?suffix=false&number=${this.installementPercent}`
    )
      .then((resp) => {
        this.installementPriceText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${
        process.env.VUE_APP_BASE_API_URL
      }/Dashboard/NumberToTextAr?suffix=false&number=${
        this.contract.paymentProcess.length - 2
      }`
    )
      .then((resp) => {
        this.installemntCountText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
};
</script>

<style scoped>
.content-wrapper {
  background: #ffffff !important;
}

.lead {
  font-size: 1.45rem;
  font-weight: 300;
}

@media print {
  th,
  td {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }
  td {
    font-weight: 100;
  }
}
</style>
