<template>
  <div>
    <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-create="$router.push({name: 'property.expense.create', params: {id: $route.params.id}})"
    >
      <template slot="empty-results">{{ $t('noResultsFound') }}</template>
      <template slot="pagination-info" slot-scope="props">
        <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
        <template v-else>{{ $t('noResultsFound') }}</template>
      </template>
      <template slot="mcPrice" slot-scope="props">
        {{ parseFloat(props.row.mcPrice).toLocaleString() }}
      </template>
      <template slot="date" slot-scope="props">
        {{ moment(props.row.date).format('YYYY-MM-DD') }}
      </template>
      <template slot="note" slot-scope="props">
        {{ props.row.note ? props.row.note : '-' }}
      </template>
      <template slot="attachment" slot-scope="props">
        <show-attachment :attachment="props.row.attachment" :title="props.row.attachment" />
      </template>
      <template slot="controls" slot-scope="props">
        <control-buttons
            :props="props"
            delete-link="propertyExpenses/DELETE"
            v-on:successAction="loadData(true)"
        />
        <b-button @click="prePrintVoucher(props.row.id)" variant="success"><i class="fas fa-print"></i></b-button>
        <div :id="'expenseVoucherReceipt_' + props.row.id" style="display: none">
          <withdraw-voucher-candle
              v-if="projectTenant() === 'Candle'"
              :voucherNo="props.row.id"
              :voucherDate="props.row.date"
              :paidTo="props.row.withdrawer"
              :amount="props.row.mcPrice"
              :reason="props.row.propertyExpenseType.name"
              :note="props.row.note"
          />
          <withdraw-voucher-royal
              v-else
              :voucherNo="props.row.id"
              :voucherDate="props.row.date"
              :paidTo="props.row.withdrawer"
              :amount="props.row.mcPrice"
              :reason="props.row.propertyExpenseType.name"
              :note="props.row.note"
          />
        </div>
      </template>
    </BootstrapTable>
    <is-loading v-if="isLoading" />
  </div>
</template>

<script>
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import {PropertyExpense} from "@/models/PropertyExpense";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";
import WithdrawVoucherCandle from "@/views/voucherDesigns/WithdrawVoucherCandle";
import WithdrawVoucherRoyal from "@/views/voucherDesigns/WithdrawVoucherRoyal";

export default {
  name: "PropertyExpensesList",
  components: {WithdrawVoucherRoyal, WithdrawVoucherCandle, ShowAttachment, IsLoading, ControlButtons, BootstrapTable},
  props: {
    expenses: {
      type: Array
    }
  },
  watch: {
    expenses: function (value) {
      this.rows = value
    }
  },
  data() {
    return {
      isLoading: false,
      model: new PropertyExpense(),
      columns: [],
      rows: this.expenses,
      totalRecords: 0,
      actions: tableActions(true),
      config: tableConfigure(null, false),
      serverParams: serverParams,
      selectedRows: []
    }
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  methods: {
    loadData() {
      this.$emit('loadAll')
    },
    prePrintVoucher(index) {
      let printContents = document.getElementById(`expenseVoucherReceipt_${index}`).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  }
}
</script>
