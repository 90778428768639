export default {
  // Start Generals
  kurdish: "کوردی",
  kurdish_badini: "کوردی بادینی",
  arabic: "عربی",
  english: "English",
  language: "Language",
  controls: "Controls",
  dashboard: "Dashboard",
  kurdishName: "Kurdish name",
  arabicName: "Arabic name",
  englishName: "English name",
  isoCode: "ISO code",
  postalCode: "Postal code",
  city: "City",
  noResultsFound: "No Results Found",
  totalRecords: "Total Records",
  save: "Save",
  yes: "Yes",
  no: "No",
  delete: "Delete",
  someThingWentWrong: "Some Thing Went Wrong",
  settings: "Settings",
  listIsEmpty: "List Is Empty",
  Id: "Id",
  addedSuccessfully: "Added Successfully",
  // End Generals

  // Start Custom Components
  isRequired: "Is Required",
  remove: "Remove",
  fileIsTooBig: "File is too big",
  typeNotSupported: "Type not supported",
  maxFileUploadReach: "Max file upload reach",
  pleaseSelect: "Please select",
  mustHaveAtLeast: "Must Have At Least",
  letters: "Letters",
  mustNotHaveGreaterThen: "Must Not Have Greater Then",
  attachments: "Attachments",
  close: "Close",
  search: "Search",
  multipleDelete: "Multiple Delete",
  multipleRestore: "Multiple Restore",
  multipleForceDelete: "Multiple Force Delete",
  export: "Export",
  noRowsSelected: "No rows selected",
  youWantToDeletePermanentlySelectedRows:
    "You Want To Delete Permanently Selected Rows",
  youWantToRestorePermanentlySelectedRows:
    "You Want To Restore Permanently Selected Rows",
  yesDelete: "Yes, Delete",
  yesRestore: "Yes, Restore",
  yourDataHasBeenDeletedPermanently: "Your Data Has Been Deleted Permanently",
  yourDataHasBeenRestoredPermanently: "Your Data Has Been Restored Permanently",
  // End Custom Components

  // Start Routes
  editProperties: "Edit Properties",
  createCustomer: "Create Customer",
  editCustomer: "Edit Customer",
  createExpenseAttachment: "Create Expense Attachment",
  createPropertyFollowUp: "Create Property FollowUp",
  editLeadSubSource: "Edit Visitor Sub Source",
  buyContractCreate: "Buy Contract Create",
  showBuyContracts: "Show Buy Contracts",
  voucherReceipt: "Voucher Receipt",
  holds: "Holds",
  // End Routes

  // Start 404
  pageNotFound: "Page Not Found",
  oopsThePageYouWereLookingForDoesntExist:
    "Oops!! The page you were looking for doesn't exist",
  backToDashboard: "Back to dashboard",
  notFound: "Not Found",
  // End 404

  // Start 403
  notAuthorized: "Not Authorized",
  oopsYouAreNotAllowedToSeeThisPage:
    "Oops!! you are not allowed to see this page",
  // End 403

  // Start Login
  username: "Username",
  password: "Password",
  login: "Login",
  // End Login

  // Start Sidebar
  customers: "Customers",
  buyers: "Buyers",
  list: "List",
  create: "Create",
  properties: "Properties",
  additionalPlotArea: "Additional Plot Area",
  createAndNew: "Create & New",
  propertyHolds: "Property Holds",
  propertyTypes: "Property Types",
  villaTypes: "Villa Types",
  propertyExpenseTypes: "Property Expense Types",
  features: "Features",
  buyContracts: "Buy Contracts",
  transferContracts: "Transfer Contracts",
  cancelContracts: "Cancel Contracts",
  isCash: "Is Cash?",
  leadSources: "Visitor Sources",
  leadSubSources: "Visitor Sub Sources",
  leadCustomers: "Visitor Customers",
  leadVisits: "Visitor Visits",
  expenses: "Expenses",
  expenseTypes: "Expense Types",
  reports: "Reports",
  leadReports: "Visitor Reports",
  transactions: "Transactions",
  logout: "Logout",
  initials: "Initials",
  contracts: "Contracts",
  leads: "Visitors",
  createLeadVisits: "Create Visitor Visits",
  createLeadCustomers: "Create Visitor Customers",
  accounting: "Accounting",
  // End Sidebar

  // Start Main Dashboard
  mainDashboard: "Main Dashboard",
  projectDashboard: "Project Dashboard",
  totalProperties: "Total Properties",
  totalExpenses: "Total Expenses",
  // End Main Dashboard

  // Start Dashboard
  todayVisits: "Today Visits",
  totalVisits: "Total Visits",
  todayContracts: "Today Contracts",
  totalContracts: "Total Contracts",
  todaySummary: "Today Summary",
  generalData: "General Data",
  invoiceNumber: "Invoice Number",
  referenceNumber: "Reference Number",
  withdraw: "Withdraw",
  reason: "Reason",
  expiredPropertyHolds: "Expired Property Holds",
  fromDate: "From Date",
  toDate: "To Date",
  upcomingPayments: "Upcoming Payments",
  upcomingPaymentsList: "Upcoming Payments List",
  remainingPayableAmount: "Remaining Payable Amount",
  payableAmount: "Payable Amount",
  payments: "Payments",
  next10Days: "Next 10 Days",
  next20Days: "Next 20 Days",
  delayed: "Delayed",
  total: "Total",
  remain: "Available ",
  remain2: "Remain",
  sold: "Sold",
  notSold: "Not Sold",
  hold: "Hold",
  back: "Back",
  soldProperties: "Sold",
  remainedProperties: "Remained",
  onHoldProperties: "On Hold",
  reset: "Reset",
  cancelledContracts: "Cancelled Contracts",
  collectedMoney: "Collected Money",
  currentContractCount: "Current Contract Count",
  propertyExpenses: "Property Expenses",
  remainPrices: "Remain Prices",
  returnedPrices: "Returned Prices",
  remainAmount: "Remain Amount",
  returnedAmount: "Returned Amount",
  safeBox: "Safe Box",
  remainToCollect: "Remain To Collect",
  price: "Price",
  totalSells: "Total Sells",
  holdInfo: "Hold Info",
  // End Dashboard

  // Start Customers
  customersList: "Customers List",
  firstName: "First Name",
  middleName: "Middle Name",
  lastName: "Last Name",
  gender: "Gender",
  birthdate: "Birthdate",
  address: "Address",
  email: "Email",
  phone: "Phone",
  phone2: "Phone 2",
  nationality: "Nationality",
  idCard: "Id Card",
  foodCard: "Food Card",
  informationCard: "Information Card",
  placementCard: "Placement Card",
  residencyCard: "Nationality Card ( Resident )",
  securitySupport: "Security Support",
  note: "Note",
  job: "Job",
  jnsia: "Jnsia",
  fullName: "Full Name",
  male: "Male",
  hasAccount: "Has Account",
  edit: "Edit",
  attachment: "Attachment",
  createCustomerAttachment: "Create Customer Attachment",
  sharable: "Shareable",
  is360: "Is 360",
  title: "Title",
  shareableForCustomer: "Shareable For Customer",
  share: "Share",
  deletedSuccessfully: "Deleted Successfully",
  // End Customers

  // Start Properties
  createProperties: "Create Properties",
  name: "Name",
  priceMeter: "Meter Price",
  floor: "Floor",
  villaNumber: "Villa Name",
  propertyNumber: "Property Number",
  villaType: "Villa Type",
  additionalInfo: "Additional Info",
  zone: "Zone",
  tower: "Tower",
  extraProperty1: "Frontispiece",
  extraProperty2: "Face",
  apartmentType: "Villa Type",
  apartmentTypes: "Villa Types",
  apartmentTypesList: "Villa Type List",
  apartment: "Villa",
  apartmentPrice: "Villa Price",
  block: "Block",
  streetNumber: "Street Number",
  streetWidth: "Street Width",
  rooms: "Rooms",
  floors: "Floors",
  realEstateAgent: "Real Estate Agent",
  propertyType: "Property Type",
  plotArea: "Standard Plot Area",
  buildUpArea: "Unit Price Of Additional Land",
  holdArea: "Hold Area",
  soldArea: "Sold Area",
  meterPrice: "Unit Price Per Built Up Area",
  amount: "Amount",
  area: "Actual Plot Area",
  variance: "Variance",
  extraCost: "Extra Cost",
  initialPrice: "Initial Price",
  feature: "Feature",
  finalPrice: "Final Price",
  numberOfRooms: "Number Of Rooms",
  followUp: "Follow Up",
  createPropertyAttachment: "Create Property Attachment",
  date: "Date",
  deadline: "Deadline",
  done: "Done",
  propertiesList: "Properties List",
  transferred: "Transferred",
  cancelled: "Cancelled",
  area2: "Area",
  meterPrice2: "Meter Price",
  meterPriceAfterDiscount: "M.P.D.",
  buyPriceAfterDiscount: "P.A.D.",
  totalPaidPrice: "T.P.P",
  purchase2: "Cash",
  // End Properties

  // Start Property Hold
  propertyName: "Property Name",
  holdPayment: "Hold Payment",
  propertyHoldsList: "Property Holds List",
  createPropertyHold: "Create Property Hold",
  showPropertyHold: "Show Property Hold",
  property: "Property",
  isCustomer: "Is Customer?",
  payType: "Pay Type",
  bankName: "Bank Name",
  bankSlipNumber: "Bank Slip Number",
  bankSlipDate: "Bank Slip Date",
  bankSlipNote: "Bank Slip Note",
  isBankPayment: "Is Bank Payment?",
  bankSlipAttachment: "Bank Slip Attachment",
  // End Property Hold

  // Start Property Type
  propertyTypesList: "Property Types List",
  createPropertyType: "Create Property Type",
  editPropertyType: "Edit Property Type",
  // End Property Type

  // Start Feature
  extraPrice: "Extra Price",
  featuresList: "Features List",
  createFeature: "Create Feature",
  editFeature: "Edit Feature",
  // End Feature

  // Start Buy Contract
  contractNumber: "Contract Number",
  contractNumber2: "Number",
  customerName: "Customer Name",
  buyingDate: "Buying Date",
  buyingPrice: "Buying Price",
  buyContractsList: "Buy Contracts List",
  createBuyContract: "Create Buy Contract",
  paidPrice: "Paid Price",
  street: "Street",
  propertyPrice: "Property Price",
  installments: "Installments",
  noOfInstallments: "No. Of Installments",
  periodOfInstallments: "Period Of Installments",
  installment: "Installment",
  percentage: "Percentage",
  percentageOfInstallments: "Percentage Of Installments",
  prepayment: "Down Payment",
  percent: "Percent",
  deliveringKeys: "Delivering Keys",
  discount: "Discount",
  showDiscount: "Show Discount",
  pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice:
    "Please Check Installments The Sum Of Installments Is Not Equal To Initial Property Price",
  showContract: "Show Contract",
  print: "Print",
  withExpense: "Other Expense",
  printKurdishContract: "Print Kurdish Contract",
  printArabicContract: "Print Arabic Contract",
  isPaid: "Is Paid",
  action: "Action",
  paidBy: "Paid By",
  pay: "Pay",
  partialPaymentPrice: "Partial Payment Price",
  partialPaymentNote: "Partial Payment Note",
  partialPaymentDate: "Partial Payment Date",
  partialPaymentDeadline: "Partial Payment Deadline",
  extraNote: "Extra Note",
  partialPay: "Partial Pay",
  areYouSure: "Are you sure?",
  youWantToMakePayment: "You want to make payment!",
  noCancel: "No, Cancel",
  paymentSuccessfullyDone: "Payment Successfully Done",
  payment: "Payment",
  printPayments: "Print Payments",
  transferContract: "Transfer Contract",
  customer: "Customer",
  cancelContract: "Cancel Contract",
  changeInstallments: "Change Installments",
  difference: "Difference",
  addAttachments: "Add Attachments",
  paymentToTransfer: "Payment To Transfer",
  with: "Transfer Payment",
  paymentPriceAfter: "Payment Price After",
  discountAr: "Discount Ar",
  discountKu: "Discount Ku",
  // End Buy Contract

  // Start Visitor Sources
  leadSourcesList: "Visitor Sources List",
  createLeadSource: "Create Visitor Source",
  editLeadSource: "Edit Visitor Source",
  // End Visitor Sources

  // Start Visitor Sub Sources
  leadSourceName: "Visitor Source Name",
  leadSubSourcesList: "Visitor Sub Sources List",
  createLeadSubSource: "Create Visitor Sub Source",
  leadSource: "Visitor Source",
  editSubLeadSource: "Edit Sub Visitor Source",
  // End Visitor Sub Sources

  // Start Visitor Customers
  isMale: "Is Male",
  leadSubSourceName: "Visitor Sub Source Name",
  state: "State",
  males: "Males",
  females: "Females",
  visitNote: "Visit Note",
  leadCustomersList: "Visitor Customers List",
  createLeadCustomer: "Create Visitor Customer",
  female: "Female",
  leadSubSource: "Visitor Sub Source",
  customerNote: "Customer Note",
  editLeadCustomer: "Edit Visitor Customer",
  // End Visitor Customers

  // Start Visitor Visits
  leadCustomer: "Visitor Customer",
  leadCustomerPhone: "Visitor Customer Phone",
  newState: "New State",
  oldState: "Old State",
  visitDate: "Visit Date",
  leadVisitsList: "Visitor Visits List",
  createLeadVisit: "Create Visitor Visit",
  // End Visitor Visits

  // Start Expenses
  expenseType: "Expense Type",
  mcPrice: "Price",
  qty: "Qty",
  mcTotalPrice: "Total Price",
  expenseList: "Expense List",
  createExpense: "Create Expense",
  editExpense: "Edit Expense",
  safeBoxBalanceError: "Safe Box Balance Error",
  notEnoughMoney: "Not Enough Money",
  yourCurrentSafeBoxBalanceLowerThanExpense:
    "Your Current Safe Box Balance Lower Than Expense",
  safeBoxBalance: "Safe Box Balance",
  expense: "Expense",
  areYouSureToContinue: "Are You Sure To Continue?",
  // End Expenses

  // Start Expense Types
  expenseTypeList: "Expense Type List",
  createExpenseType: "Create Expense Type",
  description: "Description",
  editExpenseType: "Edit Expense Type",
  // End Expense Types

  // Start Visitor Reports
  selectDate: "Select Date",
  leadVisitByDayOfWeek: "Visitor Visit By Day Of Week",
  dailyLeadVisits: "Daily Visitor Visits",
  perSources: "Per Sources",
  women: "Women",
  men: "Men",
  day: "Day",
  noOfGroups: "No. of Groups",
  visitsByDate: "Visits By Date",
  id: "Id",
  type: "Type",
  detail: "Detail",
  // End Expense Types

  // Start Transfer Contracts
  transferContractList: "Transfer Contract List",
  fromCustomer: "From Customer",
  toCustomer: "To Customer",
  oldContract: "Old Contract",
  newContract: "New Contract",
  showTransferContract: "Show Transfer Contract",
  printArabic: "Print Arabic",
  printKurdish: "Print Kurdish",
  printArabicOrder: "Print Arabic (Order)",
  printKurdishOrder: "Print Kurdish (Order)",
  // End Transfer Contracts

  // Start Cancel Contracts
  cancelContractList: "Cancel Contract List",
  returnedPrice: "Deducted Amount",
  remainPrice: "Remain Price",
  buyContract: "Buy Contract",
  showCancelContract: "Show Cancel Contract",
  // End Cancel Contracts

  // Start Property Expense Types
  propertyExpenseTypesList: "Property Expense Types List",
  createPropertyExpenseType: "Create Property Expense Type",
  editPropertyExpenseType: "Edit Property Expense Type",
  propertyExpenseType: "Property Expense Type",
  // End Property Expense Types

  // Start Property Report
  propertyReport: "Property Report",
  partialPaymentReport: "Partial Payment Report",
  // End Property Report

  // Start Expense By Type
  expensesByType: "Expenses By Type",
  // End Expense By Type

  // Start Settings
  propertySettings: "Property Settings",
  contractSettings: "Contract Settings",
  contractTopSpaces: "Contract Top Spaces",
  safeBoxSettings: "Safe Box Settings",
  defaultExpenseSafeBox: "Default Expense Safe Box",
  // End Settings

  // Start Projects
  logo: "Logo",
  projectsList: "Projects List",
  editProject: "Edit Project",
  // End Projects

  // Start Users
  users: "Users",
  usersList: "Users List",
  phoneNumber: "Phone Number",
  showUser: "Show User",
  project: "Project",
  projects: "Projects",
  addProjectToUser: "Add Project To User",
  addProjectToCustomer: "Add Project To Customer",
  addToProject: "Add To Project",
  // End Users

  // Start Permissions
  permissions: "Permissions",
  permissionsList: "Permissions List",
  editPermission: "Edit Permission",
  permissionsSaved: "Permissions Saved",
  errorInSavingPermissions: "Error In Saving Permissions",
  canPayContractPayment: "Can Pay Contract Payment",
  canAddContract: "Can Add Contract",
  canAddProperty: "Can Add Property",
  canAddHoldProperty: "Can Add Hold Property",
  canDeleteHoldProperty: "Can Delete Hold Property",
  canCreateExpense: "Can Create Expense",
  canViewTransaction: "Can View Transaction",
  canViewExpenseByTypeReport: "Can View Expense By Type Report",
  canTransferContract: "Can Transfer Contract",
  canCancelContract: "Can Cancel Contract",
  // End Permissions

  // Start Profile
  accountInfo: "Account Information",
  profile: "Profile",
  updateAvatar: "Update Avatar",
  updatePassword: "Update Password",
  oldPassword: "Old Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  pleaseWriteFullName: "Please Write Full Name",
  namesMustBeMoreThan3Chars: "Names Must Be More Than 3 Chars",
  updated: "Updated",
  pleaseWriteAllPasswords: "Please Write All Passwords",
  passwordsAreNotMatch: "Passwords Are Not Match",
  updateInfo: "Update Info",
  // End Profile

  // Start Transactions
  totalIncomes: "Total Incomes",
  totalOutcomes: "Total Outcomes",
  all: "All",
  incomes: "Incomes",
  outcomes: "Outcomes",
  types: "Types",
  // End Transactions

  // Start Expense Report
  expenseReport: "Expense Report",
  expensesReport: "Expenses Report",
  mcTotal: "Total",
  expenseCount: "Expense Count",
  // End Expense Report

  // Start Deposit Report
  depositReport: "Deposit Report",
  depositsReport: "Deposits Report",
  depositCount: "Deposit Count",
  // End Deposit Report

  // Start naw Report
  withdrawReport: "Withdraw Report",
  withdrawsReport: "Withdraws Report",
  withdrawCount: "Withdraw Count",
  // End Deposit Report

  // Start Property Features
  propertyFeatures: "Property Features",
  propertyFeaturesList: "Property Features List",
  createPropertyFeature: "Create Property Feature",
  editPropertyFeature: "Edit Property Feature",
  // End Property Features

  // Start Deposit Types
  depositTypes: "Deposit Types",
  depositTypesList: "Deposit Types List",
  depositTypeList: "Deposit Type List",
  createDepositType: "Create Deposit Type",
  editDepositType: "Edit Deposit Type",
  // End Deposit Types

  // Start Withdraw Types
  withdrawTypes: "Withdraw Types",
  withdrawTypesList: "Withdraw Types List",
  withdrawTypeList: "Withdraw Type List",
  createWithdrawType: "Create Withdraw Type",
  editWithdrawType: "Edit Withdraw Type",
  // End Withdraw Types

  // Start Deposits
  deposits: "Deposits",
  depositsList: "Deposits List",
  depositList: "Deposit List",
  createDeposit: "Create Deposit",
  editDeposit: "Edit Deposit",
  depositer: "Depositer",
  accountant: "Accountant",
  depositType: "Deposit Type",
  // End Deposits

  // Start Withdraws
  withdraws: "Withdraws",
  withdrawsList: "Withdraws List",
  withdrawList: "Withdraw List",
  createWithdraw: "Create Withdraw",
  editWithdraw: "Edit Withdraw",
  withdrawer: "Withdrawer",
  withdrawType: "Withdraw Type",
  // End Withdraws

  // Start Buy Contract Report
  buyContractReport: "Buy Contract Report",
  buyContractsReport: "Buy Contracts Report",
  buyContractCount: "Buy Contract Count",
  // End Buy Contract Report

  // Start Buy Contract Details
  buyContractDetail: "Buy Contract Details",
  buyContractsDetails: "Buy Contracts Detail",
  unitSolds: "Units sold with buyer information",
  property2: "Property",
  startDateToToday: "From the start date of the sale to today",
  building: "The building",
  paid: "Paid",
  remaining: "Remaining",
  dateOfSale: "Date of sale",
  identifier: "Identifier",
  bondNumber: "Bond number",
  amountPrice: "Amount",
  nOfTheBatch: "Name of the batch",
  paymentDate: "Payment date",
  buyerName: "Buyer's name",
  page: "Page",
  DeliveryKey: "Delivery Key",
  Cash: "Cash",
  Installment: "Installment",
  of: "Of",
  hBd: "Has been done by",
  priceOfProperty: "Price of the property",
  statisticTitle: "Updated statistics for this report",
  numberOfUnits: "Number of units",
  numberOfBuyers: "Number of buyers",
  amountsRecived: "Amounts received",
  remainingAmounts: "Remaining amounts",
  valueOfAllUnits: "Value of all units",
  totalActualArea: "Total actual area",
  totalArea: "Total Area",
  totalHoldArea: "Total Hold Area",
  totalSoldArea: "Total Sold Area",
  totalRemainArea: "Total Remain Area",
  totalAreaPlus: "Total Area",
  averageSalePerMeter: "Average sale per meter",
  // End Buy Contract Details

  // Start Payment Process Report
  paymentProcessReport: "Payment Process Report",
  paymentProcessesReport: "Payment Processes Report",
  paymentProcessCount: "Payment Process Count",
  paymentReport: "Payment Report",
  paymentsReport: "Payments Report",
  buyPrice: "Buy Price",
  // End Payment Process Report

  // Start Safe Box Transaction Report
  safeBoxTransactionReport: "Safe Box Transaction",
  safeBoxTransactionsReport: "Safe Box Transactions",
  safeBoxTransactionCount: "Safe Box Transaction Count",
  safeBoxTransaction: "Safe Box Transaction",
  // End Safe Box Transaction Report

  // Start Safe Box Transfers
  transfers: "Transfers",
  transfersList: "Transfers List",
  transferList: "Transfers List",
  fromSafeBox: "From Safe Box",
  toSafeBox: "To Safe Box",
  createTransfer: "Create Transfer",
  // End Safe Box Transfers

  // Start User Tasks
  userTasks: "User Tasks",
  userTasksList: "User Tasks List",
  userTaskList: "User Task List",
  createUserTask: "Create User Task",
  editUserTask: "Edit User Task",
  time: "Time",
  dayName: "Day Name",
  taskType: "Task Type",
  youHaveTasks: "You have tasks",
  tasks: "Tasks",
  // End User Tasks

  // Start Financial Dashboard
  financialDashboard: "Financial Dashboard",
  moreInfo: "More Info",
  totalIncomeFromSell: "Total income from sell",
  totalDeposits: "Total deposits",
  totalWithdraws: "Total withdraws",
  defaultSafeBox: "Default Safe Box",
  otherExpenseSafeBox: "Other Expense Safe Box",
  projectExpenses: "Project Expenses",
  bankSafeBox: "Bank Safe Box",
  safeBoxes: "Safe Boxes",
  currentMonth: "Current Month",
  totalTillNow: "Total Till Now",
  latestDeposits: "Latest Deposits",
  latestWithdraws: "Latest Withdraws",
  latestExpenses: "Latest Expenses",
  latestTransfers: "Latest Transfers",
  totalOfExpenses: "Total of Expenses",
  paymentsFromAgents: "Payments From Agents",
  january: "January",
  february: "February",
  march: "March",
  april: "April",
  may: "May",
  june: "June",
  july: "July",
  august: "August",
  september: "September",
  october: "October",
  november: "November",
  december: "December",
  passedPayments: "Passed Payments",
  unpaidContracts: "Unpaid Contracts",
  // End Financial Dashboard

  // Start Investors
  investors: "Investors",
  investorsList: "Investors List",
  investorList: "Investors List",
  createInvestor: "Create Investor",
  editInvestor: "Edit Investor",
  investor: "Investor",
  meter: "Meter",
  needToPay: "Need to pay",
  // End Investors

  // Start Agents
  agents: "Agents",
  agentsList: "Agents List",
  agentList: "Agents List",
  createAgent: "Create Agent",
  editAgent: "Edit Agent",
  agent: "Agent",
  // End Agents

  // Start Extras
  totalExpense: "Total Expense",
  totalDiscount: "Total Discount",
  extraPayments: "Extra Payments",
  totalExpenseCount: "Total Expense Count",
  youWantToMakeUnpayPayment: "You want to make unpay payment",
  unpay: "Unpay",
  paidDate: "Paid Date",
  forgivePrice: "Exempted Price",
  createBuyContractAttachment: "Create Buy Contract Attachment",
  loadMore: "Load More",
  showContractAttachments: "Show Contract Attachments",
  status: "Status",
  forgivePriceReport: "Forgive Price Report",
  totalForgivePrice: "Total Exempted Price",
  currentPayPrice: "Current Pay Price",
  currentForgivePrice: "Current Exempted Price",
  payDate: "Pay Date",
  perMonth: "Per Month",
  ordering: "Ordering",
  desc: "Z - A",
  asc: "A - Z",
  sorting: "Sorting",
  corner: "Corner",
  road20m: "Road 20m",
  facingPark: "Facing Park",
  reserved: "Reserved",
  available: "Available",
  isSharedCustomer: "Shared Customer",
  additionalShortagePlotArea: "Additional / Shortage Plot Area",
  pleaseTypeSecondCustomerInformation:
    "Please Type Second Customer Information",
  pleaseSelectAttachment: "Please Select Attachment",
  addAttachment: "Add Attachment",
  attachmentAdded: "Attachment Added",
  passport: "Passport",
  is20Percent: "20%",
  paidTo: "Paid To",
  reasonFor: "Reason For",
  propertyExpense: "Property Expense",
  totalPropertyExpense: "Total Property Expense",
  propertyExpenseList: "Property Expense List",
  heardAboutUsFrom: "Heard About Us From",
  feedback: "Feedback",
  villaNo: "Villa No.",
  buyContractsExpenses: "Buy Contracts Expenses",
  buyContractExpensesList: "Buy Contract Expenses List",
  // End Extras
  downpayment: "Down Payment",
  otherExpenses: "Other Expense",

  //safeBox transactions
  addWithdraw: "Add Withdraw",
  deleteWithdraw: "Delete Withdraw",
  safeBoxTransfer: "Safebox Transfer",
  deleteSafebox: "Delete Safebox",
  deletePrpertyExpense: "Delete Proprty Expense",
  addInvestor: "Add Investor",
  deleteInvestor: "Delete Investor",
  addExpense: "Add Expense",
  deleteExpense: "Delete Expense",
  addDeposit: "Add Deposit",
  deleteDeposit: "Delete Deposit",
  unpayContractPayment: "Unpay Contract Payment",
  contractPayment: "Contract Payment",
  buyContractPayment: "Buy Contract Payment",
  deleteCancleContract: "Delete Cancle Contract",
  cancleContract: "Cancle Contract",
  deleteContractPayment: "Delete Contract Payment",
  deleteBuyContractPayment: "Delete Buy Contract Payment",
  deleteSafeBoxTransferFrom: "Delete SafeBox Transfer From",
  m: "M",
  contractNote: "Contract Note",
  dari: "Dari Contract",
  textMessage: "Text Message",
};
