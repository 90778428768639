<template>
  <div class="contract text-right" dir="rtl" style="font-size: 17px !important">
    <div className="contractWrapper">
      <h4 class="p1 text-center mb-5">گرێبەستى كڕین و فرۆشتنى یەكەى نیشتەجێ</h4>
      <b>
        <p style="lineheight: 3" class="p2">
          <span class="s2">ژمارەى</span> <span class="s2">گرێبەست</span> :
          {{ " " + contract.contractNumber }}
          <span class="Apple-converted-space">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp; &nbsp;
          </span>
          <span class="s2">بەروار</span> :
          <span class="Apple-converted-space">
            &nbsp;{{ moment(contract.buyingDate).format("YYYY/MM/DD") }}
          </span>
        </p>
        <p style="lineheight: 3">یەكەم<span> / </span>لایەنەكان</p>
      </b>
      <p style="lineheight: 3">
        لایەنى یەكەم<span> ( </span>فرۆشیار <span> ) : </span>كۆمپانیاى شجرە
        الزیتون بۆ بازرگانى گشتى و وەبەرهێنانى خانووبەرە و پیشەسازى
      </p>
      <p style="lineheight: 3" class="p2">
        <span class="s2">بەشى</span> <span class="s2">فرۆشتن</span> :
        <span class="Apple-converted-space">&nbsp; </span>
        <span class="s2">ژمارەى</span> <span class="s2">مۆبایل</span> :
        <span class="Apple-converted-space">&nbsp; </span>07503005003 -
        07503005004
      </p>
      <p style="lineheight: 3">
        لایەنى دووەم<span> ( </span>كڕیار <span> ) : </span>بەڕێز
        <span>
          <span class="Apple-converted-space">
            &nbsp;:
            {{
              contract.customer &&
              contract.customer.firstName +
                " " +
                contract.customer.middleName +
                " " +
                contract.customer.lastName
            }}
          </span>
        </span>
      </p>
      <p style="lineheight: 3">
        ناونیشان
        <span> : {{ contract.customer && contract.customer.address }} </span>
      </p>
      <p style="lineheight: 3">
        ژمارەى مۆبایل
        <span>
          :
          {{
            contract.customer &&
            `${contract.customer.phone.replace("+", "00")} - ${
              contract.customer.phone2 &&
              contract.customer.phone2.replace("+", "00")
            }`
          }}
        </span>
      </p>
      <p style="lineheight: 3">
        بەڵگەنامەكان
        <span> :</span>
      </p>
      <p style="lineheight: 3">
        ژمارەى ناسنامەى بارى شارستانى / کارتی نیشتمانی
        <span> : {{ contract.customer && contract.customer.idcard }} </span>
      </p>
      <p style="lineheight: 3">
        ژمارەى ڕەگەزنامەى عیراقى
        <span> :{{ contract.customer && contract.customer.jnsia }} </span>
      </p>
      <p style="lineheight: 3">
        ژمارەى كارتى زانیارى
        <span>
          : {{ contract.customer && contract.customer.informationCard }}
        </span>
      </p>
      <p style="lineheight: 3">
        ژمارەى پسوولەى خۆراك
        <span> : {{ contract.customer && contract.customer.foodCard }} </span>
      </p>
      <p style="lineheight: 3">
        ژمارەى كارتى نشینگە<span> ( </span>اقامە
        <span>
          ) : {{ contract.customer && contract.customer.placementCard }}
        </span>
      </p>
      <p style="lineheight: 3">
        پشتگیرى ئاسایش<span> ( </span>تأیید اسایش
        <span>
          ) : {{ contract.customer && contract.customer.securitySupport }}
        </span>
      </p>
      <p style="lineheight: 3; marginbottom: 116px">
        <span>( 4 ) </span>چوار وێنەى ڕەنگاوڕەنگ
        <span> .</span>
      </p>
      <b>
        <p style="lineheight: 3">
          دووەم<span> / </span>زانیارییەكانى پڕۆژە
          <span> :</span>
        </p>
      </b>
      <p style="lineheight: 3">
        <span></span>١- ناوی پڕۆژە<span> : ( </span> پڕۆژەى شاری زەیتون پڵەس
        <span> ) .</span>
      </p>
      <p style="lineheight: 3" class="p2">
        <span class="s2">٢-ژمارەی مۆڵەتی وەبەرهێنان</span> : ( 221 )
        <span class="s2">لە</span> ( 29/12/2009 ) .
      </p>
      <p style="lineheight: 3">
        <span>٣- </span>شوێنى پڕۆژە<span> : </span> پارێزگاى هەولێر<span>
          / </span
        >ناحیەى دارەتوو
        <span>
          .
          <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span>
        </span>
      </p>
      <b>
        <br />
        <p style="lineheight: 3">
          سێ یەم<span> / </span>بابەتى گرێبەست
          <span> : </span>
        </p>
      </b>
      <p style="lineheight: 3">
        كۆمپانیاى<span> (</span> شجرة الزيتون بۆ بازرگانى گشتى و وەبەرهێنانى
        خانووبەرە و پێشەسازى<span>) </span>بە پێى مۆڵەتى وەبەرهێنان بە
        ژمارە<span> (221) </span>لە بەروارى <span> (29/12/2009) </span>كە
        دەرچووە لە دەستەى وەبەرهێنانى حكومەتى هەرێمى كوردستان ، هەڵدەستێت بە
        دروست كردنى یەكەى نیشتەجێ ى لە شوێنى تەرخان كراو بۆ پڕۆژەكە لە پارێزگاى
        هەولێر <span> / </span>ناحیەى دارەتوو ، وە فرۆشتنى بە هاووڵاتیان وە لە
        سەر ئارەزووى لایەنى دووەم وە ڕازى بوونى دواى تەماشا كردنى و تێڕوانینى
        نەخشەى یەكەكە وە هەروەها نەخشەى شوێنى پڕۆژەكە
        <span>
          ( SITE PLAN ) وپارچە زەوییەكە وە نرخى یەكەكە و چۆنیەتى دانى تێچووى وە
          ڕازى بوونى لایەنى یەكەم بەم فرۆشتنە،
          <br />
        </span>
        هەردوو لایەنى فەرمى گرێبەستەكە ڕێكەوتن لە سەر پابەند بوونى یاسایى بە
        ناوەڕۆكى گرێبەستەكە بەم شێوەیەى خوارەوە<span> :</span>
      </p>
      <p style="lineheight: 3">
        <span>1) </span>لایەنى یەكەم هەڵسا بە فرۆشتنى یەكەى نیشتەجێ ى ژمارە
        <span> ({{ contract.property && contract.property.name }}) </span>
        لە زۆنى
        <span> ({{ contract.property && contract.property.zone }}) </span>
        <!-- لە جادەى
        <span >
          ({{ contract.property && contract.property.streetNumber }})
        </span>
        لەبلوكی
        <span >
          ({{ contract.property && contract.property.block }})
        </span> -->
        بە لایەنى دووەم كە دروست كراوە لە پڕۆژەى
        <span> ( </span>شارى زەیتون پڵەس <span> ) </span>كە دەكەوێتە سەر
        پارچەیەك لە پارچە زەوییەكانى ناو پڕۆژەى شارى زەیتون بە ڕووبەرى
        <span>
          ({{ contract.property && contract.property.area }} م<sup>2</sup>)
        </span>
        مەتری دووجا وە پێكهاتووە لە دوو نهۆم هەروەكو لە نەخشەى بیناى هاوپێچدا
        هاتووە، وە ژمارەى موڵكەكە دەچەسپێ بەشێوەى كۆتایى لە دواى تەواو كردنى
        كردارى جیاكردنەوە و یەك بوون و تۆماركردنى لە فەرمانگەى تۆمارگاى
        خانووبەرەى تایبەتمەند بە شێوەى فەرمى
      </p>
      <b>
        <br />
        <br />
        <p style="lineheight: 3">پێكهاتەكانى خانوى نیشتەجێبون<span>:-</span></p>
      </b>
      <p style="lineheight: 3" class="p2">
        <span class="s2">ا</span>- RAFT FOUNDATION
      </p>
      <p style="lineheight: 3">
        ب<span>- </span>پەنجەرەكان لە ئەلەمنیوم دروستكراوە
        <span>.</span>
      </p>
      <p style="lineheight: 3">
        ج<span>- </span>سەقف<span> (</span>جبسن بورد
        <span>).</span>
      </p>
      <p style="lineheight: 3">
        د<span>- </span>پێشەوەی خانوو<span> (</span>واجهە فوگا<span>).</span>
      </p>
      <p style="lineheight: 3">
        ه<span>- </span>كاشى زەوى جۆری پورسەلین
        <span>.</span>
      </p>
      <p style="lineheight: 3">
        و<span>- </span>دەرگاكان دروستكراوى توركى یە
        <span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>2) </span>كریار دەبێت خەرجى خزمەتگوزارى بدات وە بە پێچەوانە
        كومپانیا دەتوانێت خزمەتگوزارى رابگرێت<span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>3) </span>نابێت كریار گورانكارى لە دیوى دەرەوەى خانوەكەدا بكات
        ببێتە هوى تێكدانى جوانى پروژەكە بەتایبەتى لاى پێشەوە
        <span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>4) </span>نرخى یەكەكە
        <span>
          (
          {{ contract && formatter.format(contract.buyingPrice) }},
          {{ contract && unitPrice }}
          )یە
        </span>
        وە بەم شێوەیەى خوارەوە لایەنى دووەم پارەكە دەداتە لایەنى یەكەم
        <span>:-</span>
      </p>
      <b>
        <p style="lineheight: 3">
          چوارەم<span> / </span>مەرجەكانى پارەدان
          <span> :</span>
        </p>
      </b>
      <ul class="ul1" style="liststyletype: none; padding: 0">
        <li
          v-for="(contractPrePayment, index) in contractPrePayments"
          :key="index"
        >
          <div v-if="index == 0">
            {{ bulletclasses[index] }} )
            {{ numToOrderString(index + 1) }}
            پارەدان : بڕى
            <span>
              ({{ formatter.format(contractPrePayment.currentPayPrice) }})
            </span>
            {{ contractPrePayment.kuPriceText }}
            لە گەڵ واژوو كردنى گرێبەستى كڕین و فرۆشتن لە نێوان هەردوولا دا واتە
            لایەنى دووەم دەداتە لایەنى یەكەم لە بەروارى
            <span>
              {{
                moment(contractPrePayment.currentPayDate).format("YYYY/MM/DD")
              }}.
            </span>
          </div>
          <div v-else>
            {{ bulletclasses[index] }} )
            {{ numToOrderString(index + 1) }}
            پارەدان : بڕى
            <span>
              ({{ formatter.format(contractPrePayment.currentPayPrice) }})
            </span>
            {{ contractPrePayment.kuPriceText }}
            لە بەروارى
            <span>
              {{
                moment(contractPrePayment.currentPayDate).format("YYYY/MM/DD")
              }}.
            </span>
          </div>
        </li>
        <!--Delivery key-->
        <p v-if="contractDeliveryKeyPayments !== null">
          {{ bulletclasses[contractPrePayments.length] }} )
          {{ numToOrderString(contractPrePayments.length + 1) }}
          پارەدان : لەكاتى تەواوبونى یەكەى نیشتەجێ بوون بەماوەیەكى كەمتر ئەوا
          دەبێت لایەنى دووەم لەگەل وەرگرتنى كلیلى خانوو برى پارەى پاشەكى بە
          گوژمەی
          <span>
            ({{
              formatter.format(contractDeliveryKeyPayments.currentPayPrice)
            }})
          </span>
          {{ contractDeliveryKeyPayments.kuPriceText }}
          <span> </span>بداتە لایەنى یەكەم <span> (</span>كومپانیا<span
            >), </span
          >ئینجا پێویستە لەسەر لایەنى دووەم پابەند بێت بەپێدانى گشت قستەكان بە
          لایەنى یەكەم كە لە برگەى چوارەم دا روون كراوەتەوە<span> , </span>
          واتە نابێت هیچ پارەیەك لە ئەستوى لایەنى دووەم بمێنێت.
        </p>

        <li
          v-for="(paymentProccesFiltered, key) in contractPayments"
          :key="key"
        >
          <!-- <h1>{{ contractPrePayments.length }} ///// {{ key }}</h1> -->

          {{ bulletclasses[key + contractPrePayments.length + 1] }}) قستى
          {{ numToOrderString(key + 1) }}
          <!-- {{ removeAfterMonth !== true &&
            `
          دواى 
          ${
            calculateDate(paymentProccesFiltered.currentPayDate, contract.prePaymentDate)
          }
          مانگ لە بەروارى واژووكردنى گرێبەست 
          `}} -->
          كە دەكاتە بڕى
          <span>
            ({{ formatter.format(paymentProccesFiltered.currentPayPrice) }})
          </span>
          {{ paymentProccesFiltered.kuPriceText }} لە بەروارى
          <span>
            {{
              moment(paymentProccesFiltered.currentPayDate).format("YYYY-MM-DD")
            }}
          </span>
          <br />
          <br />
        </li>
      </ul>

      <p style="lineheight: 3">
        <span>5) </span>ئەم گرێبەستە بەڵگەى دەستەبەرى <span> ( </span>ضمان<span>
          )</span
        >ە بۆ لایەنى دووەم كە ماف و خاوەندارێتى پێ دەبەخشێ<span> .</span>
      </p>
      <p style="lineheight: 3">
        <span>6) </span>ڕادەست كردنى یەكەى نیشتەجێ لە دواى
        <!-- <span >
          ( {{contract && contract.property && contract.property.zone === "I" ?
          "36" : "30"}} )
        </span> -->
        <span> (14) </span>
        مانگ<span> (</span>روژى كاركردن <span>) </span>دەبێت لە بەروارى
        <span> 1/10/2022.</span>
      </p>
      <p style="lineheight: 3">
        <span>7) </span>لە كاتى دوا كەوتنى لایەنى دووەم لە دانى قستى داوا لێكراو
        بە گوێرەى خشتەى ئەم گرێبەستە وە دواى ئاگادار كردنەوەى لە لایەن
        كۆمپانیاوە ، پێویستە لە سەر لایەنى دووەم بۆ هەر ڕۆژێك دوا كەوتن ئەوا
        ڕۆژانە بڕى<span> (100$) </span>سەد دۆلارى ئەمریكى بدات بە لایەنى یەكەم ،
        ئەمەش بەردەوام دەبێت بۆ ماوەى یەك مانگ وە لە دواى ئەم ماوەیە لایەنى
        یەكەم بۆى هەیە بە ئاگادار كردنەوەى بەڕێوەبەرایەتى گشتى وەبەرهێنان<span>
          / </span
        >هەولێر ، وە هەروەها ئاگادار كردنەوەى لایەنى دووەم ، ئەوا لایەنى یەكەم
        تاك لایەنە گرێبەستەكە هەڵبوەشێنێتەوە و یەكەكە بفرۆشێتە كەسێكى تر ئەمەش
        ڕەزامەندى هەردوو لایەنى فەرمى ئەم گرێبەستەى لە سەرە<span> .</span>
      </p>
      <p style="lineheight: 3">
        <span>8) </span>ئەگەر لایەنى دووەم داواى هەڵوەشاندنەوەى گرێبەستەكە بكات
        بە هەر هۆكارێك بێت ئەوا پێویستە لە سەر لایەنى دووەم ڕێژەى<span>
          (30 %) </span
        >سى لە سەد لە نرخى یەكەكە وەك سزاى بژاردن<span> (</span>غرامە<span
          >) </span
        >بدات بە لایەنى یەكەم بە مەبەستى قەرەبووى خەرجییەكانى كارگێرى و
        خزمەتگوزارى پەیوەست بە ڕێكارەكانى گرێبەست ، وە پاشماوەى پارەكەى بۆ
        دەگەڕێندرێتەوە لە دواى فرۆشتنى یەكەكە بە كەسێكى تر<span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>9) </span>لایەنى دووەم لە كاتى نەدانى پشكى شایستەى سەر شانى بە
        لایەنى یەكەم<span> ( </span>كۆمپانیا <span> ) </span>لە ماوەى یەك مانگدا
        لە بەروارى شایستە بوون ، ئەمە بە ڕاوەستانى كۆتایى هەژمار دەكرێت وە ئەم
        گرێبەستە بە هەڵوەشاوە هەژمار دەكرێت وە هەردوو لایەنى ئەم گرێبەستە ڕازین
        بەم هەڵوەشاندنەوەیە ، لەم حاڵەتەدا لایەنى دووەم بژاردەى دارایى بە ڕێژەى
        <span> (30%) </span>سى لە سەد لە نرخى یەكەكە لە ئەستۆ دەگرێت وەك قەرەبوو
        لە خەرجییەكانى كارگێرى و ئەو زەرەرانەى كە بە لایەنى یەكەم دەكەوێت ، وە
        پاشماوەى پارەكە بۆ<span> ( </span>لایەنى دووەم <span> )</span>دەگەڕێتەوە
        لە دواى فرۆشتنى یەكەكە بە كڕیارى تازە ، وە لایەنى یەكەم<span> ( </span
        >كۆمپانیا <span> ) </span>بۆى هەیە ئەم ڕێكارانە ئەنجام بدات
        <span> .</span>
      </p>
      <p style="lineheight: 3">
        <span>10) </span>لە حاڵەتى دوا كەوتنى لایەنى یەكەم
        <span> ( </span>كۆمپانیا<span> ) </span>لە ڕادەست كردنى یەكەى نیشتەجێ بە
        لایەنى دووەم<span> (</span>كڕیار <span>) </span>لە ماوەى دیارى كراوى
        خۆیدا هەر وەكو لە خاڵى <span> ( 6 ) </span>شەشەمی ئەم گرێبەستەدا هاتووە
        ، وە كەمتەرخەمى لە لایەن لایەنى یەكەم بوو بەڵام بەدەر لە بارودوخى
        نالەبار <span> ( </span>الضروف القاهرە<span> ) </span>ئەوا لایەنى یەكەم
        پابەندە بە دانى بڕى<span> (100$) </span>سەد دۆلارى ئەمریكى ڕۆژانە بە
        لایەنى دووەم<span> ( </span>كڕیار <span> ) </span>تا دەگاتە ڕێژەى<span>
          (5%)
        </span>
        پێنج لە سەدى نرخى یەكەكە وە ئەم پێ بژاردنە<span> (</span> غرامە<span
          >) </span
        >یە لە گەڵ دانى قستى دوایى و كۆتایى یەكلا دەكرێتەوە<span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>11)</span>لایەنى یەكەم یەكە نیشتەجێ یەكە ڕادەستى لایەنى دووەم<span>
          ( </span
        >كڕیار<span> ) </span>دەكات بە پێ ى بەندەكانى ئەم گرێبەستە وە لایەنى
        دووەم پابەندە بە وەرگرتنى یەكەكە وە واژووى لە سەر فۆرمى وەرگرتنى یەكەكە
        بكات پێش وادەى تەواو بوونى ماوەى ئەم گرێبەستە<span> , </span>لە گەڵ
        ڕەچاو كردنى نیشتەجێ بوون بە پێ ى ڕێنماییەكانى بەڕێوەبەرایەتى گشتى
        وەبەرهێنانى هەولێر
        <span> .</span>
      </p>
      <p style="lineheight: 3">
        <span>12) </span>لایەنى دووەم گشت خەرجییەكان و ڕسومات و كرێیەكان و
        ماندووێتى پەیوەست بە فەرمانگەى تۆمار كردنى خانووبەرە تایبەتمەندەكان و
        دام و دەزگا پەیوەندیدارەكان لە ئەستۆ دەگرێت لە چوارچێوەى ئەم
        گرێبەستە<span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>13) </span>لایەنى دووەم بۆى نیە <span> (</span>تنازول<span
          >) </span
        >لەم گرێبەستە بكات بۆ كەسێكى تر یاخود لایەنێكى تر بە بێ ئاگادارى و
        وەرگرتنى ڕەزامەندى پێشوەختەى لایەنى یەكەم<span> (</span>كۆمپانیا
        <span>) </span>بە شێوەى نووسین ،وە پێویستە لەسەر لایەنی دووەم تا وەکو
        بەرواری فرۆشتنی یەکەکە گشت پابەندبونە داراییەکان بەرامبەر بە لایەنی
        یەکەم ( کۆمپانیا )جێبەجێ بکات وە لە ھەمان کاتدا پێویستە لەسەر لایەنی
        دووەم و کڕیاری نوێ پێکەوە سەردانی کۆمپانیا بکەن بە مەبەستی ئاگادار بوون
        لە ناوەڕۆکی گرێبەستەکە وە ئەنجامدانی گرێبەستی نوێ لە لایەن کۆمپانیا بۆ
        کڕیاری نوێ، بە پێچەوانەوە لایەنی دووەم لە ھەموو ڕوویەکەوە بەرپرسیارە.
      </p>

      <p style="lineheight: 3">
        <span>14) </span>تۆمار كردنى یەكەى نیشتەجێ بە ناوى لایەنى دووەم ئەنجام
        نادرێت ، تەنها دواى تەواو بوونى بیناى یەكەكە و دانى هەموو نرخى یەكەكە لە
        لایەن لایەنى دووەمەوە<span> (</span>كڕیار <span>) </span>ەوە بە لایەنى
        یەكەم<span>.</span>
      </p>
      <p style="lineheight: 3">
        <span>15) </span>ماوەى چاككردنەوە<span> (</span> الصیانە<span>) </span>ى
        یەكەكە ساڵێك دەبێت لە رێککەوتی تەواوبوونی یەكەكە لە لایەن لایەنى
        یەکەمەوە ، وە لایەنى یەكەم بەرپرسیارە لەو زەرەرەى بەر یەكەكە<span>
          (</span
        >بیناكە <span>) </span>كەوتبێ لە ئەنجامى جێبەجێ كردنى، بەڵام هەر جۆرە
        زەرەرێك لە كاتى خراپ بە كارهێنانى یەكە نیشتەجێ یەكە لە لایەن لایەنى
        دووەم ئەوا خودى خۆى بەرپرسیار دەبێت و هیچ خەرجییەك ناكەوێتە ئەستۆى
        لایەنى یەكەم<span> (</span>كۆمپانیا
        <span>) .</span>
      </p>
      <p style="lineheight: 3">
        <span>16) </span>لە كاتى ڕوودانى هەر جۆرە ناكۆكییەك لە نێوان لایەنە
        فەرمییەكانى ئەم گرێبەستە ئەوا سەرەتا بە شێوەى دانوستاندن چارەسەر دەكرێت
        ، بەڵام ئەگەر هاتوو چارەسەر نەكرا ئەوا دادگاى بەرایى هەولێر لایەنى
        تایبەتمەند و یەكلاكەرەوە دەبێت بۆ یەكلا كردنەوەى كێشەى نێوان
        هەردوولایەنى فەرمى ئەم گرێبەستە<span>.</span>
      </p>
      <p style="lineheight: 3">
        ئەم گرێبەستە لە ناو و ناونیشان و پێشەكى و

        <span> ({{ pageNumber }}) </span>({{ pageNumberText }}) لاپەڕە و
        <span> (16) </span>
        شازدە خاڵ پێكدێت، كەوا هەر

        <span> ({{ pageNumber }}) </span>({{ pageNumberText }}) لاپەڕە بە ڕەسەن
        هەژمار دەكرێن و هەمان هێزى یاسایى یان هەیە لە ڕووى یاساییەوە ، هەروەها
        دواى خوێندنەوەى وورد و تێگەیشتنى هەردوو لایەنى فەرمى لە سەر كۆى ناوەڕۆكى
        ئەم گرێبەستە ، گرێبەستەكە لە شارى هەولێر لە بەروارى
        <span class="s6">
          ({{ moment(contract.buyingDate).format("YYYY-MM-DD") }})&nbsp;
        </span>
        واژوو كرا لە نێوان هەردوو لایەن<span>.</span>
      </p>

      <p style="line-height: 6" class="p3">&nbsp;</p>
      <div style="display: flex; justify-content: space-around; height: 120px">
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
          "
        >
          <b>
            <span class="s2">لایەنى دووەم</span>
          </b>
          <span class="s2">
            ناو:
            {{
              contract.customer &&
              `${contract.customer.firstName} ${contract.customer.middleName} ${contract.customer.lastName}`
            }}
          </span>
          <span class="s2">
            ناونیشان: {{ contract.customer && contract.customer.address }}
          </span>
          <!-- <span class="s2" >واژوو:</span> -->
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: space-between;
          "
        >
          <b>
            <span class="s2">لایەنى یەكەم</span>
          </b>
          <span class="s2">كۆمپانیاى شجرە الزیتون</span>
          <span class="s2">
            بۆ بازرگانى گشتى و وەبەرهێنانى خانووبەرە و پیشەسازى
          </span>
          <!-- <span class="s2" >واژوو:</span> -->
        </div>
      </div>
      <p style="line-height: 3" class="p3">&nbsp;</p>
      <b>
        <p style="line-height: 3">هاوپێچ<span> :-</span></p>
      </b>
      <p style="line-height: 3">
        <span>1 ) </span>نەخشەى پڕۆژە ( Site Plan )<span> .</span>
      </p>
      <p style="line-height: 3">
        <span>2 ) </span>نەخشەى یەكەكە<span> .</span>
      </p>
      <p style="line-height: 3">
        <span>3 ) </span>بەڵگەنامەكان<span> ( </span>
        ناسنامەى بارى شارستانى ، ڕەگەزنامەى عێراقى ، كارتى زانیارى ، پسوولەى
        خوراك ، پاسپۆرت ، كارتى نشینگە ، پشتگیرى ئاسایش ، پشتگیرى موختار
        <span> ).</span>
      </p>
      <p style="line-height: 3" class="p3">&nbsp;</p>
      <div>
        <p style="line-height: 3" class="p1 text-center">
          بەڕێوەبەرایەتى گشتى وەبەرهێنان<span> / </span>هەولێر
        </p>
        <p style="line-height: 3" class="p2 text-center">
          ( <span class="s2">پەسەند</span> <span class="s2">كرا</span> )
          <span class="s2">لە</span> (
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>/
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>/
          <span class="Apple-converted-space">&nbsp; &nbsp; </span>2020 )
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { HTTP } from "../../plugins/axios";
export default {
  name: "KurdishContractZaitonPlusCity",
  props: {
    contract: {},
    removeAfterMonth: {
      type: Boolean,
    },
    pageNumber: {
      type: Number,
    },
    contractPayments: [],
    contractPrePayments: [],
    contractDeliveryKeyPayments: [],
  },
  data() {
    return {
      bulletclasses: [
        "ا",
        "ب",
        "پ",
        "ت",
        "ث",
        "ج",
        "چ",
        "ح",
        "خ",
        "د",
        "ذ",
        "ر",
        "ز",
        "ژ",
        "ص",
        "ض",
        "ط",
        "ظ",
        "ع",
        "غ",
        "ف",
        "ق",
        "ک",
        "گ",
        "ل",
        "م",
        "ن",
        "و",
        "ه",
        "ی",
      ],
      paymentProccessFiltered: [],
      unitPrice: 0,
      pageNumberText: 0,
      formatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }),
    };
  },
  methods: {
    numToOrderString(num) {
      var returnedvalue;
      switch (num) {
        case 1:
          returnedvalue = "یەکەم";
          break;
        case 2:
          returnedvalue = "دووەم";
          break;
        case 3:
          returnedvalue = "سێیەم";
          break;
        case 4:
          returnedvalue = "چوارەم";
          break;
        case 5:
          returnedvalue = "پێنجەم";
          break;
        case 6:
          returnedvalue = "شەشەم";
          break;
        case 7:
          returnedvalue = "حەوتەم";
          break;
        case 8:
          returnedvalue = "هەشتەم";
          break;
        case 9:
          returnedvalue = "نۆیەم";
          break;
        case 10:
          returnedvalue = "دەیەم";
          break;
        case 11:
          returnedvalue = "یازدە";
          break;
        case 12:
          returnedvalue = "دوازده";
          break;
        case 13:
          returnedvalue = "سێزدە";
          break;
        case 14:
          returnedvalue = "چواردە";
          break;
        case 15:
          returnedvalue = "پازدە";
          break;
        case 16:
          returnedvalue = "شازدە";
          break;
        case 17:
          returnedvalue = "حەڤدە";
          break;
        case 18:
          returnedvalue = "هەژدە";
          break;
        case 19:
          returnedvalue = "نۆزدە";
          break;
        case 20:
          returnedvalue = "بیست";
          break;
        case 21:
          returnedvalue = "بیست و یەك";
          break;
        case 22:
          returnedvalue = "بست و دوو";
          break;
        case 23:
          returnedvalue = "بیست و سێ";
          break;
        case 24:
          returnedvalue = "بیست و چوار";
          break;
        case 25:
          returnedvalue = "بیست و پێنج";
          break;
        case 26:
          returnedvalue = "بیست و شەش";
          break;
        case 27:
          returnedvalue = "بیست و حەوت";
          break;
        case 28:
          returnedvalue = "بیست و هەشت";
          break;
        case 29:
          returnedvalue = "بیست و نۆ";
          break;
        case 30:
          returnedvalue = "سی";
          break;
        case 31:
          returnedvalue = "سی و یەك";
          break;
        case 32:
          returnedvalue = "سی و دوو";
          break;
        case 33:
          returnedvalue = "سی و سێ";
          break;
        case 34:
          returnedvalue = "سی و چوار";
          break;
        case 35:
          returnedvalue = "سی و پێنج";
          break;
        case 36:
          returnedvalue = "سی و شەش";
          break;
        case 37:
          returnedvalue = "سی و حەوت";
          break;
        case 38:
          returnedvalue = "سی و هەشت";
          break;
        case 39:
          returnedvalue = "سی و نۆ";
          break;
        case 40:
          returnedvalue = "چل";
          break;
        case 41:
          returnedvalue = "چل و یەك";
          break;
        case 42:
          returnedvalue = "چل و دوو";
          break;
        case 43:
          returnedvalue = "چل و سێ";
          break;
        case 44:
          returnedvalue = "چل و چوار";
          break;
        case 45:
          returnedvalue = "چل و پێنج";
          break;
        case 46:
          returnedvalue = "چل وشەش";
          break;
        case 47:
          returnedvalue = "چل و حەوت";
          break;
        case 48:
          returnedvalue = "چل و هەشت";
          break;
        case 49:
          returnedvalue = "چل و نۆ";
          break;
        case 50:
          returnedvalue = "پەنجا";
          break;
        case 51:
          returnedvalue = "پەنجاو یەك";
          break;
        case 52:
          returnedvalue = "پەنجاو دوو";
          break;
        case 53:
          returnedvalue = "پەنجاو سێ";
          break;
        case 54:
          returnedvalue = "پەنجاو چوار";
          break;
        case 55:
          returnedvalue = "پەنجاو پێنج";
          break;
        case 56:
          returnedvalue = "پەنجاو شەش";
          break;
        case 57:
          returnedvalue = "پەنجاو حەوت";
          break;
        case 58:
          returnedvalue = "پەنجاو هەشت";
          break;
        case 59:
          returnedvalue = "پەنجاو نۆ";
          break;
        case 60:
          returnedvalue = "شەست";
          break;
        default:
          returnedvalue = "شەست +";
      }
      return returnedvalue;
    },
    calculateDate(date1, date2) {
      // return {
      Math.ceil(moment(date1).diff(moment(date2), "months", true));
      // }
    },
  },
  async mounted() {
    // if(this.contract.paymentProcess){
    //   this.contract.paymentProcess.filter((el) => el.isItKeyPayment === false ? this.paymentProccessFiltered.push(el) : "");
    // }
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.contract.buyingPrice}`
    )
      .then((resp) => {
        this.unitPrice = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToKurdishText?number=${this.pageNumber}&suffix=false`
    )
      .then((resp) => {
        this.pageNumberText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
};
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}
p {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
span {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
li {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
div {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
strong {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
