<template>
  <div v-if="hasPermission(permissions.propertiesCreate)">
    <content-header>{{ $t('createProperties') }}</content-header>
    <v-content>
      <b-card>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <text-input id="name" :name="projectTenant() === 'MassHills' ? $t('villaNumber') : $t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
            <b-row v-if="projectTenant() !== 'MassHills'" class="mb-2">
              <b-col class="text-right" cols="2">
                <label class="col-form-label text-right">{{ $t('additionalInfo') }}</label>
              </b-col>
              <b-col>
                <div class="accordion" role="tablist">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button block v-b-toggle.additional-info variant="info">{{ $t('additionalInfo') }}</b-button>
                    </b-card-header>
                    <b-collapse id="additional-info" accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                        <b-row>
                          <b-col>
                            <text-input id="streetNumber" :name="$t('streetNumber')" :validate="$v" :is-loading="isLoading" />
                          </b-col>
                          <b-col>
                            <number-input id="streetWidth" :name="$t('streetWidth')" :validate="$v" :is-loading="isLoading" />
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <number-input id="numberOfRooms" :name="$t('rooms')" :validate="$v" :is-loading="isLoading" />
                          </b-col>
                          <b-col>
                            <number-input id="floors" :name="$t('floors')" :validate="$v" :is-loading="isLoading" />
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </b-col>
            </b-row>
            <select-input
                label="name"
                id="propertyType"
                :name="projectTenant() === 'MassHills' ? $t('villaType') : $t('apartmentType')"
                api-link="propertyTypes/GET_ALL"
                :required="true"
                @itemSelected="e => form.propertyTypeId = e"
            />
            <text-input id="zone" :name="projectTenant() === 'Candle' ? $t('tower') : $t('zone')" :validate="$v" :is-loading="isLoading" />
            <text-input id="block" :name="projectTenant() === 'Candle' ? $t('floor') : $t('block')" :validate="$v" :is-loading="isLoading" />
            <text-input v-if="projectTenant() === 'RoyalTower'" id="extraProperty1" :name="$t('extraProperty1')" :validate="$v" :is-loading="isLoading" />
            <text-input v-if="projectTenant() === 'RoyalTower'" id="extraProperty2" :name="$t('extraProperty2')" :validate="$v" :is-loading="isLoading" />
            <select-input
                v-if="projectTenant() === 'RoyalTower'"
                id="realEstateAgent"
                :name="$t('realEstateAgent')"
                :validate="$v"
                api-link="leadSources/GET_ALL"
                label="name"
                @itemSelected="e => form.note = e.name"
                :return-object="true"
            />
            <div :style="[projectTenant() !== 'Candle' ? {'display': 'block'} : {'display': 'none'}]">
              <number-input
                  id="plotArea"
                  :name="$t('plotArea')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
            </div>
            <div v-if="projectTenant() !== 'MassHills'">
              <number-input
                  id="buildUpArea"
                  :name="projectTenant() !== 'MassHills' ? projectTenant() === 'Candle' ? $t('apartment') : $t('area') : $t('buildUpArea')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
              <number-input
                  id="meterPrice"
                  :name="projectTenant() !== 'MassHills' ? projectTenant() === 'Candle' ? $t('apartmentPrice') : $t('priceMeter') : $t('meterPrice')"
                  :validate="$v"
                  :is-loading="isLoading"
              />
            </div>
            <div v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle'" class="form-group row">
              <label for="amount" class="col-sm-2 col-form-label text-right">{{ $t('amount') }}</label>
              <div class="col-sm-10">
                <input
                    id="amount"
                    v-model.trim="amount"
                    placeholder="Amount"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                    @focus="$event.target.select()"
                    v-on:keypress="e => $helpers.isNumeric(e)"
                    step="any"
                />
              </div>
            </div>
            <number-input v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle' && projectTenant() !== 'RoyalTower'" id="area" :name="$t('area')" :validate="$v" :is-loading="isLoading" />
            <div v-if="projectTenant() === 'MassHills'" class="form-group row">
              <label class="col-sm-2 col-form-label text-right">{{ $t('variance') }}</label>
              <div class="col-sm-10">
                <p class="lead pt-1">{{ variance.toLocaleString() }}</p>
              </div>
            </div>
            <number-input v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle' && projectTenant() !== 'RoyalTower'" id="extraCost" :name="$t('extraCost')" :show-locale-string="true" :validate="$v" :is-loading="isLoading" :readonly="true" />
            <number-input v-if="projectTenant() !== 'MassHills'" id="initialPrice" :name="$t('initialPrice')" :show-locale-string="true" :validate="$v" :is-loading="isLoading" />
            <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
            <div v-if="projectTenant() !== 'DiplomatiSafiran'">
              <select-input
                  id="features"
                  :name="$t('features')"
                  :validate="$v"
                  api-link="features/GET_ALL"
                  label="name"
                  @itemSelected="featureSelected"
                  :multiple="true"
              />
              <table class="table table-hover">
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t('feature') }}</th>
                  <th scope="col">{{ $t('price') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(feature, index) in features" :key="feature.id">
                  <th scope="row">{{ ++index }}</th>
                  <td>{{ feature.name }}</td>
                  <td>{{ feature.extraPrice.toLocaleString() }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-if="projectTenant() !== 'DiplomatiSafiran'" class="form-group row">
              <label class="col-sm-2 col-form-label text-right">{{ $t('finalPrice') }}</label>
              <div class="col-sm-10">
                <p class="lead pt-1">{{ finalPrice.toLocaleString() }}</p>
              </div>
            </div>
            <submit-input :name="$t('create')" :disabled="isLoading"/>
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "../../components/Content";
import ContentHeader from "../../components/ContentHeader";
import IsLoading from "../../components/IsLoading";
import {maxLength, required} from 'vuelidate/lib/validators'
import {Properties} from "@/models/Properties";
import TextInput from "../../components/Inputs/TextInput";
import SubmitInput from "../../components/Inputs/SubmitInput";
import SelectInput from "../../components/Inputs/SelectInput";
import NumberInput from "../../components/Inputs/NumberInput";
import _ from "lodash";
import {permissions} from "@/plugins/constants";
import TextAreaInput from "@/components/Inputs/TextAreaInput";

export default {
  name: "PropertiesCreate",
  components: {
    TextAreaInput,
    SelectInput, NumberInput, SubmitInput, TextInput, ContentHeader, 'v-content': Content, IsLoading},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Properties().fillAbles),
      isLoading: false,
      features: [],
      lastFeatureLength: 0,
      finalPrice: 0,
      amount: 0,
      variance: 0,
      varianceStored: localStorage.getItem('variance') ?? 10,
      buildUpAreaStored: localStorage.getItem('buildUpArea') ?? 300,
      permissions: permissions
    }
  },
  watch: {
    features: {
      handler() {
        this.calculatePropertyFinalPrice()
      },
      deep: true
    },
    form: {
      handler() {
        this.calculatePropertyFinalPrice()
      },
      deep: true
    },
    amount: {
      handler() {
        this.calculatePropertyFinalPrice()
      },
      deep: true
    }
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      propertyTypeId: {required},
      zone: {},
      block: {},
      streetNumber: {},
      area: {},
      streetWidth: {},
      numberOfRooms: {},
      floors: {},
      corner: {},
      note: {},
      initialPrice: {},
      plotArea: {},
      extraProperty1: {},
      extraProperty2: {},
      buildUpArea: {},
      meterPrice: {},
      finalPrice: {},
      extraCost: {required},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.propertyFeatures = this.features.map(item => {
        return {
          featureId: item.id,
          extraPrice: item.extraPrice
        };
      });
      this.form.finalPrice = this.finalPrice
      if (this.$helpers.projectTenant() === 'DiplomatiSafiran') {
        this.form.area = this.form.buildUpArea;
      }
      this.isLoading = true;
      this.$store.dispatch('properties/CREATE', this.form).then(() => {
        this.$v.$reset();
        this.$router.push({name: "properties"})
      }).catch(() => this.isLoading = false);
    },
    featureSelected(e) {
      if (e.length > this.lastFeatureLength) {
        let lastAddedFeature = _.last(e);
        this.$store.dispatch('features/GET_ONE', lastAddedFeature).then((response) => {
          this.features.push(response.data);
          this.lastFeatureLength += 1;
        }).catch(() => this.isLoading = false)
      } else {
        let sameBetween = _.intersection(e, _.map(this.features, 'id'));
        this.features = _.filter(this.features, (val) => {
          return sameBetween.includes(val.id);
        });
        this.lastFeatureLength -= 1;
      }
    },
    calculatePropertyFinalPrice() {
      this.finalPrice =
          parseFloat(_.sumBy(this.features, 'extraPrice')) +
          parseFloat(this.amount) +
          parseFloat(this.form.extraCost);
      if (this.$helpers.projectTenant() !== 'MassHills') {
        this.amount = parseFloat(this.form.meterPrice) * parseFloat(this.form.buildUpArea);
        this.form.initialPrice = parseFloat(this.form.meterPrice) * parseFloat(this.form.buildUpArea);
      } else {
        this.form.initialPrice = this.amount;
      }
      if (this.$helpers.projectTenant() === 'MassHills') {
        this.variance = parseFloat(this.form.area) - parseFloat(this.form.plotArea);

        if (this.variance >= this.varianceStored || this.variance === 0) {
          this.form.extraCost = this.variance > this.varianceStored ? this.buildUpAreaStored * (this.variance - this.varianceStored) : 0;
        } else if (this.variance < 0) {
          this.form.extraCost = this.variance * this.buildUpAreaStored;
        } else {
          this.form.extraCost = 0;
        }

      } else {
        this.variance = 0;
        this.form.extraCost = 0;
      }
    }
  }
}
</script>
