<template>
  <div v-if="hasPermission(permissions.propertyHoldShow)">
    <content-header>{{ $t("showPropertyHold") }}</content-header>
    <v-content>
      <b-card>
        <template #header v-if="projectTenant() === 'RoyalTower'">
          <b-button class="m-2" variant="primary" @click="printVoucher">{{
            $t("print")
          }}</b-button>
          <b-button class="m-2" variant="success" @click="printVoucher2">{{
            $t("voucherReceipt")
          }}</b-button>
        </template>
        <template #header v-if="projectTenant() === 'MassHills'">
          <b-button class="m-2" variant="primary" @click="printVoucher">{{
            $t("print")
          }}</b-button>
          <!-- <b-button class="m-2" variant="success" @click="printVoucher2">{{
            $t("voucherReceipt")
          }}</b-button> -->
        </template>
        <b-card-body>
          <p class="lead">
            {{ $t("property") }}:
            <router-link
              :to="{ name: 'properties.edit', params: { id: data.propertyId } }"
              >{{ data && data.property && data.property.name }}</router-link
            >
          </p>
          <p class="lead">
            {{ $t("name") }}:
            {{
              data && data.name
                ? data.name
                : `${data.customer && data.customer.firstName} ${
                    data.customer && data.customer.middleName
                  } ${data.customer && data.customer.lastName}`
            }}
          </p>
          <p class="lead">
            {{ $t("holdPayment") }}:
            {{ data && parseFloat(data.holdPayment).toLocaleString() }}
          </p>
          <div v-if="data && data.payType === 2">
            <p class="lead">
              {{ $t("bankName") }}: {{ data && data.bankName }}
            </p>
            <p class="lead">
              {{ $t("bankSlipNumber") }}: {{ data && data.bankSlipNumber }}
            </p>
            <p class="lead">
              {{ $t("bankSlipDate") }}:
              {{ data && formatDate(data.bankSlipDate) }}
            </p>
            <p class="lead">
              {{ $t("bankSlipNote") }}: {{ data && data.bankSlipNote }}
            </p>
          </div>
          <p class="lead">
            {{ $t("phone") }}: {{ data && replacePhone(data.phone) }}
          </p>
          <p class="lead">
            {{ $t("date") }}: {{ data && formatDate(data.date) }}
          </p>
          <p class="lead">
            {{ $t("deadline") }}: {{ data && formatDate(data.deadline) }}
          </p>
          <p class="lead">{{ $t("note") }}: {{ data && data.note }}</p>
          <p class="lead">{{ $t("attachment") }}:</p>
          <b-link
            v-if="data.attachment"
            :href="getImageUrl(data.attachment)"
            target="_blank"
          >
            <b-img
              :src="getImageUrl(data.attachment)"
              style="width: 800px; height: 400px"
              thumbnail
              rounded
              fluid
              :alt="data.property.name"
            ></b-img>
          </b-link>
          <p v-else>-</p>
          <add-attachment-modal
            update-url="propertyHolds/UPDATE_ATTACHMENT"
            :id="data.id"
            @success="loadData"
          />
        </b-card-body>
      </b-card>
      <div v-if="projectTenant() === 'MassHills'">
        <property-hold-design-mass-hills :data="data" />
      </div>
      <div v-if="projectTenant() === 'RoyalTower'">
        <property-hold-design-royal-tower :data="data" />
        <property-hold-design-royal-tower2 :data="data" />
      </div>

      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import PropertyHoldDesignRoyalTower from "@/views/propertyHolds/PropertyHoldDesignRoyalTower";
import PropertyHoldDesignRoyalTower2 from "@/views/propertyHolds/PropertyHoldDesignRoyalTower2";
import PropertyHoldDesignMassHills from "@/views/propertyHolds/PropertyHoldDesignMassHills";
import { permissions, serverParams } from "@/plugins/constants";
import AddAttachmentModal from "@/components/AddAttachmentModal";

export default {
  name: "CancelContractShow",
  components: {
    AddAttachmentModal,
    PropertyHoldDesignRoyalTower2,
    PropertyHoldDesignRoyalTower,
    PropertyHoldDesignMassHills,
    IsLoading,
    "v-content": Content,
    ContentHeader,
  },
  data() {
    return {
      isLoading: false,
      data: [],
      permissions: permissions,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("propertyHolds/GET_ONE", this.$route.params.id)
        .then((response) => {
          this.data = response.data;

          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    printVoucher() {
      let printContents = document.getElementById(
        `property-hold-voucher-1`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printVoucher2() {
      let printContents = document.getElementById(
        `property-hold-voucher-2`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
};
</script>
