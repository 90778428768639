import { i18n } from "@/plugins/i18n";

export class BuyContract {
  columns = [];
  id = "id";

  fillAbles = [
    ["contractNumber", i18n.t("contractNumber2"), true],
    ["propertyName", i18n.t("propertyName"), true],
    [
      "customerName",
      process.env.VUE_APP_PROJECT_NAME === "MassHills"
        ? i18n.t("buyerName")
        : i18n.t("customerName"),
      true,
    ],
    ["buyingDate", i18n.t("buyingDate"), true],
    ["buyingPrice", i18n.t("buyingPrice"), true],
    ["paidPrice", i18n.t("paidPrice"), true],
    ["discount", i18n.t("discount"), true],
    ["note", i18n.t("note"), true, false],
    ["controls", i18n.t("controls"), false],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: true,
        visibility: process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
