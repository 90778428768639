<template>
  <div v-if="hasPermission(permissions.buyContractsShow)">
    <content-header>{{ $t("showContract") }}</content-header>
    <v-content>
      <div v-show="kurdishPrint || arabicPrint" class="text-left m-2">
        <b-button
          class="no-print m-1"
          variant="primary"
          @click="
            kurdishPrint = false;
            arabicPrint = false;
          "
        >
          {{ $t("back") }}
        </b-button>
        <b-button class="no-print m-1" variant="info" @click="printContract">
          {{ $t("print") }}
        </b-button>
      </div>

      <!-- Start DiplomatiSafiran Contracts -->
      <kurdish-contract-zaiton-plus-city
        v-if="
          kurdishPrint && !isLoading && projectTenant() === 'ZaitonPlusCity'
        "
        :contract="form"
        :pageNumber="pageNumber"
        :removeAfterMonth="false"
        :contract-payments="installments"
        :contract-pre-payments="prePayments"
        :contract-delivery-key-payments="deliveringKeys"
      />
      <div style="font-size: 20px !important">
        <arabic-contract-zaiton-plus-city
          v-if="
            arabicPrint && !isLoading && projectTenant() === 'ZaitonPlusCity'
          "
          :contract="form"
          :pageNumber="pageNumber"
          :removeAfterMonth="false"
          :contract-payments="installments"
          :contract-pre-payments="prePayments"
          :contract-delivery-key-payments="deliveringKeys"
        />
      </div>
      <!-- End DiplomatiSafiran Contracts -->

      <b-card v-show="!kurdishPrint && !arabicPrint">
        <b-card-body v-if="!isLoading">
          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('name')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.property && form.property.name"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('zone')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.property && form.property.zone"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('fullName')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="
                      form.customer &&
                      `${form.customer.firstName} ${form.customer.middleName} ${form.customer.lastName}`
                    "
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('phone')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.customer && replacePhone(form.customer.phone)"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('finalPrice')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="
                      form.buyingPrice && form.buyingPrice.toLocaleString()
                    "
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('area')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="
                      form.property &&
                      (form.property.area && form.property.area == 0
                        ? form.property.buildUpArea
                        : form.property.area)
                    "
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('extraProperty1')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.property && form.property.extraProperty1"
                    :placeholder="$t('extraProperty1')"
                  ></b-form-input>
                  <b-form-input
                    disabled
                    :value="form.property && form.property.extraProperty2"
                    :placeholder="$t('extraProperty2')"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('address')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.customer && form.customer.address"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('phone2')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.customer && replacePhone(form.customer.phone2)"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-input-group
                  :prepend="$t('discount')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.discount && form.discount.toLocaleString()"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group>
                <b-input-group
                  :prepend="$t('note')"
                  class="mb-2 mr-sm-2 mb-sm-0"
                >
                  <b-form-input
                    disabled
                    :value="form.note && form.note"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" class="no-print">
              <b-button
                class="m-2"
                variant="primary"
                @click="kurdishPrint = true"
              >
                {{ $t("printKurdishContract") }}
              </b-button>
              <b-button
                v-if="hasPermission(permissions.buyContractsShowPrintContracts)"
                class="m-2"
                variant="warning"
                @click="arabicPrint = true"
              >
                {{ $t("printArabicContract") }}
              </b-button>
              <b-button
                v-if="hasPermission(permissions.buyContractsShowPrintPayments)"
                class="m-2 no-print"
                variant="info"
                @click="printContract"
              >
                {{ $t("printPayments") }}
              </b-button>

              <b-button
                v-if="hasPermission(permissions.transferContractsCreate)"
                :disabled="isTransferredOrCanceled"
                v-b-modal.transfer-contract-modal
                class="m-2 no-print"
                variant="success"
              >
                {{ $t("transferContract") }}
              </b-button>
              <b-modal
                id="transfer-contract-modal"
                :title="$t('transferContract')"
                size="xl"
              >
                <div class="d-block text-center">
                  <select-input
                    id="customer"
                    :name="$t('customer')"
                    label="firstName,middleName,lastName,+phone"
                    api-link="customers/GET_ALL"
                    required
                    @itemSelected="(e) => (transferContract.newCustomerId = e)"
                  />
                  <date-time-input
                    id="transferDate"
                    :name="$t('date')"
                    :validate="$v"
                    form="transferContract"
                    :required="true"
                    :is-loading="isLoading"
                  />
                  <text-area-input
                    id="note"
                    :name="$t('note')"
                    :validate="$v"
                    form="transferContract"
                    :is-loading="isLoading"
                  />
                  <file-input
                    id="attachment"
                    :required="true"
                    :name="$t('attachment')"
                    @fileAdded="
                      (base64File) => (transferContract.attachment = base64File)
                    "
                  />
                </div>
                <template #modal-footer="{ cancel }">
                  <b-button variant="secondary" @click="cancel()">
                    {{ $t("close") }}
                  </b-button>
                  <b-button variant="primary" @click="addTransferContract">
                    {{ $t("create") }}
                  </b-button>
                </template>
              </b-modal>

              <b-button
                v-if="hasPermission(permissions.cancelContractsCreate)"
                :disabled="isTransferredOrCanceled"
                v-b-modal.cancel-contract-modal
                class="m-2 no-print"
                variant="danger"
              >
                {{ $t("cancelContract") }}
              </b-button>
              <b-modal
                id="cancel-contract-modal"
                :title="$t('cancelContract')"
                size="xl"
              >
                <div class="d-block text-center">
                  <text-input
                    id="customer"
                    :name="$t('customer')"
                    form="cancelContract"
                    :readonly="true"
                    :default-value="
                      form.customer &&
                      `${form.customer.firstName} ${form.customer.middleName} ${form.customer.lastName}`
                    "
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-view :name="$t('paidPrice')" :value="paidPrice" />
                  <number-input
                    id="returnedPrice"
                    :name="$t('returnedPrice')"
                    form="cancelContract"
                    :required="true"
                    :default-value="cancelContract.returnedPrice"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <number-input
                    id="remainPrice"
                    :name="$t('remainPrice')"
                    form="cancelContract"
                    :required="true"
                    :readonly="true"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <date-time-input
                    id="date"
                    :name="$t('date')"
                    form="cancelContract"
                    :validate="$v"
                    :required="true"
                    :is-loading="isLoading"
                  />
                  <text-area-input
                    id="note"
                    :name="$t('note')"
                    :validate="$v"
                    form="cancelContract"
                    :is-loading="isLoading"
                  />
                  <file-input
                    id="attachment"
                    :required="true"
                    :name="$t('attachment')"
                    @fileAdded="
                      (base64File) => (cancelContract.attachment = base64File)
                    "
                  />
                </div>
                <template #modal-footer="{ cancel }">
                  <b-button variant="secondary" @click="cancel()">
                    {{ $t("close") }}
                  </b-button>
                  <b-button variant="primary" @click="addCancelContract">
                    {{ $t("create") }}
                  </b-button>
                </template>
              </b-modal>

              <!--              <b-button-->
              <!--                  v-if="hasPermission(permissions.cancelContractsCreate)"-->
              <!--                  :disabled="isTransferredOrCanceled"-->
              <!--                  v-b-modal.add-attachment-modal-->
              <!--                  class="m-2 no-print"-->
              <!--                  variant="primary"-->
              <!--              >-->
              <!--                {{ $t("addAttachments") }}-->
              <!--              </b-button>-->
              <b-modal
                id="add-attachment-modal"
                :title="$t('addAttachments')"
                size="xl"
              >
                <div class="d-block text-center">
                  <file-input
                    v-if="form.attachment === null"
                    id="attachment"
                    :required="true"
                    :name="$t('attachment')"
                    @fileAdded="
                      (base64File) => (contractAttachment = base64File)
                    "
                  />
                  <div v-else class="preview m-2">
                    <span
                      class="close"
                      @click="deleteContractAttachment($route.params.id)"
                    >
                      <i class="fas fa-times"></i>
                    </span>
                    <img
                      :src="getImageUrl(form.attachment)"
                      :alt="'test'"
                      width="100%"
                      height="100%"
                      style="object-fit: contain"
                    />
                  </div>
                </div>
                <template #modal-footer="{ cancel }">
                  <b-button variant="secondary" @click="cancel()">
                    {{ $t("close") }}
                  </b-button>
                  <b-button
                    v-if="form.attachment === null"
                    variant="primary"
                    @click="addAttachmentsToContract"
                  >
                    {{ $t("create") }}
                  </b-button>
                </template>
              </b-modal>
            </b-col>
            <b-col>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t("installment") }}</th>
                    <th>{{ $t("price") }}</th>
                    <th>{{ $t("date") }}</th>
                    <th class="no-print">{{ $t("isPaid") }}</th>
                    <th class="only-print">{{ $t("isPaid") }}</th>
                    <th class="no-print">{{ $t("action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(prePayment, index) in prePayments"
                    :key="prePayment.id"
                  >
                    <th>{{ $t("prepayment") }} {{ index + 1 }}</th>
                    <th>
                      <b-input-group
                        class="boldIt"
                        :prepend="
                          prePayment.currentPayPrice &&
                          '$' + prePayment.currentPayPrice.toLocaleString()
                        "
                      >
                        <b-form-textarea
                          :class="{
                            'no-print': projectTenant() === 'RoyalTower',
                          }"
                          rows="2"
                          disabled
                          style="resize: none"
                          :value="prePayment[getLanguage() + 'PriceText']"
                        ></b-form-textarea>
                      </b-input-group>
                      <br />
                      <div v-if="hasForgivePrice">
                        <b-input-group
                          :prepend="$t('forgivePrice')"
                          class="no-print"
                        >
                          <b-form-input
                            :id="'preForgivePrice_' + index"
                            :disabled="
                              prePayment.isPayed || isTransferredOrCanceled
                            "
                            type="number"
                            :prepend="$t('forgivePrice')"
                            :value="prePayment.currentForgivePrice"
                          ></b-form-input>
                        </b-input-group>
                        <br />
                      </div>
                      <b-input-group :prepend="$t('note')" class="no-print">
                        <b-form-textarea
                          :id="'preNote_' + index"
                          :disabled="
                            prePayment.isPayed || isTransferredOrCanceled
                          "
                          :value="prePayment.note"
                        ></b-form-textarea>
                      </b-input-group>
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          :id="'prePayedDate_' + index"
                          :disabled="
                            prePayment.isPayed || isTransferredOrCanceled
                          "
                          type="date"
                          :value="formatDate(prePayment.currentPayDate)"
                        ></b-form-input>
                      </b-input-group>
                      <div v-if="prePayment.isPayed">
                        <br />
                        <b-input-group :prepend="$t('paidDate')">
                          <b-form-input
                            disabled
                            type="date"
                            :value="formatDate(prePayment.payDate)"
                          ></b-form-input>
                        </b-input-group>
                      </div>
                      <br />
                      <b-input-group :prepend="$t('paidBy')" class="no-print">
                        <b-form-input
                          :id="'prePaidBy_' + index"
                          :disabled="
                            prePayment.isPayed || isTransferredOrCanceled
                          "
                          type="text"
                          placeholder="Paid By"
                          :value="
                            form.customer &&
                            form.customer.firstName +
                              ' ' +
                              form.customer.middleName +
                              ' ' +
                              form.customer.lastName
                          "
                        ></b-form-input>
                      </b-input-group>
                      <br />
                      <!-- <select
                        v-if="
                          projectTenant() === 'RoyalTower' ||
                          projectTenant() === 'Candle'
                        "
                        v-model="selectedSafeBoxId"
                        class="no-print form-control"
                        :disabled="
                          prePayment.isPayed || isTransferredOrCanceled
                        "
                      >
                        <option
                          v-for="safeBox in safeBoxes"
                          :value="safeBox.id"
                          :key="safeBox.id"
                        >
                          {{ safeBox.name }}
                        </option>
                      </select> -->
                      <br />
                      <b-input-group
                        :prepend="$t('invoiceNumber')"
                        @click="
                          getPaymentInvoiceNumber('preInvoiceNumber_' + index)
                        "
                      >
                        <b-form-input
                          :id="'preInvoiceNumber_' + index"
                          :disabled="prePayment.isPayed"
                          type="text"
                          :value="prePayment.invoiceNumber"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th class="no-print">
                      <b-button
                        v-if="hasPermission(permissions.buyContractsShowPay)"
                        @click="payPrePaymentInstallment(index)"
                        :disabled="
                          prePayment.isPayed ||
                          (index > 0 && !prePayments[index - 1].isPayed) ||
                          isTransferredOrCanceled
                        "
                        variant="primary"
                        class="m-2"
                      >
                        {{ $t("pay") }}
                      </b-button>
                      <b-button
                        class="m-2"
                        @click="unpayPaymentProcess(prePayment.id)"
                        v-if="
                          prePayment.isPayed &&
                          hasPermission(permissions.buyContractsShowUnPayButton)
                        "
                        variant="danger"
                      >
                        {{ $t("unpay") }}
                      </b-button>
                      <div v-if="hasTransferPaymentProcess">
                        <b-button
                          v-if="
                            !(
                              prePayment.isPayed ||
                              (index > 1 && !prePayments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            ) &&
                            hasPermission(
                              permissions.buyContractsShowTransferPaymentProcessButton
                            )
                          "
                          :disabled="
                            prePayment.isPayed ||
                            (index > 0 && !prePayments[index - 1].isPayed) ||
                            isTransferredOrCanceled
                          "
                          @click="showTransferPaymentModal(prePayment)"
                          class="m-2 no-print"
                          variant="primary"
                        >
                          {{ $t("transferPayment") }}
                        </b-button>
                        <b-modal
                          :id="'transfer-payment-modal-' + prePayment.id"
                          :title="$t('transferPayment')"
                          size="xl"
                        >
                          <div v-if="paymentToTransfer">
                            <h3>
                              {{ $t("paymentToTransfer") }}:
                              {{
                                paymentToTransfer.currentPayPrice.toLocaleString()
                              }}
                              -
                              {{ formatDate(paymentToTransfer.currentPayDate) }}
                              (
                              {{
                                (
                                  paymentToTransfer.currentPayPrice -
                                  transferPrice
                                ).toLocaleString()
                              }}
                              )
                            </h3>
                          </div>
                          <div class="d-block text-center">
                            <div class="form-group row">
                              <label
                                for="allPayments"
                                class="col-sm-2 col-form-label text-right required-field"
                                >{{ $t("payments") }}</label
                              >
                              <div class="col-sm-10">
                                <select
                                  id="allPayments"
                                  class="form-select"
                                  v-model="selectedTransferPayment"
                                >
                                  <option
                                    v-for="(
                                      unpaidPayment, index
                                    ) in unpaidPayments"
                                    :key="index"
                                    :value="unpaidPayment"
                                    :disabled="
                                      paymentToTransfer &&
                                      paymentToTransfer.id === unpaidPayment.id
                                    "
                                  >
                                    {{
                                      unpaidPayment.currentPayPrice.toLocaleString()
                                    }}
                                    -
                                    {{
                                      formatDate(unpaidPayment.currentPayDate)
                                    }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="transferPaymentPrice"
                                class="col-sm-2 col-form-label text-right required-field"
                                >{{ $t("price") }}</label
                              >
                              <div class="col-sm-10">
                                <input
                                  id="transferPaymentPrice"
                                  v-model="transferPrice"
                                  type="number"
                                  class="form-control"
                                  :placeholder="$t('price')"
                                />
                                <h4
                                  v-if="selectedTransferPayment"
                                  class="text-left"
                                >
                                  {{ $t("paymentPriceAfter") }}:
                                  {{
                                    (
                                      parseFloat(
                                        selectedTransferPayment.currentPayPrice
                                      ) + parseFloat(transferPrice)
                                    ).toLocaleString()
                                  }}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <template #modal-footer="{ cancel }">
                            <b-button variant="secondary" @click="cancel()"
                              >{{ $t("close") }}
                            </b-button>
                            <b-button
                              variant="primary"
                              @click="transferPaymentProcess"
                            >
                              {{ $t("create") }}
                            </b-button>
                          </template>
                        </b-modal>
                      </div>
                    </th>
                    <th class="only-print">
                      {{ prePayment.isPayed ? $t("yes") : $t("no") }}
                    </th>
                    <th class="no-print">
                      <b-button
                        v-if="
                          hasPermission(permissions.buyContractsShowPayPrint)
                        "
                        @click="prePrintVoucher(index)"
                        variant="primary"
                        :disabled="
                          !prePayment.isPayed || isTransferredOrCanceled
                        "
                      >
                        {{ $t("print") }}
                      </b-button>
                      <show-attachment
                        v-if="prePayment.bankSlipAttachment"
                        :attachment="prePayment.bankSlipAttachment"
                        :title="prePayment.id"
                      />
                      <br />
                      <b-button
                        class="m-2"
                        @click="printArabicPreInvoicePre(index)"
                        variant="success"
                        v-if="parseFloat(prePayment.currentForgivePrice) > 0"
                      >
                        {{ $t("discountAr") }}
                      </b-button>
                      <br />
                      <b-button
                        class="m-2"
                        @click="printKurdishPreInvoicePre(index)"
                        variant="success"
                        v-if="parseFloat(prePayment.currentForgivePrice) > 0"
                      >
                        {{ $t("discountKu") }}
                      </b-button>
                      <div
                        :id="'voucherPreReceiptDiv_' + index"
                        style="display: none"
                      >
                        <div>
                          <voucher-receipt
                            :voucherNo="`${prePayment.id}`"
                            :voucherDate="prePayment.payDate"
                            :paidName="prePayment.payedBy"
                            :amount="`${prePayment.currentPayPrice}`"
                            :note="prePayment.note"
                            :accountantName="prePayment.accountant"
                          />
                          <voucher-receipt
                            :voucherNo="`${prePayment.id}`"
                            :voucherDate="prePayment.payDate"
                            :paidName="prePayment.payedBy"
                            :amount="`${prePayment.currentPayPrice}`"
                            :note="prePayment.note"
                            :accountantName="prePayment.accountant"
                          />
                        </div>
                      </div>
                      <div
                        :id="'zaitonArabicVoucherPrePrintDivPre_' + index"
                        style="display: none"
                      >
                        <ArabicZaitonPlusCityDiscountReceipt
                          :propertyName="`${form.property.name}`"
                          :customerName="form.customer.fullName"
                          :contractNumber="form.contractNumber"
                          :contractDate="`${moment(form.buyingDate).format(
                            'YYYY-MM-DD'
                          )}`"
                          :contractTotalPrice="form.buyingPrice.toString()"
                          :discount="prePayment.currentForgivePrice.toString()"
                        />
                      </div>
                      <div
                        :id="'zaitonKurdishVoucherPrePrintDivPre_' + index"
                        style="display: none"
                      >
                        <KurdishZaitonPlusCityDiscountReceipt
                          :propertyName="`${form.property.name}`"
                          :customerName="form.customer.fullName"
                          :contractNumber="form.contractNumber"
                          :contractDate="`${moment(form.buyingDate).format(
                            'YYYY-MM-DD'
                          )}`"
                          :contractTotalPrice="form.buyingPrice.toString()"
                          :discount="prePayment.currentForgivePrice.toString()"
                        />
                      </div>
                      <div
                        :id="'zaitonArabicVoucherDiscountPrintDivPre_' + index"
                        style="display: none"
                      >
                        <ArabicZaitonPlusCityDiscountReceipt
                          :propertyName="`${form.property.name}`"
                          :customerName="form.customer.fullName"
                          :contractNumber="form.contractNumber"
                          :contractDate="`${moment(form.buyingDate).format(
                            'YYYY-MM-DD'
                          )}`"
                          :contractTotalPrice="form.buyingPrice.toString()"
                          :discount="prePayment.currentForgivePrice.toString()"
                        />
                      </div>
                      <div
                        :id="'zaitonKurdishVoucherDiscountPrintDivPre_' + index"
                        style="display: none"
                      >
                        <KurdishZaitonPlusCityDiscountReceipt
                          :propertyName="`${form.property.name}`"
                          :customerName="form.customer.fullName"
                          :contractNumber="form.contractNumber"
                          :contractDate="`${moment(form.buyingDate).format(
                            'YYYY-MM-DD'
                          )}`"
                          :contractTotalPrice="form.buyingPrice.toString()"
                          :discount="prePayment.currentForgivePrice.toString()"
                        />
                      </div>
                    </th>
                  </tr>
                  <tr v-for="(installment, index) in installments" :key="index">
                    <th>
                      {{ ++index }}
                    </th>
                    <th>
                      <b-input-group
                        class="boldIt"
                        :prepend="
                          installment.currentPayPrice &&
                          '$' + installment.currentPayPrice.toLocaleString()
                        "
                      >
                        <b-form-textarea
                          :class="{
                            'no-print': projectTenant() === 'RoyalTower',
                          }"
                          rows="2"
                          disabled
                          style="resize: none"
                          :value="installment[getLanguage() + 'PriceText']"
                        ></b-form-textarea>
                      </b-input-group>

                      <b-input-group
                        class="boldIt"
                        style="margin-top: 5px"
                        :prepend="$t('remainPrice')"
                      >
                        <b-form-textarea
                          :class="{
                            'no-print': projectTenant() === 'RoyalTower',
                          }"
                          disabled
                          style="
                            resize: none;
                            height: 40px;
                            color: red;
                            font-weight: bold;
                            font-size: 20px;
                          "
                          :value="
                            (
                              parseFloat(installment.currentPayPrice) -
                              parseFloat(
                                installment.partialPrice == null
                                  ? 0
                                  : installment.partialPrice
                              )
                            ).toLocaleString()
                          "
                        ></b-form-textarea>
                      </b-input-group>
                      <br />

                      <div v-if="hasForgivePrice">
                        <b-input-group
                          :prepend="$t('forgivePrice')"
                          class="no-print"
                        >
                          <b-form-input
                            :id="'forgivePrice_' + index"
                            :disabled="
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            "
                            type="number"
                            :prepend="$t('forgivePrice')"
                            :value="installment.currentForgivePrice"
                          ></b-form-input>
                        </b-input-group>
                        <br />
                      </div>
                      <b-input-group :prepend="$t('note')" class="no-print">
                        <b-form-textarea
                          :id="'note_' + index"
                          :disabled="
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          "
                          :value="installment.note"
                        ></b-form-textarea>
                      </b-input-group>
                      <br />
                      <div
                        v-if="
                          usePartialPayment &&
                          hasPermission(permissions.buyContractsShowPay)
                        "
                        class="no-print"
                      >
                        <b-input-group :prepend="$t('partialPaymentPrice')">
                          <b-form-input
                            :id="'partialPrice_' + index"
                            :disabled="
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            "
                            type="number"
                            placeholder="$t('partialPaymentPrice')"
                            :value="installment.partialPrice"
                          ></b-form-input>
                        </b-input-group>
                        <br />
                        <b-input-group :prepend="$t('partialPaymentNote')">
                          <b-form-textarea
                            :id="'partialNote_' + index"
                            :disabled="
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            "
                            :placeholder="$t('partialPaymentNote')"
                            :value="installment.partialNote"
                          ></b-form-textarea>
                        </b-input-group>
                        <br />
                        <b-input-group :prepend="$t('partialPaymentDate')">
                          <b-form-input
                            :id="'partialDate_' + index"
                            :disabled="
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            "
                            type="date"
                            :value="formatDate(installment.partialDate)"
                          ></b-form-input>
                        </b-input-group>
                        <br />
                        <b-input-group :prepend="$t('partialPaymentDeadline')">
                          <b-form-input
                            :id="'partialDeadline_' + index"
                            :disabled="
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            "
                            type="date"
                            :value="formatDate(installment.partialDeadline)"
                          ></b-form-input>
                        </b-input-group>
                      </div>
                      <!--Bank Payment-->
                      <!-- <div
                        v-if="
                          hasBankPayment()  &&
                          !(
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          )
                        "
                      >
                        <br />
                        <b-form-checkbox
                          v-model="isBankPayment"
                          class="no-print"
                          name="isBankPayment"
                          switch
                        >
                          {{ $t("isBankPayment") }}
                        </b-form-checkbox>
                        <br />
                        <div v-if="isBankPayment">
                          <b-input-group :prepend="$t('bankName')">
                            <b-form-input
                              :id="'bankName_' + index"
                              :disabled="
                                installment.isPayed || isTransferredOrCanceled
                              "
                            ></b-form-input>
                          </b-input-group>
                          <br />
                          <b-input-group :prepend="$t('bankSlipNumber')">
                            <b-form-input
                              :id="'bankSlipNumber_' + index"
                              :disabled="
                                installment.isPayed || isTransferredOrCanceled
                              "
                            ></b-form-input>
                          </b-input-group>
                          <br />
                          <b-input-group :prepend="$t('bankSlipDate')">
                            <input
                              :id="'bankSlipDate_' + index"
                              type="date"
                              class="form-control"
                              :disabled="
                                installment.isPayed || isTransferredOrCanceled
                              "
                            />
                          </b-input-group>
                          <br />
                          <b-input-group :prepend="$t('bankSlipNote')">
                            <b-form-textarea
                              :id="'bankSlipNote_' + index"
                              :disabled="
                                installment.isPayed || isTransferredOrCanceled
                              "
                            ></b-form-textarea>
                          </b-input-group>
                          <br />
                          <b-input-group :prepend="$t('bankSlipAttachment')">
                            <b-form-file
                              :id="'bankSlipAttachment_' + index"
                              placeholder="Choose a file or drop it here..."
                              :disabled="
                                installment.isPayed || isTransferredOrCanceled
                              "
                              drop-placeholder="Drop file here..."
                              accept="image/png, image/jpeg, image/gif"
                            ></b-form-file>
                          </b-input-group>
                        </div>
                      </div> -->
                    </th>
                    <th>
                      <b-input-group :prepend="$t('date')">
                        <b-form-input
                          :id="'payedDate_' + index"
                          :disabled="
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          "
                          type="date"
                          :value="formatDate(installment.currentPayDate)"
                        ></b-form-input>
                      </b-input-group>
                      <div v-if="installment.isPayed">
                        <br />
                        <b-input-group :prepend="$t('paidDate')">
                          <b-form-input
                            disabled
                            type="date"
                            :value="formatDate(installment.payDate)"
                          ></b-form-input>
                        </b-input-group>
                      </div>
                      <br />
                      <b-input-group :prepend="$t('paidBy')" class="no-print">
                        <b-form-input
                          :id="'paidBy_' + index"
                          :disabled="
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          "
                          type="text"
                          :placeholder="$t('paidBy')"
                          :value="
                            form.customer &&
                            form.customer.firstName +
                              ' ' +
                              form.customer.middleName +
                              ' ' +
                              form.customer.lastName
                          "
                        ></b-form-input>
                      </b-input-group>
                      <br />
                      <b-input-group
                        :prepend="$t('extraPrice')"
                        class="no-print"
                      >
                        <b-form-input
                          :id="'extraPrice_' + index"
                          :disabled="
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          "
                          type="number"
                          placeholder="Extra Price"
                          :value="0"
                        ></b-form-input>
                      </b-input-group>
                      <br />
                      <b-input-group
                        :prepend="$t('extraNote')"
                        class="no-print"
                      >
                        <b-form-textarea
                          :id="'extraNote_' + index"
                          :disabled="
                            installment.isPayed ||
                            (index > 1 && !installments[index - 2].isPayed) ||
                            isTransferredOrCanceled
                          "
                          placeholder="Extra Note"
                        ></b-form-textarea>
                      </b-input-group>
                      <br />
                      <select
                        v-if="
                          projectTenant() === 'RoyalTower' ||
                          projectTenant() === 'Candle'
                        "
                        v-model="selectedSafeBoxId"
                        class="no-print form-control"
                        :disabled="
                          installment.isPayed ||
                          (index > 1 && !installments[index - 2].isPayed) ||
                          isTransferredOrCanceled
                        "
                      >
                        <option
                          v-for="safeBox in safeBoxes"
                          :value="safeBox.id"
                          :key="safeBox.id"
                        >
                          {{ safeBox.name }}
                        </option>
                      </select>
                      <br />
                      <b-input-group
                        :prepend="$t('invoiceNumber')"
                        @click="
                          getPaymentInvoiceNumber('invoiceNumber_' + index)
                        "
                      >
                        <b-form-input
                          :id="'invoiceNumber_' + index"
                          :disabled="
                            (index > 1 && !installments[index - 2].isPayed) ||
                            installment.isPayed
                          "
                          type="text"
                          :value="installment.invoiceNumber"
                        ></b-form-input>
                      </b-input-group>
                    </th>
                    <th class="no-print">
                      <b-button
                        v-if="hasPermission(permissions.buyContractsShowPay)"
                        class="m-2"
                        :disabled="
                          installment.isPayed ||
                          (index > 1 && !installments[index - 2].isPayed) ||
                          isTransferredOrCanceled
                        "
                        variant="primary"
                        @click="payInstallment(index)"
                      >
                        {{ $t("pay") }}
                      </b-button>
                      <b-button
                        class="m-2"
                        @click="unpayPaymentProcess(installment.id)"
                        v-if="
                          installment.isPayed &&
                          hasPermission(permissions.buyContractsShowUnPayButton)
                        "
                        variant="danger"
                      >
                        {{ $t("unpay") }}
                      </b-button>
                      <b-button
                        v-if="
                          usePartialPayment &&
                          hasPermission(permissions.buyContractsShowPay)
                        "
                        class="m-1"
                        :disabled="
                          installment.isPayed ||
                          (index > 1 && !installments[index - 2].isPayed) ||
                          isTransferredOrCanceled
                        "
                        variant="warning"
                        @click="partialPayInstallment(index)"
                      >
                        {{ $t("partialPay") }}
                      </b-button>

                      <div v-if="hasTransferPaymentProcess">
                        <b-button
                          v-if="
                            !(
                              installment.isPayed ||
                              (index > 1 && !installments[index - 2].isPayed) ||
                              isTransferredOrCanceled
                            ) &&
                            hasPermission(
                              permissions.buyContractsShowTransferPaymentProcessButton
                            )
                          "
                          @click="showTransferPaymentModal(installment)"
                          class="m-2 no-print"
                          variant="primary"
                        >
                          {{ $t("transferPayment") }}
                        </b-button>
                        <b-modal
                          :id="'transfer-payment-modal-' + installment.id"
                          :title="$t('transferPayment')"
                          size="xl"
                        >
                          <div v-if="paymentToTransfer">
                            <h3>
                              {{ $t("paymentToTransfer") }}:
                              {{
                                paymentToTransfer.currentPayPrice.toLocaleString()
                              }}
                              -
                              {{ formatDate(paymentToTransfer.currentPayDate) }}
                              ({{
                                (
                                  paymentToTransfer.currentPayPrice -
                                  transferPrice
                                ).toLocaleString()
                              }})
                            </h3>
                          </div>
                          <div class="d-block text-center">
                            <div class="form-group row">
                              <label
                                for="allPayments"
                                class="col-sm-2 col-form-label text-right required-field"
                              >
                                {{ $t("payments") }}</label
                              >
                              <div class="col-sm-10">
                                <select
                                  id="allPayments"
                                  class="form-select"
                                  v-model="selectedTransferPayment"
                                >
                                  <option
                                    v-for="(
                                      unpaidPayment, index
                                    ) in unpaidPayments"
                                    :key="index"
                                    :value="unpaidPayment"
                                    :disabled="
                                      paymentToTransfer &&
                                      paymentToTransfer.id === unpaidPayment.id
                                    "
                                  >
                                    {{
                                      unpaidPayment.currentPayPrice.toLocaleString()
                                    }}
                                    -
                                    {{
                                      formatDate(unpaidPayment.currentPayDate)
                                    }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                for="transferPaymentPrice"
                                class="col-sm-2 col-form-label text-right required-field"
                              >
                                {{ $t("price") }}
                              </label>
                              <div class="col-sm-10">
                                <input
                                  id="transferPaymentPrice"
                                  v-model="transferPrice"
                                  type="number"
                                  class="form-control"
                                  :placeholder="$t('price')"
                                />
                                <h4
                                  v-if="selectedTransferPayment"
                                  class="text-left"
                                >
                                  {{ $t("paymentPriceAfter") }}:
                                  {{
                                    (
                                      parseFloat(
                                        selectedTransferPayment.currentPayPrice
                                      ) + parseFloat(transferPrice)
                                    ).toLocaleString()
                                  }}
                                </h4>
                              </div>
                            </div>
                          </div>
                          <template #modal-footer="{ cancel }">
                            <b-button variant="secondary" @click="cancel()">
                              {{ $t("close") }}
                            </b-button>
                            <b-button
                              variant="primary"
                              @click="transferPaymentProcess"
                            >
                              {{ $t("create") }}
                            </b-button>
                          </template>
                        </b-modal>
                      </div>
                    </th>
                    <th class="only-print">
                      {{ installment.isPayed ? $t("yes") : $t("no") }}
                    </th>
                    <th class="no-print text-center">
                      <b-button
                        :disabled="
                          !installment.isPayed || isTransferredOrCanceled
                        "
                        variant="primary"
                        @click="printVoucher(index)"
                      >
                        {{ $t("print") }}
                      </b-button>
                      <br />
                      <b-button
                        class="m-2"
                        @click="printArabicDiscountInvoiceInstallment(index)"
                        variant="success"
                        v-if="parseFloat(installment.currentForgivePrice) > 0"
                      >
                        {{ $t("discountAr") }}
                      </b-button>
                      <br />
                      <b-button
                        class="m-2"
                        @click="printKurdishDiscountInvoiceInstallment(index)"
                        variant="success"
                        v-if="parseFloat(installment.currentForgivePrice) > 0"
                      >
                        {{ $t("discountKu") }}
                      </b-button>
                      <p
                        v-if="parseFloat(installment.currentForgivePrice) > 0"
                        style="max-width: 75px"
                      >
                        print discount invoice
                      </p>
                      <div
                        :id="'voucherReceiptDiv_' + index"
                        style="display: none"
                      >
                        <div>
                          <voucher-receipt
                            :voucherNo="`${installment.id}`"
                            :voucherDate="installment.payDate"
                            :paidName="installment.payedBy"
                            :amount="`${installment.currentPayPrice}`"
                            :note="installment.note"
                            :accountantName="installment.accountant"
                          />
                          <voucher-receipt
                            :voucherNo="`${installment.id}`"
                            :voucherDate="installment.payDate"
                            :paidName="installment.payedBy"
                            :amount="`${installment.currentPayPrice}`"
                            :note="installment.note"
                            :accountantName="installment.accountant"
                          />
                        </div>
                        <div
                          :id="
                            'zaitonArabicVoucherDiscountPrintDivInstallment_' +
                            index
                          "
                          style="display: none"
                        >
                          <ArabicZaitonPlusCityDiscountReceipt
                            :propertyName="`${form.property.name}`"
                            :customerName="form.customer.fullName"
                            :contractNumber="form.contractNumber"
                            :contractDate="`${moment(form.buyingDate).format(
                              'YYYY-MM-DD'
                            )}`"
                            :contractTotalPrice="form.buyingPrice.toString()"
                            :discount="
                              installment.currentForgivePrice.toString()
                            "
                          />
                        </div>
                        <div
                          :id="
                            'zaitonKurdishVoucherDiscountPrintDivInstallment_' +
                            index
                          "
                          style="display: none"
                        >
                          <KurdishZaitonPlusCityDiscountReceipt
                            :propertyName="`${form.property.name}`"
                            :customerName="form.customer.fullName"
                            :contractNumber="form.contractNumber"
                            :contractDate="`${moment(form.buyingDate).format(
                              'YYYY-MM-DD'
                            )}`"
                            :contractTotalPrice="form.buyingPrice.toString()"
                            :discount="
                              installment.currentForgivePrice.toString()
                            "
                          />
                        </div>
                      </div>
                    </th>
                  </tr>

                  <div v-if="deliveringKeys != null">
                    <tr v-if="this.form.buyType === 'Installment'">
                      <th>{{ $t("deliveringKeys") }}</th>

                      <th>
                        <b-input-group
                          class="boldIt"
                          :prepend="
                            deliveringKeys.currentPayPrice &&
                            '$' +
                              deliveringKeys.currentPayPrice.toLocaleString()
                          "
                        >
                          <b-form-textarea
                            :class="{
                              'no-print': projectTenant() === 'RoyalTower',
                            }"
                            rows="2"
                            disabled
                            style="resize: none"
                            :value="deliveringKeys[getLanguage() + 'PriceText']"
                          ></b-form-textarea>
                        </b-input-group>
                        <br />

                        <div v-if="hasForgivePrice">
                          <b-input-group
                            :prepend="$t('forgivePrice')"
                            class="no-print"
                          >
                            <b-form-input
                              id="keyForgivePrice"
                              :disabled="
                                deliveringKeys.isPayed ||
                                isTransferredOrCanceled
                              "
                              type="number"
                              :prepend="$t('forgivePrice')"
                              :value="deliveringKeys.currentForgivePrice"
                            ></b-form-input>
                          </b-input-group>
                          <br />
                        </div>
                        <b-input-group :prepend="$t('note')" class="no-print">
                          <b-form-textarea
                            id="keyNote"
                            :disabled="
                              deliveringKeys.isPayed || isTransferredOrCanceled
                            "
                            :value="deliveringKeys.note"
                          ></b-form-textarea>
                        </b-input-group>
                        <div
                          v-if="
                            hasBankPayment() &&
                            (projectTenant() === 'RoyalTower' ||
                              projectTenant() === 'Candle')
                          "
                        >
                          <br />
                          <!--Start Bank Slip Payment-->
                          <b-form-checkbox
                            v-model="isBankPayment"
                            class="no-print"
                            name="isBankPayment"
                            switch
                          >
                            {{ $t("isBankPayment") }}
                          </b-form-checkbox>
                          <br />
                          <div v-if="isBankPayment">
                            <b-input-group :prepend="$t('bankName')">
                              <b-form-input
                                id="keyBankName"
                                :disabled="
                                  deliveringKeys.isPayed ||
                                  isTransferredOrCanceled
                                "
                              ></b-form-input>
                            </b-input-group>
                            <br />
                            <b-input-group :prepend="$t('bankSlipNumber')">
                              <b-form-input
                                id="keyBankSlipNumber"
                                :disabled="
                                  deliveringKeys.isPayed ||
                                  isTransferredOrCanceled
                                "
                              ></b-form-input>
                            </b-input-group>
                            <br />
                            <b-input-group :prepend="$t('bankSlipDate')">
                              <input
                                id="keyBankSlipDate"
                                type="date"
                                class="form-control"
                                :disabled="
                                  deliveringKeys.isPayed ||
                                  isTransferredOrCanceled
                                "
                              />
                            </b-input-group>
                            <br />
                            <b-input-group :prepend="$t('bankSlipNote')">
                              <b-form-textarea
                                id="keyBankSlipNote"
                                :disabled="
                                  deliveringKeys.isPayed ||
                                  isTransferredOrCanceled
                                "
                              ></b-form-textarea>
                            </b-input-group>
                            <br />
                            <b-input-group :prepend="$t('bankSlipAttachment')">
                              <b-form-file
                                id="keyBankSlipAttachment"
                                placeholder="Choose a file or drop it here..."
                                :disabled="
                                  deliveringKeys.isPayed ||
                                  isTransferredOrCanceled
                                "
                                drop-placeholder="Drop file here..."
                                accept="image/png, image/jpeg, image/gif"
                              ></b-form-file>
                            </b-input-group>
                          </div>
                          <!--End Bank Slip Payment-->
                        </div>
                      </th>

                      <th>
                        <b-input-group :prepend="$t('date')">
                          <b-form-input
                            id="keyPayedDate"
                            :disabled="
                              deliveringKeys.isPayed || isTransferredOrCanceled
                            "
                            type="date"
                            :value="formatDate(deliveringKeys.currentPayDate)"
                          ></b-form-input>
                        </b-input-group>
                        <div v-if="deliveringKeys.isPayed">
                          <br />
                          <b-input-group :prepend="$t('paidDate')">
                            <b-form-input
                              disabled
                              type="date"
                              :value="formatDate(deliveringKeys.payDate)"
                            ></b-form-input>
                          </b-input-group>
                        </div>
                        <br />
                        <b-input-group :prepend="$t('paidBy')" class="no-print">
                          <b-form-input
                            id="keyPaidBy"
                            :disabled="
                              deliveringKeys.isPayed || isTransferredOrCanceled
                            "
                            type="text"
                            placeholder="Paid By"
                            :value="
                              form.customer &&
                              form.customer.firstName +
                                ' ' +
                                form.customer.middleName +
                                ' ' +
                                form.customer.lastName
                            "
                          ></b-form-input>
                        </b-input-group>
                        <br />
                        <select
                          v-if="
                            projectTenant() === 'RoyalTower' ||
                            projectTenant() === 'Candle'
                          "
                          v-model="selectedSafeBoxId"
                          class="no-print form-control"
                          :disabled="
                            deliveringKeys.isPayed || isTransferredOrCanceled
                          "
                        >
                          <option
                            v-for="safeBox in safeBoxes"
                            :value="safeBox.id"
                            :key="safeBox.id"
                          >
                            {{ safeBox.name }}
                          </option>
                        </select>
                        <br />
                        <b-input-group
                          :prepend="$t('invoiceNumber')"
                          @click="getPaymentInvoiceNumber('keyInvoiceNumber')"
                        >
                          <b-form-input
                            id="keyInvoiceNumber"
                            :disabled="
                              projectTenant() !== 'RoyalTower' ||
                              deliveringKeys.isPayed
                            "
                            type="text"
                            :value="deliveringKeys.invoiceNumber"
                          ></b-form-input>
                        </b-input-group>
                      </th>

                      <th class="no-print">
                        <b-button
                          v-if="hasPermission(permissions.buyContractsShowPay)"
                          class="m-2"
                          @click="payKeyPaymentInstallment"
                          :disabled="
                            deliveringKeys.isPayed || isTransferredOrCanceled
                          "
                          variant="primary"
                        >
                          {{ $t("pay") }}
                        </b-button>
                        <b-button
                          class="m-2"
                          @click="unpayPaymentProcess(deliveringKeys.id)"
                          v-if="
                            deliveringKeys.isPayed &&
                            hasPermission(
                              permissions.buyContractsShowUnPayButton
                            )
                          "
                          variant="danger"
                        >
                          {{ $t("unpay") }}
                        </b-button>
                      </th>
                      <th class="only-print">
                        {{ deliveringKeys.isPayed ? $t("yes") : $t("no") }}
                      </th>
                      <th class="no-print">
                        <b-button
                          v-if="
                            hasPermission(permissions.buyContractsShowPayPrint)
                          "
                          @click="keyPrintVoucher"
                          :disabled="
                            !deliveringKeys.isPayed || isTransferredOrCanceled
                          "
                          variant="primary"
                        >
                          {{ $t("print") }}
                        </b-button>
                        <div
                          id="zaitonArabicVoucherDiscountPrintDivDeliveryKey"
                          style="display: none"
                        >
                          <ArabicZaitonPlusCityDiscountReceipt
                            :propertyName="`${form.property.name}`"
                            :customerName="form.customer.fullName"
                            :contractNumber="form.contractNumber"
                            :contractDate="`${moment(form.buyingDate).format(
                              'YYYY-MM-DD'
                            )}`"
                            :contractTotalPrice="form.buyingPrice.toString()"
                            :discount="
                              deliveringKeys.currentForgivePrice.toString()
                            "
                          />
                        </div>
                        <div
                          id="zaitonKurdishVoucherDiscountPrintDivDeliveryKey"
                          style="display: none"
                        >
                          <KurdishZaitonPlusCityDiscountReceipt
                            :propertyName="`${form.property.name}`"
                            :customerName="form.customer.fullName"
                            :contractNumber="form.contractNumber"
                            :contractDate="`${moment(form.buyingDate).format(
                              'YYYY-MM-DD'
                            )}`"
                            :contractTotalPrice="form.buyingPrice.toString()"
                            :discount="
                              deliveringKeys.currentForgivePrice.toString()
                            "
                          />
                        </div>
                        <show-attachment
                          v-if="deliveringKeys.bankSlipAttachment"
                          :attachment="deliveringKeys.bankSlipAttachment"
                          :title="deliveringKeys.id"
                        />
                        <br />
                        <b-button
                          class="m-2"
                          @click="printArabicDiscountInvoiceDeliveryKey"
                          variant="success"
                          v-if="
                            parseFloat(deliveringKeys.currentForgivePrice) > 0
                          "
                        >
                          {{ $t("discountAr") }}
                        </b-button>
                        <br />
                        <b-button
                          class="m-2"
                          @click="printKurdishDiscountInvoiceDeliveryKey"
                          variant="success"
                          v-if="
                            parseFloat(deliveringKeys.currentForgivePrice) > 0
                          "
                        >
                          {{ $t("discountKu") }}
                        </b-button>
                        <p
                          v-if="
                            parseFloat(deliveringKeys.currentForgivePrice) > 0
                          "
                          style="max-width: 75px"
                        >
                          print discount invoice
                        </p>
                        {deliveringKeys &&
                        <div :id="'voucherKeyReceiptDiv'" style="display: none">
                          <div>
                            <voucher-receipt
                              :voucherNo="`${deliveringKeys.id}`"
                              :voucherDate="deliveringKeys.payDate"
                              :paidName="deliveringKeys.payedBy"
                              :amount="`${deliveringKeys.currentPayPrice}`"
                              :note="deliveringKeys.note"
                              :accountantName="deliveringKeys.accountant"
                            />
                            <voucher-receipt
                              :voucherNo="`${deliveringKeys.id}`"
                              :voucherDate="deliveringKeys.payDate"
                              :paidName="deliveringKeys.payedBy"
                              :amount="`${deliveringKeys.currentPayPrice}`"
                              :note="deliveringKeys.note"
                              :accountantName="deliveringKeys.accountant"
                            />
                          </div>
                        </div>
                        }
                      </th>
                    </tr>
                  </div>
                </tbody>
              </table>
              <div class="d-flex mb-3">
                <b-input-group
                  v-if="projectTenant() === 'ZaitonPlusCity'"
                  :prepend="$t('pageNumber')"
                  class="w-25 mr-3"
                >
                  <b-form-input
                    id="keyInvoicepageNumberNumber"
                    type="number"
                    v-model="pageNumber"
                  ></b-form-input>
                </b-input-group>
                <h5>
                  {{ $t("paidPrice") }}: ${{ totalPaidPrice.toLocaleString() }}
                  -
                  {{ $t("remainPrice") }}: ${{
                    totalRemainPrice.toLocaleString()
                  }}
                </h5>
              </div>
              <b-progress
                height="2rem"
                :value="parseFloat(totalPaidPrice)"
                :max="parseFloat(totalPaidPrice) + parseFloat(totalRemainPrice)"
                show-progress
                :precision="2"
                show-value
                class="mb-3"
              ></b-progress>
            </b-col>
          </b-row>
        </b-card-body>
        <is-loading v-else />
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import lodash from "lodash";
import Swal from "sweetalert2";
import VoucherReceipt from "../contractDesigns/VoucherReceipt";
import ArabicZaitonPlusCityDiscountReceipt from "../contractDesigns/DiscountReciptDesigns/ArabicZaitonPlusCityDiscountReceipt";
import KurdishZaitonPlusCityDiscountReceipt from "../contractDesigns/DiscountReciptDesigns/KurdishZaitonPlusCityDiscountReceipt";
import KurdishContractZaitonPlusCity from "@/views/contractDesigns/KurdishContractZaitonPlusCity";
import SelectInput from "@/components/Inputs/SelectInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import FileInput from "@/components/Inputs/FileInput";
import { required } from "vuelidate/lib/validators";
import { TransferContract } from "@/models/TransferContract";
import TextInput from "@/components/Inputs/TextInput";
import { CancelContract } from "@/models/CancelContract";
import NumberInput from "@/components/Inputs/NumberInput";
import TextView from "@/components/TextView";
import ArabicContractZaitonPlusCity from "@/views/contractDesigns/ArabicContractZaitonPlusCity";
import { permissions, serverParams, swalMixin } from "@/plugins/constants";
import mixins from "@/mixins";
import ShowAttachment from "@/components/ShowAttachment";

export default {
  name: "ShowZaitonPlusCity",
  components: {
    ShowAttachment,
    TextView,
    NumberInput,
    TextInput,
    FileInput,
    DateTimeInput,
    TextAreaInput,
    SelectInput,
    KurdishContractZaitonPlusCity,
    ArabicContractZaitonPlusCity,
    VoucherReceipt,
    ArabicZaitonPlusCityDiscountReceipt,
    KurdishZaitonPlusCityDiscountReceipt,
    IsLoading,
    ContentHeader,
    "v-content": Content,
  },
  data() {
    return {
      form: {},
      transferContract: this.$helpers.convertArrayToObject(
        new TransferContract().fillAbles
      ),
      cancelContract: this.$helpers.convertArrayToObject(
        new CancelContract().fillAbles
      ),
      isLoading: false,
      kurdishPrint: false,
      arabicPrint: false,
      isBankPayment: false,
      selectedItem: {},
      prePayments: {},
      deliveringKeys: {},
      installments: {},
      usePartialPayment: process.env.VUE_APP_PARTIAL_PAYMENT_MODULE === "true",
      isTransferredOrCanceled: false,
      paidPrice: 0,
      totalPaidPrice: 0,
      totalRemainPrice: 0,
      selectedSafeBoxId: 1,
      safeBoxes: [],
      permissions: permissions,
      contractAttachment: null,
      unpaidPayments: [],
      paymentToTransfer: null,
      selectedTransferPayment: null,
      transferPrice: 0,
      hasTransferPaymentProcess:
        process.env.VUE_APP_TRANSFER_PAYMENT_PROCESS_MODULE === "true",
      hasForgivePrice:
        process.env.VUE_APP_PAYMENT_PROCESS_FORGIVE_PRICE_MODULE === "true",
      buyContractAttachments: [],
      is20Percent: false,
      buyContractExpensesTotal: 0,
      pageNumber: 0,
      currentDateFromServer: null,
    };
  },
  watch: {
    cancelContract: {
      handler() {
        this.cancelContract.remainPrice =
          parseFloat(this.paidPrice) -
          parseFloat(this.cancelContract.returnedPrice);
      },
      deep: true,
    },
    is20Percent: {
      handler(val) {
        document.getElementById("preForgivePrice_0").value = val
          ? this.form.property.buildUpArea * 20
          : 0;
      },
      deep: true,
    },
  },
  validations: {
    transferContract: {
      newCustomerId: { required },
      transferDate: { required },
      note: {},
      attachment: {},
    },
    cancelContract: {
      customer: {},
      returnedPrice: { required },
      remainPrice: { required },
      date: { required },
      note: {},
      attachment: {},
    },
  },
  mounted() {
    this.loadData();
    this.$store.dispatch("safeBoxes/GET_ALL", serverParams).then(() => {
      this.safeBoxes = this.$store.getters["safeBoxes/safeBoxes"];
    });
    this.$store
      .dispatch("buyContracts/EXPENSES", {
        id: this.$route.params.id,
        serverParams,
      })
      .then((response) => {
        this.buyContractExpensesTotal = parseFloat(response.headers["mctotal"]);
      });
    if (this.projectTenant() == "Candle") {
      mixins.getCurrentDateTimeServer().then((response) => {
        this.currentDateFromServer = response.datetime;
      });
    }
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/GET_ONE", this.$route.params.id)
        .then(async (response) => {
          this.form = await response.data;
          this.buyContractAttachments = response.data.buyContractAttachments;
          this.isLoading = false;
          response.data.paymentProcess.reduce((total, item) => {
            item.isPayed
              ? (this.totalPaidPrice += item.currentPayPrice)
              : (this.totalRemainPrice += item.currentPayPrice);
          }, 0);
          this.installments = lodash.filter(
            response.data.paymentProcess,
            (x) => {
              return !x.isItKeyPayment && !x.isItPrePayment;
            }
          );
          this.prePayments = lodash.filter(
            response.data.paymentProcess,
            (x) => {
              return x.isItPrePayment;
            }
          );
          // console.log("prePayments = ", this.prePayments);
          this.deliveringKeys =
            lodash.filter(response.data.paymentProcess, (x) => {
              return x.isItKeyPayment;
            })[0] ?? null;
          console.log("deliveringKeys = ", this.deliveringKeys);
          this.isTransferredOrCanceled =
            response.data.isTransfered || response.data.isCancelled;
          this.paidPrice = lodash.sumBy(
            response.data.paymentProcess,
            (item) => {
              return item.isPayed ? item.currentPayPrice : 0;
            }
          );
          this.cancelContract.returnedPrice =
            this.paidPrice *
            (this.projectTenant() !== "DiplomatiSafiran" ? 0.85 : 0);
          this.unpaidPayments = lodash
            .filter(response.data.paymentProcess, (x) => {
              return !x.isPayed;
            })
            .sort(
              (a, b) => new Date(a.currentPayDate) - new Date(b.currentPayDate)
            );
        })
        .catch(() => (this.isLoading = false));
    },
    printContract() {
      window.print();
    },
    async payPrePaymentInstallment(data) {
      let installmentData = this.prePayments[data];
      let sendData = {
        id: installmentData.id,
        accountant: this.$helpers.getAuthUser(),
        buyContractId: installmentData.buyContractId,
        extraPrice: 0,
        extraNote: null,
        invoiceNumber: document.getElementById("preInvoiceNumber_" + data)
          ?.value,
        payedBy: document.getElementById("prePaidBy_" + data)?.value,
        currentForgivePrice: parseFloat(
          document.getElementById("preForgivePrice_" + data)?.value
        ),
        note: document.getElementById("preNote_" + data)?.value,
        payDate: document.getElementById("prePayedDate_" + data)?.value,
        safeboxId: this.selectedSafeBoxId,
        payType: this.isBankPayment ? "BankSlip" : "Cash",
        bankName: document.getElementById("preBankName_" + data)?.value,
        bankSlipNumber: document.getElementById("preBankSlipNumber_" + data)
          ?.value,
        bankSlipDate: document.getElementById("preBankSlipDate_" + data)?.value,
        bankSlipNote: document.getElementById("preBankSlipNote_" + data)?.value,
        attachment: await mixins.changeFileToBase64(
          document.getElementById("preBankSlipAttachment_" + data)?.files[0]
        ),
        isPayed: true,
        isPartialPaid: false,
        partialNote: "",
        partialDate: "",
        partialDeadline: "",
        partialPrice: 0,
      };
      Swal.fire({
        title: this.$t("areYouSure"),
        text:
          this.$t("youWantToMakePayment") +
          " | " +
          installmentData.currentPayPrice.toLocaleString(),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("noCancel"),
        confirmButtonText: this.$t("yes"),
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("paymentProcesses/UPDATE", sendData)
            .then(() => {
              this.isLoading = false;
              Swal.fire({
                icon: "success",
                title: this.$t("paymentSuccessfullyDone"),
                showConfirmButton: false,
                timer: 2500,
              });
              window.location.reload();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    prePrintVoucher(index) {
      let printContents = document.getElementById(
        `voucherPreReceiptDiv_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    async payKeyPaymentInstallment() {
      let installmentData = this.deliveringKeys;
      let sendData = {
        id: installmentData.id,
        accountant: this.$helpers.getAuthUsername(),
        buyContractId: installmentData.buyContractId,
        extraPrice: 0,
        extraNote: null,
        invoiceNumber: document.getElementById("keyInvoiceNumber")?.value,
        payedBy: document.getElementById("keyPaidBy")?.value,
        currentForgivePrice: parseFloat(
          document.getElementById("keyForgivePrice")?.value
        ),
        note: document.getElementById("keyNote")?.value,
        payDate: document.getElementById("keyPayedDate")?.value,
        safeboxId: this.selectedSafeBoxId,
        payType: this.isBankPayment ? "BankSlip" : "Cash",
        bankName: document.getElementById("keyBankName")?.value,
        bankSlipNumber: document.getElementById("keyBankSlipNumber")?.value,
        bankSlipDate: document.getElementById("keyBankSlipDate")?.value,
        bankSlipNote: document.getElementById("keyBankSlipNote")?.value,
        attachment: await mixins.changeFileToBase64(
          document.getElementById("keyBankSlipAttachment")?.files[0]
        ),
        isPayed: true,
        isPartialPaid: false,
        partialNote: "",
        partialDate: "",
        partialDeadline: "",
        partialPrice: 0,
      };
      Swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("youWantToMakePayment"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("noCancel"),
        confirmButtonText: this.$t("yes"),
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("paymentProcesses/UPDATE", sendData)
            .then(() => {
              this.isLoading = false;
              Swal.fire({
                icon: "success",
                title: this.$t("paymentSuccessfullyDone"),
                showConfirmButton: false,
                timer: 2500,
              });
              window.location.reload();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    keyPrintVoucher() {
      let printContents = document.getElementById(
        "voucherKeyReceiptDiv"
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    async payInstallment(data) {
      let index = data - 1;
      let installmentData = this.installments[index];
      let sendData = {
        id: installmentData.id,
        accountant: this.$helpers.getAuthUsername(),
        buyContractId: installmentData.buyContractId,
        invoiceNumber: document.getElementById("invoiceNumber_" + data)?.value,
        extraPrice: document.getElementById("extraPrice_" + data)?.value,
        extraNote: document.getElementById("extraNote_" + data)?.value,
        payedBy: document.getElementById("paidBy_" + data)?.value,
        currentForgivePrice: parseFloat(
          document.getElementById("forgivePrice_" + data)?.value
        ),
        note: document.getElementById("note_" + data)?.value,
        payDate: document.getElementById("payedDate_" + data)?.value,
        safeboxId: this.selectedSafeBoxId,
        payType: this.isBankPayment ? "BankSlip" : "Cash",
        bankName: document.getElementById("bankName_" + data)?.value,
        bankSlipNumber: document.getElementById("bankSlipNumber_" + data)
          ?.value,
        bankSlipDate: document.getElementById("bankSlipDate_" + data)?.value,
        bankSlipNote: document.getElementById("bankSlipNote_" + data)?.value,
        attachment: await mixins.changeFileToBase64(
          document.getElementById("bankSlipAttachment_" + data)?.files[0]
        ),
        isPayed: true,
        isPartialPaid: false,
        partialNote: "",
        partialDate: "",
        partialDeadline: "",
        partialPrice: 0,
      };
      Swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("youWantToMakePayment"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("noCancel"),
        confirmButtonText: this.$t("yes"),
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("paymentProcesses/UPDATE", sendData)
            .then(() => {
              this.isLoading = false;
              Swal.fire({
                icon: "success",
                title: this.$t("paymentSuccessfullyDone"),
                showConfirmButton: false,
                timer: 2500,
              });
              window.location.reload();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    async partialPayInstallment(data) {
      let index = data - 1;
      let installmentData = this.installments[index];
      let sendData = {
        id: installmentData.id,
        accountant: this.$helpers.getAuthUsername(),
        buyContractId: installmentData.buyContractId,
        extraPrice: 0,
        extraNote: "",
        payedBy: document.getElementById("paidBy_" + data)?.value,
        safeboxId: this.selectedSafeBoxId,
        payType: this.isBankPayment ? "BankSlip" : "Cash",
        bankName: document.getElementById("preBankName_" + data)?.value,
        bankSlipNumber: document.getElementById("preBankSlipNumber_" + data)
          ?.value,
        bankSlipDate: document.getElementById("preBankSlipDate_" + data)?.value,
        bankSlipNote: document.getElementById("preBankSlipNote_" + data)?.value,
        attachment: await mixins.changeFileToBase64(
          document.getElementById("preBankSlipAttachment_" + data)?.files[0]
        ),
        isPayed: false,
        isPartialPaid: true,
        partialNote:
          document.getElementById("partialNote_" + data)?.value ?? "",
        partialDate:
          document.getElementById("partialDate_" + data)?.value ?? "",
        partialDeadline:
          document.getElementById("partialDeadline_" + data)?.value ?? "",
        partialPrice:
          document.getElementById("partialPrice_" + data)?.value ?? "",
      };
      Swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("youWantToMakePayment"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("noCancel"),
        confirmButtonText: this.$t("yes"),
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          this.$store
            .dispatch("paymentProcesses/PARTIAL_PAY_INSTALLMENT", sendData)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: this.$t("paymentSuccessfullyDone"),
                showConfirmButton: false,
                timer: 2500,
              });
              window.location.reload();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    printVoucher(index) {
      let printContents = document.getElementById(
        `voucherReceiptDiv_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    addTransferContract() {
      this.$v.transferContract.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.transferContract.oldContractId = this.$route.params.id;
      this.transferContract.oldCustomerId = this.form.customerId;
      this.isLoading = true;
      this.$store
        .dispatch("transferContracts/CREATE", this.transferContract)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "buyContracts" });
        })
        .catch(() => (this.isLoading = false));
    },
    addCancelContract() {
      this.$v.cancelContract.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.cancelContract.buyContractId = this.$route.params.id;
      this.isLoading = true;
      this.$store
        .dispatch("cancelContracts/CREATE", this.cancelContract)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "buyContracts" });
        })
        .catch(() => (this.isLoading = false));
    },
    addAttachmentsToContract() {
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/ADD_CONTRACT_ATTACHMENT", {
          contractId: this.$route.params.id,
          attachment: this.contractAttachment,
        })
        .then(() => {
          swalMixin("success", this.$t("addedSuccessfully"));
          this.isLoading = false;
          this.loadData();
          this.$bvModal.hide("add-attachment-modal");
        })
        .catch(() => (this.isLoading = false));
    },
    deleteContractAttachment(contractId) {
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/DELETE_CONTRACT_ATTACHMENT", contractId)
        .then(() => {
          this.isLoading = false;
          this.loadData();
          this.$bvModal.hide("add-attachment-modal");
        })
        .catch(() => (this.isLoading = false));
    },
    addTransferPayment() {
      // this.isLoading = true;
      // this.$store.dispatch('buyContracts/ADD_CONTRACT_ATTACHMENT', {contractId: this.$route.params.id, attachment: this.contractAttachment}).then(() => {
      //   swalMixin('success', this.$t('addedSuccessfully'))
      //   this.isLoading = false;
      //   this.loadData();
      //   this.$bvModal.hide('add-attachment-modal');
      // }).catch(() => this.isLoading = false);
    },
    showTransferPaymentModal(payment) {
      this.$bvModal.show("transfer-payment-modal-" + payment.id);
      this.paymentToTransfer = payment;
    },
    transferPaymentProcess() {
      this.isLoading = true;
      this.$store
        .dispatch("buyContracts/TRANSFER_PAYMENT_PROCESS", {
          fromPayment: this.paymentToTransfer.id,
          toPayment: this.selectedTransferPayment.id,
          price: this.transferPrice,
        })
        .then(() => {
          swalMixin("success", this.$t("addedSuccessfully"));
          this.isLoading = false;
          this.loadData();
          this.$bvModal.hide(
            "transfer-payment-modal-" + this.paymentToTransfer.id
          );
          this.paymentToTransfer = null;
        })
        .catch(() => (this.isLoading = false));
    },
    unpayPaymentProcess(paymentProcessId) {
      Swal.fire({
        title: this.$t("areYouSure"),
        text: this.$t("youWantToMakeUnpayPayment"),
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("noCancel"),
        confirmButtonText: this.$t("yes"),
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("buyContracts/UNPAY_PAYMENT_PROCESS", paymentProcessId)
            .then(() => {
              swalMixin("success", this.$t("addedSuccessfully"));
              this.isLoading = false;
              this.loadData();
            })
            .catch(() => (this.isLoading = false));
        }
      });
    },
    printArabicDiscountInvoicePre(index) {
      let printContents = document.getElementById(
        `zaitonArabicVoucherDiscountPrintDivPre_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printKurdishDiscountInvoicePre(index) {
      let printContents = document.getElementById(
        `zaitonKurdishVoucherDiscountPrintDivPre_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printArabicPreInvoicePre(index) {
      let printContents = document.getElementById(
        `zaitonArabicVoucherPrePrintDivPre_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printKurdishPreInvoicePre(index) {
      let printContents = document.getElementById(
        `zaitonKurdishVoucherPrePrintDivPre_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },

    printArabicDiscountInvoiceInstallment(index) {
      let printContents = document.getElementById(
        `zaitonArabicVoucherDiscountPrintDivInstallment_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printKurdishDiscountInvoiceInstallment(index) {
      let printContents = document.getElementById(
        `zaitonKurdishVoucherDiscountPrintDivInstallment_${index}`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },

    printArabicDiscountInvoiceDeliveryKey() {
      let printContents = document.getElementById(
        "zaitonArabicVoucherDiscountPrintDivDeliveryKey"
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    printKurdishDiscountInvoiceDeliveryKey() {
      let printContents = document.getElementById(
        `zaitonKurdishVoucherDiscountPrintDivDeliveryKey`
      ).innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },

    getPaymentInvoiceNumber(id) {
      if (document.getElementById(id).disabled) {
        return;
      }
      this.$store
        .dispatch("buyContracts/GET_PAYMENT_INVOICE_NUMBER")
        .then((response) => {
          document.getElementById(id).value = response.data;
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>

<style scoped>
.preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview img {
  max-width: 100%;
  max-height: 500px;
}

.preview span {
  position: absolute;
  top: 0;
  right: 4px;
  z-index: 100;
  color: red;
  cursor: pointer;
}

@media print {
  *:not(button) {
    font-weight: bold;
  }

  .table .input-group input,
  .table .input-group textarea,
  .boldIt .input-group-text {
    font-size: 22px;
  }
}
</style>
