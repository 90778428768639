<template>
  <div v-if="hasPermission(permissions.reportsExpensesByType)">
    <content-header>{{ $t('expensesByType') }}</content-header>
    <v-content>
      <b-row class="mb-4">
        <b-col cols="6">
          <select-input
              label="name"
              id="expenseTypes"
              :name="$t('expenseTypes')"
              api-link="expenseTypes/GET_ALL"
              @itemSelected="expenseTypeSelected"
              :multiple="true"
          />
        </b-col>
        <b-col cols="6">
          <div class="form-group row">
            <label for="totalAmount" class="col-sm-2 col-form-label text-right">{{ $t('total') }}</label>
            <div class="col-sm-10">
              <input
                  id="totalAmount"
                  v-model.trim="totalAmount"
                  disabled
                  :placeholder="$t('total')"
                  type="text"
                  autocomplete="off"
                  class="form-control"
                  @focus="$event.target.select()"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t('fromDate') }}</label>
          <flat-pickr id="fromDate" v-model="fromDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t('toDate') }}</label>
          <flat-pickr id="toDate" v-model="toDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="safeBox">{{ $t('safeBox') }}</label>
          <select id="safeBox" v-model="selectedSafeBoxId" class="form-control">
            <option value="0">{{ $t('all') }}</option>
            <option v-for="safeBox in safeBoxes" :value="safeBox.id" :key="safeBox.id">{{ safeBox.name }}</option>
          </select>
        </b-col>
      </b-row>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="qty" slot-scope="props">
          {{ props.row.qty.toLocaleString() }}
        </template>
        <template slot="mcTotalPrice" slot-scope="props">
          {{ props.row.mcTotalPrice.toLocaleString() }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format('YYYY-MM-DD') }}
        </template>
        <template slot="attachment" slot-scope="props">
          <show-attachment :attachment="props.row.attachment" :title="props.row.attachment" />
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {Expense} from "@/models/Expense";
import IsLoading from "@/components/IsLoading";
import {permissions, serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import ShowAttachment from "@/components/ShowAttachment";
import SelectInput from "@/components/Inputs/SelectInput";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "ExpensesByType",
  components: {SelectInput, ShowAttachment, IsLoading, 'v-content': Content, ContentHeader, BootstrapTable, flatPickr},
  data() {
    return {
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(1),
      isLoading: false,
      model: new Expense(),
      columns: [],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t('expensesByType'), true, false),
      serverParams: serverParams,
      selectedRows: [],
      selectedTypes: [],
      totalAmount: 0,
      selectedSafeBoxId: 1,
      safeBoxes: [],
      permissions: permissions
    };
  },
  watch: {
    fromDate: function () {
      this.resetServerParams()
      this.loadData()
    },
    toDate: function () {
      this.resetServerParams()
      this.loadData()
    },
    selectedSafeBoxId: function () {
      this.resetServerParams();
      this.loadData();
    }
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  mounted() {
    this.loadData()
    this.loadSafeBoxes()
  },
  methods: {
    loadData(typeIds = '') {
      this.isLoading = true;
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate, subLevelId: this.selectedSafeBoxId, enableSubLevel: true, typeIds}
      this.$store.dispatch('expenses/GET_ALL_REPORT', serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count']);
            this.totalAmount  = parseInt(response.headers['mctotal']).toLocaleString();
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start         = (queryParams.page - 1) * queryParams.per_page
      serverParams._end           = queryParams.page * queryParams.per_page
      serverParams._sort          = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order         = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search        = queryParams.global_search ?? ''
      this.loadData()
    },
    expenseTypeSelected(typeIds) {
      this.resetServerParams();
      if (typeIds.length > 0) {
        let ids = ''
        this._.map(typeIds, (e) => {
          ids += '&id=' + e
        })
        this.loadData(ids)
      } else {
        this.loadData()
      }
    },
    loadSafeBoxes() {
      this.$store.dispatch('safeBoxes/GET_ALL', {
        _start: 0,
        _end: 10,
        _sort:'',
        _order:'',
        _search:''
      }).then((response) => {
        this.safeBoxes = response.data
      })
    },
    resetServerParams() {
      serverParams._start = 0
      serverParams._end   = 10
      serverParams._sort  = ''
      serverParams._order = ''
      serverParams._search = ''
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate, subLevelId: this.selectedSafeBoxId, enableSubLevel: true}
    }
  }
}
</script>
