<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>
<style>
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/nunito.ttf) format("truetype");
}

@font-face {
  font-family: "Speda";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/speda.ttf) format("truetype");
}

* {
  font-family: Nunito, Speda, sans-serif;
}

table > thead > tr > th.vbt-column-header {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.only-print {
  display: none !important;
}

@media print {
  .only-print {
    display: block !important;
  }
}

.swal-wide {
  width: 850px !important;
}
</style>
