<template>
  <div v-if="hasPermission(permissions.propertiesList)">
    <content-header>{{ $t("properties") }}</content-header>
    <v-content>
      <b-row class="mb-4">
        <b-col cols="3">
          <div class="form-group row">
            <label
              for="propertySoldStatus"
              class="col-sm-2 col-form-label text-right"
              >{{ $t("status") }}</label
            >
            <div class="col-md-10">
              <b-form-select
                id="propertySoldStatus"
                v-model="propertyStatus"
                :options="propertySoldStatus"
              ></b-form-select>
            </div>
          </div>
        </b-col>
        <b-col v-if="projectTenant() == 'Candle'" cols="9">
          <div class="row">
            <div class="col-3">
              <label
                for="propertySoldStatus"
                class="text-center"
                >{{ $t("totalArea") }}:</label
              >
              <h5 class="mt-2 px-2 d-inline-block">
                {{ totalBuildupArea.toLocaleString() }} m<sup>2</sup>
              </h5>
            </div>
            <div class="col-3">
              <label
                for="propertySoldStatus"
                class="text-center"
                >{{ $t("totalHoldArea") }}:</label
              >
              <h5 class="mt-2 px-2 d-inline-block">
                {{ totalHoldBuildupArea.toLocaleString() }} m<sup>2</sup>
              </h5>
            </div>
            <div class="col-3">
              <label
                for="propertySoldStatus"
                class="text-center"
                >{{ $t("totalSoldArea") }}:</label
              >
              <h5 class="mt-2 px-2 d-inline-block">
                {{ totalSoldBuildupArea.toLocaleString() }} m<sup>2</sup>
              </h5>
            </div>
            <div class="col-3">
              <label
                for="propertySoldStatus"
                class="text-center"
                >{{ $t("totalRemainArea") }}:</label
              >
              <h5 class="mt-2 px-2 d-inline-block">
                {{ totalRemainArea.toLocaleString() }} m<sup>2</sup>
              </h5>
            </div>
          </div>
        </b-col>
      </b-row>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
        @on-create="$router.push({ name: 'properties.create' })"
        @on-multiple-delete="onMultipleDelete"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="isSold" slot-scope="props">
          <span v-if="props.row.isHold" style="color: red; font-weight: bold">{{
            $t("reserved")
          }}</span>
          <span v-else>{{
            props.row.isSold ? $t("sold") : $t("available")
          }}</span>
        </template>
        <template slot="initialPrice" slot-scope="props">
          {{ "$" + props.row.initialPrice.toLocaleString() }}
        </template>
        <template slot="finalPrice" slot-scope="props">
          {{ "$" + props.row.finalPrice.toLocaleString() }}
        </template>
        <template slot="variance" slot-scope="props">
          <span style="color: #4c4cff">
            ${{ calcForProperty(props.row.area, props.row.plotArea) }}
          </span>
        </template>
        <template slot="features" slot-scope="props" class="background-color">
          <span style="color: #4c4cff">
            {{
              props.row.features &&
              props.row.features.length > 0 &&
              props.row.features.find((value) => /Corner \((.*)\)/i.test(value))
                ? "$" +
                  props.row.features
                    .find((value) => /Corner \((.*)\)/i.test(value))
                    .match(/Corner \((.*)\)/i)[1]
                : "$0"
            }}
          </span>
        </template>
        <template slot="features1" slot-scope="props">
          <span style="color: #4c4cff">
            {{
              props.row.features &&
              props.row.features.length > 0 &&
              props.row.features.find((value) =>
                /Road 20M \((.*)\)/i.test(value)
              )
                ? "$" +
                  props.row.features
                    .find((value) => /Road 20M \((.*)\)/i.test(value))
                    .match(/Road 20M \((.*)\)/i)[1]
                : "$0"
            }}
          </span>
        </template>
        <template slot="features2" slot-scope="props">
          <span style="color: #4c4cff">
            {{
              props.row.features &&
              props.row.features.length > 0 &&
              props.row.features.find((value) =>
                /Facing Park \((.*)\)/i.test(value)
              )
                ? "$" +
                  props.row.features
                    .find((value) => /Facing Park \((.*)\)/i.test(value))
                    .match(/Facing Park \((.*)\)/i)[1]
                : "$0"
            }}
          </span>
        </template>
        <template
          slot="controls"
          slot-scope="props"
          v-if="
            hasPermission(permissions.propertiesEdit) ||
            hasPermission(permissions.propertiesShow) ||
            hasPermission(permissions.propertiesDelete)
          "
        >
          <control-buttons
            :props="props"
            :edit-link="
              (hasPermission(permissions.propertiesEdit) ||
                hasPermission(permissions.propertiesShow)) &&
              'properties.edit'
            "
            :delete-link="
              hasPermission(permissions.propertiesDelete) && 'properties/DELETE'
            "
            v-on:successAction="loadData(true)"
          />
          <a
            v-if="props.row.propertyTypeAttachment"
            :href="getImageUrl(props.row.propertyTypeAttachment)"
            target="_blank"
            class="btn btn-outline-info m-1"
          >
            <i class="fal fa-map"></i>
          </a>
          <button
            v-if="projectTenant() === 'MassHills'"
            class="btn btn-outline-info m-1"
            @click="showInfoModal(props.row.id)"
          >
            <i class="fal fa-info"></i>
          </button>
        </template>
      </BootstrapTable>
      <b-modal :id="'property-info-modal'" :title="$t('property')" size="xl">
        <h4>
          {{ $t("propertyName") }}: {{ propertyInfo && propertyInfo.name }}
        </h4>
        <div class="d-block">
          <p class="lead">
            {{ $t("initialPrice") }}:
            <span style="font-weight: bold"
              >${{
                propertyInfo && propertyInfo.initialPrice.toLocaleString()
              }}</span
            >
          </p>
          <p class="lead">
            {{ $t("finalPrice") }}:
            <span style="font-weight: bold"
              >${{
                propertyInfo && propertyInfo.finalPrice.toLocaleString()
              }}</span
            >
          </p>
          <p class="lead">
            {{ $t("area") }}:
            <span style="font-weight: bold">{{
              propertyInfo && propertyInfo.area.toLocaleString()
            }}</span>
          </p>
          <p class="lead">
            {{ $t("additionalPlotArea") }}:
            <span style="font-weight: bold">{{
              propertyInfo && propertyInfo.plotArea.toLocaleString()
            }}</span>
          </p>
          <hr />
          <div v-if="propertyInfo" class="row">
            <div class="col-6">
              <h5>{{ $t("features") }}</h5>
              <p
                class="lead"
                v-for="propertyFeature in propertyInfo.propertyFeatures"
                :key="propertyFeature.id"
              >
                {{ propertyFeature.feature.name }}:
                <span style="font-weight: bold"
                  >${{
                    propertyFeature.feature.extraPrice.toLocaleString()
                  }}</span
                >
              </p>
            </div>
            <div class="col-6">
              <h5>{{ $t("payments") }}</h5>
              <table class="table table-striped table-bordered">
                <tr>
                  <th>{{ $t("prepayment") }} 1 (20%)</th>
                  <td>
                    ${{ (propertyInfo.finalPrice * 0.2).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("prepayment") }} 2 (15%)</th>
                  <td>
                    ${{ (propertyInfo.finalPrice * 0.15).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("prepayment") }} 3 (15%)</th>
                  <td>
                    ${{ (propertyInfo.finalPrice * 0.15).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("deliveringKeys") }} (25%)</th>
                  <td>
                    ${{ (propertyInfo.finalPrice * 0.25).toLocaleString() }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("installments") }} (25%)</th>
                  <td>
                    ${{ (propertyInfo.finalPrice * 0.25).toLocaleString() }} -
                    (${{
                      ((propertyInfo.finalPrice * 0.25) / 25).toLocaleString()
                    }}
                    {{ $t("perMonth") }})
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()">{{
            $t("close")
          }}</b-button>
        </template>
      </b-modal>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { Properties } from "@/models/Properties";
import IsLoading from "@/components/IsLoading";
import ControlButtons from "@/components/ControlButtons";
import {
  actions,
  serverParams,
  swalMixin,
  tableActions,
  tableConfigure,
  permissions,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "PropertiesList",
  components: {
    ControlButtons,
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
  },
  data() {
    return {
      isLoading: false,
      model: new Properties(),
      columns: [],
      rows: [],
      actions: tableActions(this.hasPermission(permissions.propertiesCreate)),
      totalRecords: 0,
      config: tableConfigure(this.$t("propertiesList")),
      serverParams: serverParams,
      selectedRows: [],
      varianceStored: localStorage.getItem("variance") ?? 10,
      buildUpAreaStored: localStorage.getItem("buildUpArea") ?? 300,
      permissions: permissions,
      propertyStatus: null,
      propertySoldStatus: [
        {
          value: null,
          text: this.$t("all"),
        },
        {
          value: "false",
          text: this.$t("notSold"),
        },
        {
          value: "true",
          text: this.$t("sold"),
        },
      ],
      propertyInfo: null,
      totalBuildupArea: "",
      totalHoldBuildupArea: "",
      totalSoldBuildupArea: "",
      totalRemainArea: "",
    };
  },
  watch: {
    propertyStatus: function () {
      this.serverParams.custom_params = {
        isSold: this.propertyStatus,
      };
      this.loadData(true);
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("properties/GET_ALL", this.serverParams)
        .then((response) => {
          this.rows = response.data;
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.totalBuildupArea = parseInt(response.headers["totalbuilduparea"]);
          this.totalHoldBuildupArea = parseInt(
            response.headers["totalholdbuilduparea"]
          );
          this.totalSoldBuildupArea = parseInt(
            response.headers["totalsoldbuilduparea"]
          );
          this.totalRemainArea  = parseFloat(this.totalBuildupArea) - parseFloat(this.totalHoldBuildupArea) - parseFloat(this.totalSoldBuildupArea);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      this.loadData();
    },
    async onMultipleDelete() {
      if (this.selectedRows.length === 0) {
        swalMixin("error", "noRows");
        return false;
      }
      if (await actions("properties/DELETE", this.selectedRows, "delete")) {
        this.loadData();
      }
    },
    showInfoModal(propertyId) {
      this.$bvModal.show("property-info-modal");
      this.$store
        .dispatch("properties/GET_ONE", propertyId)
        .then((response) => {
          this.propertyInfo = response.data;
        });
    },
    calcForProperty(area, plotArea) {
      let variance = parseFloat(area) - parseFloat(plotArea);

      if (variance >= this.varianceStored || variance === 0) {
        return variance > this.varianceStored
          ? parseFloat(
              this.buildUpAreaStored * (variance - this.varianceStored)
            ).toLocaleString()
          : 0;
      } else if (variance < 0) {
        return parseFloat(variance * this.buildUpAreaStored).toLocaleString();
      } else {
        return 0;
      }
    },
  },
};
</script>
