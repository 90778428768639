import { i18n } from "@/plugins/i18n";

export class BuyContractReport {
  columns = [];
  id = "id";

  fillAbles = [
    ["contractNumber", i18n.t("contractNumber"), true],
    ["propertyName", i18n.t("propertyName"), true],
    ["customer", i18n.t("customer"), true],
    ["buyingPrice", i18n.t("buyingPrice"), true],
    ["paidPrice", i18n.t("paidPrice"), true],
    ["remainPrice", i18n.t("remainPrice"), true],
    ["floor", i18n.t("floor"), true, false],
    ["zone", i18n.t("zone"), true],
    ["block", i18n.t("block"), true],
    ["date", i18n.t("date"), true],
  ];

  getColumns() {
    this.columns = [
      {
        label: i18n.t("id"),
        name: "id",
        sort: false,
        visibility:
          process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true,
        uniqueId: true,
      },
    ];
    this.fillAbles.map((e) => {
      this.columns.push({
        name: e[0],
        label: e[1],
        sort: e[2],
        visibility: e[3] ?? true,
      });
    });
    return this.columns;
  }
}
