<template>
  <div id="voucher-receipt" class="voucher-receipt" dir="rtl">
    <div id="cloneable">
      <b-row
        class="text-center"
        style="margin: 0; font-size: 28px; border-bottom: 3px solid #b58151"
      >
        <b-col cols="4">
          <h2 class="text-left text-bold pt-4">پڕۆژەی کاندڵ ریزدینس</h2>
          <h2 class="text-left text-bold">مشروع کاندل السکنی</h2>
        </b-col>
        <b-col cols="3" class="text-right">
          <b-img
            :src="systemImage"
            fluid
            rounded="circle"
            alt="Responsive image"
            style="width: 65%"
          ></b-img>
        </b-col>
        <b-col cols="5" class="p-2 pt-1 text-right" style="text-align: end">
          <h5 class="text-bold">
            law@investincandle.com
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            sales@investincandle.com
            <i class="fal fa-envelope" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            07504211100 - 07504211122
            <i class="fal fa-phone fa-rotate-270" style="color: #9e7f49"></i>
          </h5>
          <h5 class="text-bold">
            Iraq - Erbil - Gulan Str, A.B. Center, Floor 2
            <i class="fal fa-map-marker" style="color: #9e7f49"></i>
          </h5>
        </b-col>
      </b-row>
      <b-row class="m-2" style="margin: 0; font-size: 20px">
        <b-col class="text-left" style="font-size: 25px" cols="4">
          <h5>
            <span class="text-bold">ژمارەی وەصڵ:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >{{ voucherNo && voucherNo }}</span
            >
          </h5>
          <h5>
            <span class="text-bold">کـۆی گـشتی:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >${{
                (
                  parseFloat(totalPaidPrice) + parseFloat(remain)
                ).toLocaleString()
              }}</span
            >
          </h5>
          <h5>
            <span class="text-bold">پــارەی مـــاوە:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >${{ remain.toLocaleString() }}</span
            >
          </h5>
          <h5>
            <span class="text-bold">رێــژەی مـــاوە:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >%{{
                (
                  (parseFloat(remain) /
                    (parseFloat(totalPaidPrice) + parseFloat(remain))) *
                  100
                ).toFixed(2)
              }}</span
            >
          </h5>
        </b-col>
        <b-col class="text-center" cols="4" style="font-size: 25px">
          <strong>پسوولەی پارە وەرگرتن</strong>
          <p class="lead">
            <!-- {{ formatDate(datetime, "YYYY-MM-DD - h:m A") }} -->
            {{ formatDate(voucherDate, "YYYY-MM-DD - h:m A") }}
            <br />
            {{ isCash ? "کاش" : "بانک" }}
          </p>
        </b-col>
        <b-col class="text-center" style="font-size: 25px" cols="4">
          <p class="h5">
            <span class="text-bold">تاوەر:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >{{ property && property.zone }}</span
            >
          </p>
          <p class="h5">
            <span class="text-bold">نهۆم:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >{{ property && property.block }}</span
            >
          </p>
          <p class="h5">
            <span class="text-bold">شوقە:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
              "
              >{{ property && property.name }}</span
            >
          </p>
          <p class="h5">
            <span class="text-bold">ڕووبەر:&nbsp;</span>
            <span
              style="
                display: inline-block;
                width: 200px;
                border-bottom: 5px dotted #9e7f49;
                padding: 0 20px;
                direction: ltr !important;
              "
              >{{
                property &&
                `${property.propertyType.name} ${property.buildUpArea}`
              }}m<sup>2</sup></span
            >
          </p>
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> بڕی پارە (المبلغ) </b-col>
        <b-col class="text-center" cols="4">
          <strong>${{ amount && numberFormat(amount) }}</strong>
          <h4 style="border-bottom: 2px solid #9e7f49; padding-bottom: 15px">
            {{ amountText }}
          </h4>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4">
          Amount
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px">
        <b-col class="text-right" cols="4"> بە مەبەستی (و ذلك عن) </b-col>
        <b-col class="text-center" cols="4">
          <strong>{{ note && note }}</strong>
        </b-col>
        <b-col style="direction: ltr" class="text-left" cols="4">
          Purpose of receipt
        </b-col>
      </b-row>
      <br />
      <b-row style="margin: 0; font-size: 25px" class="text-center mt-5">
        <b-col cols="6">
          <strong>واژووی وەرگر</strong>
          <br />
          {{ accountantName && accountantName }}
        </b-col>
        <b-col cols="6">
          <strong>واژووی پێدەر</strong>
          <br />
          {{ paidName && paidName }}
        </b-col>
      </b-row>
    </div>
    <hr style="border: 2px solid #b58151; height: 0; margin: 40px 0" />
  </div>
</template>

<script>
// import mixins from "@/mixins";

export default {
  name: "VoucherReceiptCandle",
  props: {
    voucherNo: {
      type: String,
    },
    voucherDate: {
      type: String,
    },
    paidName: {
      type: String,
    },
    amount: {
      type: String,
    },
    amountText: {},
    purposeOfVoucher: {
      type: String,
    },
    note: {
      type: String,
    },
    accountantName: {
      type: String,
      default: "ناوی ژمێریار",
    },
    property: {
      type: Object,
    },
    isCash: {
      type: Boolean,
      default: true,
    },
    remain: {},
    totalPaidPrice: {},
  },
  // mounted() {
  //   mixins.getCurrentDateTimeServer().then((response) => {
  //     this.datetime = response.datetime;
  //   });
  // },
  data() {
    return {
      systemImage: this.$helpers.projectTenantLogo(),
      // datetime: null,
    };
  },
};
</script>
