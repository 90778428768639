<template>
  <table class="table table-bordered table-striped">
    <thead>
    <tr>
      <th>{{ $t('name') }}</th>
      <th>{{ $t('holdPayment') }}</th>
      <th>{{ $t('date') }}</th>
      <th>{{ $t('deadline') }}</th>
      <th>{{ $t('note') }}</th>
      <th>{{ $t('attachment') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="hold in allHolds" :key="hold.id">
      <td>{{ hold.name }}</td>
      <td>{{ hold.holdPayment.toLocaleString() }}</td>
      <td>{{ formatDate(hold.date, 'YYYY-MM-DD') }}</td>
      <td>{{ formatDate(hold.deadline, 'YYYY-MM-DD') }}</td>
      <td>{{ hold.note ? hold.note : '-' }}</td>
      <td>
        <show-attachment :attachment="hold.attachment" :title="hold.name" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>

import ShowAttachment from "@/components/ShowAttachment";
export default {
  name: "PropertyHoldsList",
  components: {ShowAttachment},
  props: {
    holds: {
      type: Array
    }
  },
  watch: {
    holds: function (value) {
      this.allHolds = value
    }
  },
  data() {
    return {
      allHolds: this.holds,
      url: process.env.VUE_APP_BASE_URL,
      isLoading: false
    }
  },
}
</script>
