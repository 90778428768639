export default {
  // Start Generals
  kurdish: "کوردی",
  arabic: "عربی",
  english: "English",
  language: "زمان",
  controls: "دەستکاری",
  dashboard: "داشبۆرد",
  kurdishName: "ناوی کوردی",
  arabicName: "ناوی عەرەبی",
  englishName: "ناوی ئینگلیزی",
  isoCode: "کۆدا نێودەوڵەتی",
  postalCode: "پۆستال كۆد",
  city: "شار",
  noResultsFound: "هیچ ئەنجامەک نەهاتيە ديتن",
  totalRecords: "کۆیا تۆمارا",
  save: "پاشەکەڤت بکە",
  yes: "بەلێ",
  no: "نەخێر",
  delete: "ژێبرن",
  someThingWentWrong: "هەڵەیەک ڕوویدا",
  settings: "ڕێکخستن",
  listIsEmpty: "لیستێ بەتاڵەكە",
  // End Generals

  // Start Custom Components
  isRequired: "هاتيە داواكرن ",
  remove: "ژێبرن",
  fileIsTooBig: "قەبارا فایلا زۆرە",
  typeNotSupported: "جۆرەكە نەهاتيە پشتگیری كرن",
  maxFileUploadReach: "فایل يێت زۆرن",
  pleaseSelect: "تکایە هەلبژێرە",
  mustHaveAtLeast: "دبيت لانی کەم هەبيت",
  letters: "پیت",
  mustNotHaveGreaterThen: "نابيت مەزنتر بيت",
  attachments: "هاوپێچ",
  close: "قەپاتكرن",
  search: "گەڕان",
  multipleDelete: "فرە ژێبرن",
  multipleRestore: "فرە ڤەزڤڕين",
  multipleForceDelete: "فرە ژێبرنا هەميشەىىي",
  export: "ناردن",
  noRowsSelected: "هیچ ڕیزەك نەهاتيە دەستنیشان كرن",
  youWantToDeletePermanentlySelectedRows:
    "تە دڤێت ڕیزێن دیاریکراوە  بۆ هەمیشەیی بهێن ژێبرن",
  youWantToRestorePermanentlySelectedRows:
    "تە دڤێت ڕیزێن دیاریکراوە  بۆ هەمیشەیی بهێن ڤەزڤڕين",
  yesDelete: "بەڵێ، ژێببە",
  yesRestore: "بەڵێ، ڤەزڤڕينە",
  yourDataHasBeenDeletedPermanently: "داتا يێن هاتين ژێ برن ",
  yourDataHasBeenRestoredPermanently: "داتا يێن هاتين ڤەزڤڕين",
  // End Custom Components

  // Start Routes
  editProperties: "دەستکاریێن موڵکا",
  createCustomer: "دروستکردنا کڕیارا",
  editCustomer: "گوهڕينا کڕیارا",
  createExpenseAttachment: "دروستکرنا هاوپێچا خەرجیا",
  createPropertyFollowUp: "دروستکردنا شوێنپێدانا موڵکا",
  editLeadSubSource: "بژارکرنا سەرچاڤێن لاوەکیێن سەرەکی",
  buyContractCreate: "دروستکردنا گرێبەستێن کڕینێ",
  showBuyContracts: "نیشاندانا گرێبەستا کڕینا",
  voucherReceipt: "وەصڵ",
  holds: "هاتيە ڕاگرتن",
  // End Routes

  // Start 404
  pageNotFound: "پەیج نەهاتيە ڤەديتن",
  oopsThePageYouWereLookingForDoesntExist:
    "ببورە ئەو پەیجێ ژ ديڤ دگەڕێى نەهاتيە ڤەديتن ",
  backToDashboard: "ڤەزڤڕين بۆ داشبۆرد",
  notFound: "نەهاتيە ڤەديتن",
  // End 404

  // Start Login
  username: "ناڤێ بکارهێنەر",
  password: "ووشا نهێنی",
  login: "چوونە ژوور",
  // End Login

  // Start Sidebar
  customers: "کڕیار",
  buyers: "کڕیار",
  list: "لیست",
  create: "زێدەکرن",
  additionalPlotArea: "ڕووبەرێ زێدە",
  createAndNew: "زێدەكرن و نوێ",
  properties: "موڵک",
  propertyHolds: "ئەڤ موڵکە هاتيە ڕاگیران",
  propertyTypes: "جۆرێن موڵک",
  villaTypes: "جۆرێن موڵکەکان",
  propertyExpenseTypes: "جۆرێن خەرجیێن موڵک",
  features: "تایبەتمەندی",
  buyContracts: "گرێبەستیێن کڕین",
  transferContracts: "گڕێبەستێن ڤەگواستى",
  cancelContracts: "گرێبەستێن هەڵوەشێندرايى",
  isCash: "بـ کاش؟",
  leadSources: "سەرچاوێن سەرەکی",
  leadSubSources: "سەرچاوێن لاوەکی",
  leadCustomers: "سەرچاوێن کڕیارا",
  leadVisits: "سەرچاوێن سەردانا",
  expenses: "خەرجی",
  expenseTypes: "جۆرێن خەرجی",
  reports: "ڕاپۆرت",
  leadReports: "راپۆرتێن سەرچاوا",
  transactions: "مامەڵەکان",
  logout: "چوونە ژوور",
  initials: "دەستپێك",
  contracts: "گرێبەست",
  leads: "سەرچاوە",
  createLeadVisits: "زێدەكرنا سەرچاوێن سەردانا",
  createLeadCustomers: "زێدەكرنا سەرچاوێن کڕیارا",
  accounting: "ژمێریاری",
  // End Sidebar

  // Start Main Dashboard
  mainDashboard: "داشبۆردا سەرەکی",
  projectDashboard: "داشبۆردا پڕۆژەيان",
  totalProperties: "کۆیا موڵکا",
  totalExpenses: "کۆیا خەرجیا",
  remainingPayableAmount: "پاشماوێن بڕێن پارە پێدان",
  // End Main Dashboard

  // Start Dashboard
  todayVisits: "سەردانيكرنێن ئەڤڕو",
  totalVisits: "کۆیا سەردانيكرنا",
  todayContracts: "گرێبەستێن ئەڤڕۆ",
  totalContracts: "کۆیا گرێبەستان",
  todaySummary: "پوختەیا ئەڤڕۆ",
  generalData: "داتایا گشتی",
  invoiceNumber: "ژمارا پسوولان",
  referenceNumber: "ژمارا پسوولان",
  withdraw: "ڕاکێشان",
  reason: "هۆکار",
  expiredPropertyHolds: "موڵكێ ڕاگیڕايى بسەرچووە",
  fromDate: "ژ بەرواری",
  toDate: "بو بەرواری",
  upcomingPayments: "پارەدانێن داهاتيى",
  upcomingPaymentsList: "لیستا پارەدانێن داهاتيى",
  payments: "پارەدان",
  next10Days: "10 ڕۆژا داهاتوو",
  next20Days: "20 ڕۆژا داهاتوو",
  delayed: "دواکەوت",
  total: "کۆیا گشتی",
  remain: "ماوە",
  sold: "هاتيە فرۆشتن",
  hold: "هاتيە ڕاگرتن",
  back: "ڤەزڤڕين",
  soldProperties: "فرۆشراو",
  remainedProperties: "ماوەکان",
  onHoldProperties: "ئەوێن هاتين ڕاگرتن",
  reset: "دووبارە ڕێک بخڤە",
  cancelledContracts: "گرێبەستێن هەڵوەشێنرايى",
  collectedMoney: "پارێن هاتين کومكرن",
  currentContractCount: "ژمارەیا گرێبەستا نوكە",
  propertyExpenses: "خەرجیێن موڵکا",
  remainPrices: "نرخێن مايى",
  returnedPrices: "نرخێ ڤەزڤڕينا",
  safeBox: "سندوق",
  remainToCollect: "مايە بۆ کۆمكرنێ",
  price: "بها",
  holdInfo: "زانیاریێن ڕاگیرايى",
  // End Dashboard

  // Start Customers
  customersList: "لیستیا کڕیارا",
  firstName: "ناڤێ یێکەم",
  middleName: "ناڤێ ناوەڕاستێ",
  lastName: "ناڤێ دوماهيكێ",
  gender: "ڕەگەز",
  birthdate: "ژ دایکبوون",
  address: "ناڤونیشان",
  email: "ئیمەیڵ",
  phone: "تەلەفۆن",
  phone2: "تەلەفۆن 2",
  nationality: "نەتەوە",
  idCard: "کارتا ناسنامە",
  foodCard: "کارتا خۆراک",
  informationCard: "کارتا زانیاری",
  residencyCard: "کارتا نشینگە",
  placementCard: "کارتا دانانی",
  securitySupport: "پشتگیریا ئاسایش",
  note: "تێبینی",
  job: "کار",
  jnsia: "جنسیە",
  fullName: "ناڤێ تەمامەتى",
  male: "نێر",
  hasAccount: "ئەژمارا هەی",
  edit: "بژارکردن",
  attachment: "هاوپێچ",
  createCustomerAttachment: "دروستکردنا هاوپێچا کڕیارا",
  sharable: "هاوبەشکراو",
  is360: "ئایا 360",
  title: "ناڤونیشان",
  shareableForCustomer: "هاوبەشکراو بۆ کڕیارا",
  share: "هاوبەشکرن",
  deletedSuccessfully: "بـ سەرکەڤتى هاتيە ژێ برن",
  // End Customers

  // Start Properties
  createProperties: "زێدەكرنا موڵک",
  villaNumber: "ژمارەیا ڤیلا",
  propertyNumber: "ژمارەیا موڵکا",
  villaType: "جۆرێن ڤیلا",
  name: "ناڤ",
  floor: "نهۆم",
  priceMeter: "نرخێ یەک مەتر",
  additionalInfo: "زانیاریا زیاتر",
  zone: "ناوچە",
  tower: "تاوەر",
  extraProperty1: "ڕووکار",
  extraProperty2: "ڕوو",
  apartmentType: "جۆرێ شوقە",
  apartment: "شوقە",
  apartmentPrice: "نرخێ شوقە",
  block: "بلۆک",
  streetNumber: "ژمارەیا شەقام",
  streetWidth: "پانیا شەقاما",
  rooms: "ژوور",
  floors: "نهۆم",
  realEstateAgent: "بریکارێ خانووبەرە",
  propertyType: "جۆرێ موڵک",
  plotArea: "ڕووبەرێ نەخشە",
  buildUpArea: "ڕووبەرێ چێكرنێ",
  meterPrice: "نرخێ مەتر",
  amount: "بڕ",
  area: "ڕووبەر",
  variance: "جیاوازیا ڕووبەرى",
  extraCost: "تێچوویا زێدە",
  initialPrice: "بهايێ دەست پێكێ",
  feature: "تایبەتمەندی",
  finalPrice: "بهايێ دوماهيكێ",
  numberOfRooms: "ژمارەیا ژووران",
  followUp: "ديڤ کەتن",
  createPropertyAttachment: "دروستکردنا هاوپێچا موڵکى",
  date: "بەروار",
  deadline: "دوا وادە",
  done: "هاتيە كرن",
  propertiesList: "لیستا موڵکان",
  // End Properties

  // Start Property Hold
  propertyName: "ناڤی موڵکا",
  holdPayment: "پارەیێن هەڵگرتى",
  propertyHoldsList: "لیستا موڵکێن هەڵواسراو",
  createPropertyHold: "چێكرنا موڵکێن هەڵواسراو",
  showPropertyHold: "نیشاندانا موڵکێن هەڵواسراو",
  property: "موڵک",
  transferred: "هاتيە ڤەگواستن",
  cancelled: "هەڵوەشایە",
  isCustomer: "ئایا کڕیارە؟",
  payType: "جۆریێن پارەدانا",
  bankName: "ناڤی بانک",
  bankSlipNumber: "ژمارەیا وەصڵا بانکێ",
  bankSlipDate: "بەروارا وەصڵا بانکێ",
  bankSlipNote: "تێبینیا وەصڵیێن بانکێ",
  isBankPayment: "ئایا پارەدانا بانکێيە؟",
  bankSlipAttachment: "هاوپێچا وەصڵا بانکێ",
  // End Property Hold

  // Start Property Type
  propertyTypesList: "لیستا جۆرێن موڵکى",
  createPropertyType: "چێكرنا جۆرێن موڵکى",
  editPropertyType: "گۆڕینا جۆرێن موڵکى",
  // End Property Type

  // Start Feature
  extraPrice: "بهايێ زێدە",
  featuresList: "لیستا تایبەتمەندیيا",
  createFeature: "چێكرنا تایبەتمەندیا",
  editFeature: "دەستكاريا تایبەتمەندیا",
  // End Feature

  // Start Buy Contract
  contractNumber: "ژمارەیا گرێبەستا",
  customerName: "ناڤێ کڕیار",
  buyingDate: "بەروارێ کڕین",
  buyingPrice: "بهايێ کڕین",
  buyContractsList: "لیستا گرێبەستێن کڕینێ",
  createBuyContract: "چێكرنا گرێبەستێن کڕینێ",
  street: "شەقام",
  propertyPrice: "بهايێ موڵک",
  installments: "قیست",
  noOfInstallments: "ژمارەيێن قیستا",
  periodOfInstallments: "ماوەیا قیستان",
  installment: "قیست",
  percentage: "ڕێژەیا سەدی",
  percentageOfInstallments: "ڕێژەیا سەدی يا قیستا",
  prepayment: "پارەدانا پێشوەخت",
  percent: "ڕێژە",
  paidPrice: "پارێن هاتين دان ",
  deliveringKeys: "وەرگرتنا کلیل",
  discount: "داشکاندن",
  showDiscount: "نیشاندانی داشکان",
  pleaseCheckInstallmentsTheSumOfInstallmentsIsNotEqualToInitialPropertyPrice:
    "تکایە پێداچوونەڤێ بوو قستا بكە چونکى یەکسان نیە دگەل بهايێ دەستپێكا موڵک",
  showContract: "نیشاندانی گرێبەست",
  print: "چاپکردن",
  withExpense: "خەرجی دگەل دايە",
  printKurdishContract: "چاپکردنا گرێبەستێن کڕینا کوردی",
  printArabicContract: "چاپکردنا گرێبەستێن کڕینا عەرەبی",
  isPaid: "پارە هاتيىە دان",
  action: "کردار",
  paidBy: "هاتيە دان ژ لايەنێ",
  pay: "پارەدان",
  partialPaymentPrice: "بهايێ بەشەکێ پارە",
  partialPaymentNote: "تێبینی بەشەکێ پارە",
  partialPaymentDate: "بەروارێ بەشەکێ پارە",
  partialPaymentDeadline: "دوماهيك بەروارا بەشەكێ پارە",
  extraNote: "تێبینیا زیاتر",
  partialPay: "پارەدانا بەشەکى",
  areYouSure: "ئایا دڵنیای؟",
  youWantToMakePayment: "تەدڤێت پارەدانێ بکەی",
  noCancel: "نەخێر",
  paymentSuccessfullyDone: "پارەدان بە سەرکەڤتيى هاتە ئەنجام دان",
  payment: "پارەدان",
  printPayments: "چاپکرنا پارەدانا",
  transferContract: "ڤەگواستنا گرێبەستان",
  customer: "کڕیار",
  cancelContract: "هەڵوەشاندنا گرێبەستا",
  addAttachments: "زیادکرنا هاوپێچێ",
  // End Buy Contract

  // Start Lead Sources
  leadSourcesList: "لیستا سەرچاوەيان",
  createLeadSource: "چێكرنا سەرچاوەيا",
  editLeadSource: "دەستکاری کرنا سەرچاوەيان",
  // End Lead Sources

  // Start Lead Sub Sources
  leadSourceName: "ناڤێ سەرچاوە",
  leadSubSourcesList: "لیستا سەرچاوەيا لاوەکی",
  createLeadSubSource: "چێكرنا سەرچاوەیا لاوەکی",
  leadSource: "سەرچاوە",
  editSubLeadSource: "دەستکاریا سەرچاوەیا لاوەکی",
  // End Lead Sub Sources

  // Start Lead Customers
  isMale: "نێر؟",
  leadSubSourceName: "ناڤێ سەرچاوەیا لاوەکی",
  state: "بارودۆخ",
  males: "نێرەکان",
  females: "مێیەکان",
  visitNote: "تێبینیا سەردانی كرنێ",
  leadCustomersList: "لیستا کڕیارێن پێشبینیکرى",
  createLeadCustomer: "چێكرنا کڕیارێن پێشبینیکرى",
  female: "مێ",
  leadSubSource: "سەرچاوەیا لاوەکیا پێشبینیکرى",
  customerNote: "تێبینیێن کڕیارى",
  editLeadCustomer: "دەستکاری كرنا کڕیاریێن چاوەڕوانکراو",
  // End Lead Customers

  // Start Lead Visits
  leadCustomer: "کڕیارێن پێشبینیکريى",
  leadCustomerPhone: "ژمارەيێن مۆبایلێن کڕیارێ پێشبینیکرى",
  newState: "بارودۆخێ نوو",
  oldState: "بارودۆخی كەڤن",
  visitDate: "بەروارێ سەردانيكرنێ",
  leadVisitsList: "لیستا سەردانیكرنا پێشبینیکريى",
  createLeadVisit: "چێكرنا سەردانیكرنا پێشبینیکرى",
  // End Lead Visits

  // Start Expenses
  expenseType: "جۆرێن خەرجیێ",
  mcPrice: "بها",
  qty: "بڕ",
  mcTotalPrice: "کۆیا بهايى",
  expenseList: "لیستا خەرجیا",
  createExpense: "چێكرنا خەرجیا",
  editExpense: "دەستکاریا خەرجیا",
  // End Expenses

  // Start Expense Types
  expenseTypeList: "لیستا جۆرێن خەرجیا",
  createExpenseType: "چێكرنا جۆرن خەرجیا",
  description: "وەسف",
  editExpenseType: "دەستکاریا جۆرێن خەرجیا",
  // End Expense Types

  // Start Lead Reports
  selectDate: "بەروارى هەڵبژێرە",
  leadVisitByDayOfWeek: "سەردانیا پێشبینیکرى بـ پێیێ ڕۆژا هەفتييێ",
  dailyLeadVisits: "سەردانیا پێشبینیکريا ڕۆژانە",
  perSources: "بۆ هەر سەرچاوەکيى",
  women: "مێ",
  men: "نێر",
  day: "ڕۆژ",
  noOfGroups: "ژمارەیا گروپان",
  visitsByDate: "سەردانی بەگوێرا بەروارى",
  id: "ناسنامە",
  type: "جۆر",
  detail: "وردەکاری",
  // End Expense Types

  // Start Transfer Contracts
  transferContractList: "لیستا گرێبەستێن ڤەگواستى",
  fromCustomer: "ژ کڕیار",
  toCustomer: "بو کڕیار",
  oldContract: "گرێبەستا کەڤن",
  newContract: "گرێبەستا نوێ",
  showTransferContract: "نيشاندانا گرێبەستێن ڤەگواستى",
  // End Transfer Contracts

  // Start Cancel Contracts
  cancelContractList: "لیستا گرێبەستێن هەڵوەشايى",
  returnedPrice: "بهايێ زڤريى",
  remainPrice: "بهايێ مايى",
  buyContract: "گرێبەستا کڕینا",
  showCancelContract: "نیشاندانا گرێبەستێن هەڵوەشايى",
  // End Cancel Contracts

  // Start Property Expense Types
  propertyExpenseTypesList: "لیستا جۆرێن خەرجیێن موڵک",
  createPropertyExpenseType: "زێدەكرنا جۆرێ خەرجیێن موڵک",
  editPropertyExpenseType: "دەستکاریکرنا جۆرێ خەرجیێن موڵک",
  propertyExpenseType: "جۆرێن خەرجیێن موڵک",
  // End Property Expense Types

  // Start Property Report
  propertyReport: "ڕاپۆرتێن موڵکان",
  // End Property Report

  // Start Expense By Type
  expensesByType: "خەرجى بـ گوێرێ جۆر",
  // End Expense By Type

  // Start Settings
  propertySettings: "ڕێکخستنێن موڵک",
  contractSettings: "ڕێکخستنێن گرێبەست",
  contractTopSpaces: "بۆشایيا سەرەوەيا گرێبەست",
  // End Settings

  // Start Projects
  logo: "لۆگۆ",
  projectsList: "لیستا پڕۆژان",
  editProject: "دەستکاریکرنا پرۆژەيان",
  // End Projects

  // Start Users
  users: "بەکارهێنەر",
  usersList: "لیستیا بەکارهێنەران",
  phoneNumber: "ژمارا مۆبایل",
  showUser: "نيشاندانا بەکارهێنەر",
  project: "پڕۆژە",
  projects: "پڕۆژە",
  addProjectToUser: "زێدەكرنا پڕۆژە بو بکارهێنەرى",
  addProjectToCustomer: "زیادکردنا پڕۆژە بو کڕیار",
  addToProject: "زێدەكرن بۆ پڕۆژە",
  // End Users

  // Start Permissions
  permissions: "مۆڵەت",
  permissionsList: "لیستا مۆڵەتان",
  editPermission: "بژارکرنا مۆڵانەت",
  permissionsSaved: "ڕێپێدانێن هەڵگرتيا",
  errorInSavingPermissions: "هەڵە ژ پاشکەڤتکرنا مۆڵەتان",
  canPayContractPayment: "دێشێت پارەدانێن گرێبەستى بدەت",
  canAddContract: "دێشێت گرێبەستێ زیاد بکەت",
  canAddProperty: "دێشێت تایبەتمەندیا زێدە بکەت",
  canAddHoldProperty: "دێشێت تایبەتمەندیا ڕاگرتنێ زێدە بکەت",
  canDeleteHoldProperty: "دێشێت تایبەتمەندیێن ڕاگرتنێ ژێ ببەت",
  canCreateExpense: "دێشێت خەرجیان دروست بکەت",
  canViewTransaction: "دێشێت مامەڵەکێ ببینیت",
  canViewExpenseByTypeReport: "دێشێت تێچوويێت بـ گوێرا راپۆرتا جۆرى نیشان بدەت",
  canTransferContract: "دێشێت گرێبەستەکێ ڤە گوازيت ",
  canCancelContract: "دێشێت گرێبەستەکێ هەڵبوەشێنيت",
  // End Permissions

  // Start Profile
  accountInfo: "زانیاریێن بکارهێنەرى",
  profile: "پرۆفایلا کەسی",
  updateAvatar: "گۆڕینا وێنەى",
  updatePassword: "گۆڕینا وشا نهێنی",
  oldPassword: "وشا نهێنیا کەڤن",
  newPassword: "وشا نهێنیا نوێ",
  confirmNewPassword: "دوپاتکردنەوا وشەیا نهێنی",
  pleaseWriteFullName: "تکایە ناڤێ تەمام بنڤيسە",
  namesMustBeMoreThan3Chars: "ناڤ نابيت ژ ٣ پیت کەمتر بيت",
  updated: "هاتە گوهڕين",
  pleaseWriteAllPasswords: "تکایە هەميى وشێن نهێنی بنڤيسە",
  passwordsAreNotMatch: "وشێن نهێنی وەکى ئێک نین",
  updateInfo: "گۆڕینیا زانیاری",
  // End Profile

  // Start Transactions
  totalIncomes: "کۆیا داهاتان",
  totalOutcomes: "کۆیا خەرجیان",
  all: "هەميى",
  incomes: "هاتيى",
  outcomes: "چوويى",
  types: "جۆرێن وان",
  // End Transactions

  // Start Expense Report
  expenseReport: "ڕاپۆرتێن خەرجیان",
  expensesReport: "ڕاپۆرتێن خەرجیان",
  mcTotal: "کۆیا گشتی",
  expenseCount: "ژمارەيێن خەرجیان",
  // End Expense Report

  // Start Withdraw Report
  depositReport: "ڕاپۆرتا دانانا",
  depositsReport: "ڕاپۆرتا دانانا",
  depositCount: "ژمارەیێن دانانا",
  // End Deposit Report

  // Start Deposit Report
  withdrawReport: "ڕاپۆرتا ڕاکێشانا",
  withdrawsReport: "ڕاپۆرتا ڕاکێشانەکا",
  withdrawCount: "ژمارەيێن ڕاکێشانا",
  // End Deposit Report

  // Start Property Features
  propertyFeatures: "تایبەتمەندیێن یەکەى",
  propertyFeaturesList: "لیستا تایبەتمەندیێن یەکەى",
  createPropertyFeature: "زێدەكرنا تایبەتمەندیێن یەکەى",
  editPropertyFeature: "گۆڕینا تایبەتمەندیێن یەکەى",
  // End Property Features

  // Start Deposit Types
  depositTypes: "جۆرێن دانانا",
  depositTypesList: "لیستا جۆرێن دانان",
  depositTypeList: "لیستا جۆرێن دانان",
  createDepositType: "زێدەكرنا جۆرێن دانان",
  editDepositType: "گۆڕینا جۆریێن دانان",
  // End Deposit Types

  // Start Withdraw Types
  withdrawTypes: "جۆرێن ڕاکێشانا",
  withdrawTypesList: "لیستا جۆرێن ڕاکێشانا",
  withdrawTypeList: "لیستا جۆرێن ڕاکێشانا",
  createWithdrawType: "زێدەكرنا جۆرێن ڕاکێشانا",
  editWithdrawType: "گۆڕینیا جۆرێن ڕاکێشانا",
  // End Withdraw Types

  // Start Deposits
  deposits: "دانان",
  depositsList: "لیستا دانانا",
  depositList: "لیستا دانانا",
  createDeposit: "زێدەكرنا دانانا",
  editDeposit: "گۆڕینا دانانا",
  depositer: "دانەر",
  accountant: "ژمێریار",
  depositType: "جۆرێن دانانا",
  // End Deposits

  // Start Withdraws
  withdraws: "ڕاکێشان",
  withdrawsList: "لیستا ڕاکێشانا",
  withdrawList: "لیستا ڕاکێشانا",
  createWithdraw: "زێدەكرنا ڕاکێشانا",
  editWithdraw: "گۆڕینا ڕاکێشانا",
  withdrawer: "ڕاکێشەر",
  withdrawType: "جۆرێن ڕاکێشانا",
  // End Withdraws

  // Start Buy Contract Report
  buyContractReport: "ڕاپۆرتێن گرێبەستا",
  buyContractsReport: "ڕاپۆرتێن گرێبەستا",
  buyContractCount: "ژمارەيێن گرێبەستا",
  // End Buy Contract Report

  // Start Buy Contract Details
  buyContractDetail: "ڕاپۆرتا گرێبەستا بکڕە",
  buyContractsDetails: "ڕاپۆرتا گرێبەستا بکڕە",
  unitSolds: "یەکێن زانیاری بـ کڕیارا تێن فروتن",
  startDateToToday: "ژ بەروارێ دەستپێکرنا فروتنێ هەتا ئەڤڕۆ",
  building: "باڵەخانە",
  property2: "موڵك",
  paid: "هاتيە دان",
  remaining: "مايەڤە",
  dateOfSale: "بەروارێ فرۆتنێ",
  identifier: "پێناس",
  bondNumber: "ژمارەیا بۆند",
  nOfTheBatch: "ناڤێ دەستەى",
  paymentDate: "بەروارێ پارەدانێ",
  buyerName: "ناڤێ کڕیار",
  page: "پەڕە",
  of: "لەكۆی",
  hBd: "ئهاتيە ئەنجام دان ژ لايەنێ ",
  priceOfProperty: "Price of the property",
  // End Buy Contract Details

  // Start Payment Process Report
  paymentProcessReport: "ڕاپۆرتا پارەدانا",
  paymentProcessesReport: "ڕاپۆرتا پارەدانا",
  paymentProcessCount: "ژمارەيێن پارەدانا",
  paymentReport: "ڕاپۆرتا پارەدانا",
  paymentsReport: "ڕاپۆرتا پارەدانا",
  buyPrice: "بهايێ کڕین",
  DeliveryKey: "وەرگرتنا  كلیل",
  Cash: "پارەدان",
  Installment: "قستەكان",
  // End Payment Process Report

  // Start Safe Box Transaction Report
  safeBoxTransactionReport: "جوڵێن قاسە",
  safeBoxTransactionsReport: "جوڵێن قاسە",
  safeBoxTransactionCount: "ژمارەیێن جوڵێن قاسە",
  safeBoxTransaction: "جوڵێن قاسە",
  // End Safe Box Transaction Report
};
