<template>
  <div v-if="hasPermission(permissions.customersCreate)">
    <content-header>Create Customer</content-header>
    <v-content>
      <b-card>
        <b-form-checkbox
          v-if="projectTenant() === 'Candle'"
          id="isSharedCustomer"
          v-model="isSharedCustomer"
          name="isSharedCustomer"
          style="text-align: end; color: red"
        >
          {{ $t("isSharedCustomer") }}
        </b-form-checkbox>
        <b-card-body>
          <form class="forms-sample" @submit.prevent="onSubmit">
            <h4 v-if="isSharedCustomer">{{ $t("customer") }} 1</h4>
            <div class="row">
              <div class="col-6">
                <text-input
                  id="firstName"
                  :name="$t('firstName')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
                />
                <text-input
                  id="middleName"
                  :name="$t('middleName')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
                />
                <text-input
                  id="lastName"
                  :name="$t('lastName')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
                />
                <div class="col-6">
                    <div class="d-flex justify-content-start">
                      <label class="col-sm-4 px-3 col-form-label text-right"
                        >Gender:
                      </label>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="maleLabel"
                          :checked="form.male"
                          @change="(e) => (form.male = e.target.checked)"
                        />
                        <label class="form-check-label" for="maleLabel">
                          Male
                        </label>
                      </div>
                      <div class="mx-2"></div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="gender"
                          id="femaleLabel"
                          :checked="!form.male"
                          @change="(e) => (form.male = !e.target.checked)"
                        />
                        <label class="form-check-label" for="femaleLabel">
                          Female
                        </label>
                      </div>
                    </div>
                  </div>
                <!-- <boolean-input
                  id="male"
                  :name="$t('gender')"
                  :default-value="true"
                  checked-label="Male"
                  un-checked-label="Female"
                  @switched="(e) => (form.male = e)"
                /> -->
                <date-time-input
                  id="dob"
                  :name="$t('birthdate')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-area-input
                  id="address"
                  :name="$t('address')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  id="email"
                  :name="$t('email')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  id="phone"
                  :name="$t('phone')"
                  :validate="$v"
                  :required="true"
                  :is-loading="isLoading"
                />
                <text-input
                  v-if="!isSharedCustomer"
                  id="phone2"
                  :name="$t('phone2')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
              </div>
              <div class="col-6">
                <text-input
                  id="nationality"
                  :name="$t('nationality')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  id="idcard"
                  :name="$t('idCard')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <div v-if="projectTenant() !== 'MassHills'">
                  <text-input
                    v-if="projectTenant() !== 'RoyalTower'"
                    id="placementCard"
                    :name="$t('residencyCard')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                  <text-input
                    v-if="projectTenant() !== 'RoyalTower'"
                    id="jnsia"
                    :name="$t('jnsia')"
                    :validate="$v"
                    :is-loading="isLoading"
                  />
                </div>
                <text-input
                  v-if="projectTenant() !== 'RoyalTower'"
                  id="foodCard"
                  :name="
                    projectTenant() !== 'MassHills'
                      ? $t('foodCard')
                      : $t('passport')
                  "
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  v-if="projectTenant() !== 'RoyalTower'"
                  id="informationCard"
                  :name="$t('informationCard')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  v-if="projectTenant() !== 'RoyalTower'"
                  id="securitySupport"
                  :name="$t('securitySupport')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-area-input
                  id="note"
                  :name="$t('note')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
                <text-input
                  id="job"
                  :name="$t('job')"
                  :validate="$v"
                  :is-loading="isLoading"
                />
              </div>
            </div>
            <div v-if="isSharedCustomer">
              <hr style="height: 6px !important" />
              <h4 v-if="isSharedCustomer">{{ $t("customer") }} 2</h4>
              <div class="row">
                <div class="col-6">
                  <div class="form-group row">
                    <label
                      for="firstName2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("firstName") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="firstName2"
                        v-model.trim="secondCustomer.firstName"
                        :disabled="isLoading"
                        :placeholder="$t('firstName')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="middleName2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("middleName") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="middleName2"
                        v-model.trim="secondCustomer.middleName"
                        :disabled="isLoading"
                        :placeholder="$t('middleName')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="lastName2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("lastName") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="lastName2"
                        v-model.trim="secondCustomer.lastName"
                        :disabled="isLoading"
                        :placeholder="$t('lastName')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="dob2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("birthdate") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="dob2"
                        v-model.trim="secondCustomer.dob"
                        :disabled="isLoading"
                        :placeholder="$t('dob')"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="address2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("address") }}</label
                    >
                    <div class="col-sm-10">
                      <textarea
                        id="address2"
                        v-model.trim="secondCustomer.address"
                        :disabled="isLoading"
                        :placeholder="$t('address')"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="email2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("email") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="email2"
                        v-model.trim="secondCustomer.email"
                        :disabled="isLoading"
                        :placeholder="$t('email')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="phone2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("phone") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="phone2"
                        v-model.trim="secondCustomer.phone"
                        :disabled="isLoading"
                        :placeholder="$t('phone')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label
                      for="nationality2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("nationality") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="nationality2"
                        v-model.trim="secondCustomer.nationality"
                        :disabled="isLoading"
                        :placeholder="$t('nationality')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="idcard2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("idCard") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="idcard2"
                        v-model.trim="secondCustomer.idcard"
                        :disabled="isLoading"
                        :placeholder="$t('idCard')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="placementCard2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("residencyCard") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="placementCard2"
                        v-model.trim="secondCustomer.placementCard"
                        :disabled="isLoading"
                        :placeholder="$t('residencyCard')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="jnsia2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("jnsia") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="jnsia2"
                        v-model.trim="secondCustomer.jnsia"
                        :disabled="isLoading"
                        :placeholder="$t('jnsia')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="foodCard2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("foodCard") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="foodCard2"
                        v-model.trim="secondCustomer.foodCard"
                        :disabled="isLoading"
                        :placeholder="$t('foodCard')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="informationCard2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("informationCard") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="informationCard2"
                        v-model.trim="secondCustomer.informationCard"
                        :disabled="isLoading"
                        :placeholder="$t('informationCard')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="securitySupport2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("securitySupport") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="securitySupport2"
                        v-model.trim="secondCustomer.securitySupport"
                        :disabled="isLoading"
                        :placeholder="$t('securitySupport')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="note2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("note") }}</label
                    >
                    <div class="col-sm-10">
                      <textarea
                        id="note2"
                        v-model.trim="secondCustomer.note"
                        :disabled="isLoading"
                        :placeholder="$t('note')"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      for="job2"
                      class="col-sm-2 col-form-label text-right"
                      >{{ $t("job") }}</label
                    >
                    <div class="col-sm-10">
                      <input
                        id="job2"
                        v-model.trim="secondCustomer.job"
                        :disabled="isLoading"
                        :placeholder="$t('job')"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        @focus="$event.target.select()"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <submit-input :name="$t('create')" :disabled="isLoading" />
            <is-loading v-if="isLoading" />
          </form>
        </b-card-body>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { required } from "vuelidate/lib/validators";
import IsLoading from "@/components/IsLoading";
import { Customer } from "@/models/Customer";
import SubmitInput from "@/components/Inputs/SubmitInput";
// import BooleanInput from "@/components/Inputs/BooleanInput";
import TextInput from "@/components/Inputs/TextInput";
import DateTimeInput from "@/components/Inputs/DateTimeInput";
import TextAreaInput from "@/components/Inputs/TextAreaInput";
import { permissions, swalMixin } from "@/plugins/constants";
import mixins from "@/mixins";

export default {
  name: "CustomerCreate",
  components: {
    TextAreaInput,
    DateTimeInput,
    TextInput,
    // BooleanInput,
    SubmitInput,
    ContentHeader,
    "v-content": Content,
    IsLoading,
  },
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Customer().fillAbles),
      isLoading: false,
      permissions: permissions,
      isSharedCustomer: false,
      secondCustomer: {
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        nationality: null,
        address: null,
        email: null,
        phone: null,
        phone2: null,
        idcard: null,
        foodCard: null,
        informationCard: null,
        placementCard: null,
        securitySupport: null,
        note: null,
        male: null,
        jnsia: null,
        job: null,
      },
      finalCustomerResult: {
        firstName: null,
        middleName: null,
        lastName: null,
        dob: null,
        nationality: null,
        address: null,
        email: null,
        phone: null,
        phone2: null,
        idcard: null,
        foodCard: null,
        informationCard: null,
        placementCard: null,
        securitySupport: null,
        note: null,
        male: null,
        jnsia: null,
        job: null,
      },
    };
  },
  validations: {
    form: {
      firstName: { required },
      middleName: { required },
      lastName: { required },
      dob: {},
      nationality: {},
      address: {},
      email: {},
      phone: { required },
      phone2: {},
      idcard: {},
      foodCard: {},
      informationCard: {},
      placementCard: {},
      securitySupport: {},
      note: {},
      male: {},
      jnsia: {},
      job: {},
    },
  },
  methods: {
    onSubmit() {
      if (
        this.isSharedCustomer &&
        (this.secondCustomer.firstName === null ||
          this.secondCustomer.firstName === undefined ||
          this.secondCustomer.firstName === "" ||
          this.secondCustomer.middleName === null ||
          this.secondCustomer.middleName === undefined ||
          this.secondCustomer.middleName === "" ||
          this.secondCustomer.lastName === null ||
          this.secondCustomer.lastName === undefined ||
          this.secondCustomer.lastName === "" ||
          this.secondCustomer.phone === null ||
          this.secondCustomer.phone === undefined ||
          this.secondCustomer.phone === "")
      ) {
        swalMixin("error", this.$t("pleaseTypeSecondCustomerInformation"));
        return false;
      }
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      delete this.form.hasAccount;
      delete this.form.controls;
      this.isLoading = true;
      let sendForm = this.form;
      if (this.isSharedCustomer) {
        this.finalCustomerResult.firstName =
          this.form.firstName +
          " " +
          this.form.middleName +
          " " +
          this.form.lastName;
        this.finalCustomerResult.middleName = " + ";
        this.finalCustomerResult.lastName =
          this.secondCustomer.firstName +
          " " +
          this.secondCustomer.middleName +
          " " +
          this.secondCustomer.lastName;

        this.finalCustomerResult.dob = this.form.dob;
        this.finalCustomerResult.howCame = this.secondCustomer.dob;

        this.finalCustomerResult.male = this.form.male;

        this.finalCustomerResult.phone = this.form.phone;
        this.finalCustomerResult.phone2 = this.secondCustomer.phone;

        if (
          mixins.checkString(this.form.address) &&
          mixins.checkString(this.secondCustomer.address)
        ) {
          this.finalCustomerResult.address =
            this.form.address + " + " + this.secondCustomer.address;
        }
        if (
          mixins.checkString(this.form.email) &&
          mixins.checkString(this.secondCustomer.email)
        ) {
          this.finalCustomerResult.email =
            this.form.email + " + " + this.secondCustomer.email;
        }

        if (
          mixins.checkString(this.form.nationality) &&
          mixins.checkString(this.secondCustomer.nationality)
        ) {
          this.finalCustomerResult.nationality =
            this.form.nationality + " + " + this.secondCustomer.nationality;
        }

        if (
          mixins.checkString(this.form.placementCard) &&
          mixins.checkString(this.secondCustomer.placementCard)
        ) {
          this.finalCustomerResult.placementCard =
            this.form.placementCard + " + " + this.secondCustomer.placementCard;
        }

        if (
          mixins.checkString(this.form.idcard) &&
          mixins.checkString(this.secondCustomer.idcard)
        ) {
          this.finalCustomerResult.idcard =
            this.form.idcard + " + " + this.secondCustomer.idcard;
        }

        if (
          mixins.checkString(this.form.jnsia) &&
          mixins.checkString(this.secondCustomer.jnsia)
        ) {
          this.finalCustomerResult.jnsia =
            this.form.jnsia + " + " + this.secondCustomer.jnsia;
        }

        if (
          mixins.checkString(this.form.foodCard) &&
          mixins.checkString(this.secondCustomer.foodCard)
        ) {
          this.finalCustomerResult.foodCard =
            this.form.foodCard + " + " + this.secondCustomer.foodCard;
        }

        if (
          mixins.checkString(this.form.informationCard) &&
          mixins.checkString(this.secondCustomer.informationCard)
        ) {
          this.finalCustomerResult.informationCard =
            this.form.informationCard +
            " + " +
            this.secondCustomer.informationCard;
        }

        if (
          mixins.checkString(this.form.securitySupport) &&
          mixins.checkString(this.secondCustomer.securitySupport)
        ) {
          this.finalCustomerResult.securitySupport =
            this.form.securitySupport +
            " + " +
            this.secondCustomer.securitySupport;
        }

        if (
          mixins.checkString(this.form.note) &&
          mixins.checkString(this.secondCustomer.note)
        ) {
          this.finalCustomerResult.note =
            this.form.note + " + " + this.secondCustomer.note;
        }

        if (
          mixins.checkString(this.form.job) &&
          mixins.checkString(this.secondCustomer.job)
        ) {
          this.finalCustomerResult.job =
            this.form.job + " + " + this.secondCustomer.job;
        }
        this.finalCustomerResult.isSharedCustomer = true;
        sendForm = this.finalCustomerResult;
      }
      this.$store
        .dispatch("customers/CREATE", sendForm)
        .then(() => {
          this.$v.$reset();
          this.$router.push({ name: "customers" });
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
