<template>
  <div v-if="hasPermission(permissions.reportsSafeBoxTransactions)">
    <content-header>{{ $t("safeBoxTransaction") }}</content-header>
    <v-content>
      <h5>
        {{ $t("totalIncomes") }}: {{ totalIncomes.toLocaleString() }} -
        {{ $t("totalOutcomes") }}: {{ totalOutcomes.toLocaleString() }}
      </h5>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t("fromDate") }}</label>
          <flat-pickr
            id="fromDate"
            v-model="fromDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t("toDate") }}</label>
          <flat-pickr
            id="toDate"
            v-model="toDate"
            class="form-control"
            :placeholder="$t('selectDate')"
            name="date"
          />
        </b-col>
        <b-col>
          <label for="safeBox">{{ $t("safeBox") }}</label>
          <select id="safeBox" v-model="selectedSafeBoxId" class="form-control">
            <option
              v-for="safeBox in safeBoxes"
              :value="safeBox.id"
              :key="safeBox.id"
            >
              {{ safeBox.name }}
            </option>
          </select>
        </b-col>
        <b-col>
          <label for="withdraw">{{ $t("withdraw") }}</label>
          <select id="withdraw" v-model="withdraw" class="form-control">
            <option value="">{{ $t("all") }}</option>
            <option value="true">{{ $t("yes") }}</option>
            <option value="false">{{ $t("no") }}</option>
          </select>
        </b-col>
      </b-row>
      <BootstrapTable
        :rows="rows"
        :columns="columns"
        :config="config"
        :total-rows="totalRecords"
        :actions="actions"
        @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t("noResultsFound") }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0"
            >{{ totalRecords }} {{ $t("totalRecords") }}</template
          >
          <template v-else>{{ $t("noResultsFound") }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice && props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="lost" slot-scope="props">
          {{ props.row.lost ? "✔" : "❌" }}
        </template>
        <template slot="date" slot-scope="props">
          {{ formatDate(props.row.date) }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import { SafeBoxTransaction } from "@/models/SafeBoxTransaction";
import IsLoading from "@/components/IsLoading";
import {
  permissions,
  serverParams,
  tableActions,
  tableConfigure,
} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "SafeBoxTransaction",
  components: {
    IsLoading,
    "v-content": Content,
    ContentHeader,
    BootstrapTable,
    flatPickr,
  },
  data() {
    return {
      isLoading: false,
      model: new SafeBoxTransaction(),
      columns: [],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t("safeBoxTransaction"), true, false),
      serverParams: serverParams,
      selectedRows: [],
      selectedTypes: [],
      fromDate: this.$helpers.getCurrentDate(-1),
      toDate: this.$helpers.getCurrentDate(),
      selectedSafeBoxId: 1,
      safeBoxes: [],
      withdraw: "",
      permissions: permissions,
      totalIncomes: 0,
      totalOutcomes: 0,
    };
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    selectedSafeBoxId: function () {
      this.resetServerParams();
      this.loadData();
    },
    withdraw: function () {
      this.resetServerParams();
      this.loadData();
    },
  },
  beforeMount() {
    this.columns = this.model.getColumns();
  },
  mounted() {
    this.loadData(true);
    this.loadSafeBoxes();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start = 0;
        serverParams._end = 10;
        serverParams._sort = "";
        serverParams._order = "";
        serverParams._search = "";
      }
      this.isLoading = true;
      this.$store
        .dispatch("reports/GET_SAFE_BOX_TRANSACTION", this.serverParams)
        .then((response) => {
          this.rows = response.data.map((e) => ({
            id: e.id,
            referenceNumber: e.referenceNumber,
            mcPrice: e.mcPrice,
            lost: e.lost,
            reason: this.getTranslate(e.reason),
            table: this.getTranslate(e.table),
            date: e.date,
          }));
          this.totalRecords = parseInt(response.headers["x-total-count"]);
          this.totalIncomes = parseFloat(response.headers["mctotalincome"]);
          this.totalOutcomes = parseFloat(response.headers["mctotaloutcome"]);
          this.isLoading = false;
        })
        .catch(() => (this.isLoading = false));
    },
    onChangeQuery(queryParams) {
      serverParams._start = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end = queryParams.page * queryParams.per_page;
      serverParams._sort = queryParams.sort[0] ? queryParams.sort[0].name : "";
      serverParams._order = queryParams.sort[0]
        ? queryParams.sort[0].order
        : "";
      serverParams._search = queryParams.global_search ?? "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subLevelId: this.selectedSafeBoxId,
        enableSubLevel: true,
        isLost: this.withdraw,
      };
      this.loadData();
    },
    resetServerParams() {
      serverParams._start = 0;
      serverParams._end = 10;
      serverParams._sort = "";
      serverParams._order = "";
      serverParams._search = "";
      serverParams.custom_params = {
        startDate: this.fromDate,
        endDate: this.toDate,
        subLevelId: this.selectedSafeBoxId,
        enableSubLevel: true,
        isLost: this.withdraw,
      };
    },
    loadSafeBoxes() {
      this.$store
        .dispatch("safeBoxes/GET_ALL", serverParams)
        .then((response) => {
          this.safeBoxes = response.data;
        });
    },
    getTranslate(e) {
      switch (e) {
        case "Withdraws":
          return this.$t("withdraws");
        case "Add Withdraw":
          return this.$t("addWithdraw");
        case "Delete Withdraw":
          return this.$t("deleteWithdraw");
        case "SafeBox Transfer":
          return this.$t("safeBoxTransfer");
        case "Delete SafeBox":
          return this.$t("deleteSafebox");
        case "Delete Property Expense":
          return this.$t("deletePrpertyExpense");
        case "Property Expense":
          return this.$t("propertyExpense");
        case "Investors":
          return this.$t("investors");
        case "Add Investor":
          return this.$t("addInvestor");
        case "Delete Investor":
          return this.$t("deleteInvestor");
        case "Expenses":
          return this.$t("expenses");
        case "Add Expense":
          return this.$t("addExpense");
        case "Delete Expense":
          return this.$t("deleteExpense");
        case "Deposits":
          return this.$t("deposits");
        case "Add Deposit":
          return this.$t("addDeposit");
        case "Delete Deposit":
          return this.$t("deleteDeposit");
        case "Unpay Contract Payment":
          return this.$t("unpayContractPayment");
        case "Contract Payment":
          return this.$t("contractPayment");
        case "Buy Contract Payment":
          return this.$t("buyContractPayment");
        case "Contracts":
          return this.$t("contracts");
        case "Delete Cancel Contract":
          return this.$t("deleteCancleContract");
        case "Cancel Contract":
          return this.$t("cancleContract");
        case "Delete Contract Payment":
          return this.$t("deleteContractPayment");
        case "Delete Buy Contract Payment":
          return this.$t("deleteBuyContractPayment");

        default:
          e.replace("Delete SafeBox Transfer From", this.$t('deleteSafeBoxTransferFrom'));
          break;
      }
      // return `flatpickrssss${e.replace("Delete SafeBox Transfer From", this.$t('deleteSafeBoxTransferFrom'))}`;
    },
  },
};
</script>
