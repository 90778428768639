var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission(_vm.permissions.buyContractsList))?_c('div',[_c('content-header',[_vm._v(_vm._s(_vm.$t("buyContracts")))]),_c('v-content',[_c('BootstrapTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"config":_vm.config,"total-rows":_vm.totalRecords,"actions":_vm.actions},on:{"on-change-query":_vm.onChangeQuery,"on-create":function($event){return _vm.$router.push({ name: 'buyContracts.create' })}},scopedSlots:_vm._u([{key:"pagination-info",fn:function(props){return [(props.currentPageRowsLength !== 0)?[_vm._v(_vm._s(_vm.totalRecords)+" "+_vm._s(_vm.$t("totalRecords")))]:[_vm._v(_vm._s(_vm.$t("noResultsFound")))]]}},{key:"propertyName",fn:function(props){return [_c('router-link',{attrs:{"to":{
            name: 'properties.edit',
            params: { id: props.row.propertyId },
          }}},[_vm._v(_vm._s(props.row.propertyName))])]}},{key:"customerName",fn:function(props){return [_c('router-link',{attrs:{"to":{
            name: 'customers.edit',
            params: { id: props.row.customerId },
          }}},[_vm._v(_vm._s(props.row.customerName))])]}},{key:"buyingDate",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatDate(props.row.buyingDate))+" ")]}},{key:"buyingPrice",fn:function(props){return [_vm._v(" "+_vm._s(props.row.buyingPrice.toLocaleString())+" ")]}},{key:"discount",fn:function(props){return [_vm._v(" "+_vm._s(props.row.discount.toLocaleString())+" ")]}},{key:"controls",fn:function(props){return (
          _vm.hasPermission(_vm.permissions.buyContractsShow) ||
          _vm.hasPermission(_vm.permissions.buyContractsDelete)
        )?[_c('control-buttons',{attrs:{"props":props,"show-link":_vm.hasPermission(_vm.permissions.buyContractsShow) && 'buyContracts.show',"delete-link":_vm.hasPermission(_vm.permissions.buyContractsDelete) &&
            'buyContracts/DELETE'},on:{"successAction":function($event){return _vm.loadData(true)}}}),_c('button',{staticClass:"btn btn-outline-success m-1",on:{"click":function($event){return _vm.$router.push({
              name: 'buyContract.attachments',
              params: { id: props.row.id },
            })}}},[_c('i',{staticClass:"fal fa-file"})]),(_vm.projectTenant() === 'MassHills')?_c('button',{staticClass:"btn btn-danger m-1",on:{"click":function($event){return _vm.$router.push({
              name: 'buyContract.expenses',
              params: { id: props.row.id },
            })}}},[_c('i',{staticClass:"fal fa-money-bill-alt"})]):_vm._e()]:undefined}}],null,true)},[_c('template',{slot:"empty-results"},[_vm._v(_vm._s(_vm.$t("noResultsFound")))])],2),(_vm.isLoading)?_c('is-loading'):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }