<template>
  <div v-if="hasPermission(permissions.reportsTransactions)">
    <content-header>{{ $t('transactions') }}</content-header>
    <v-content>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t('fromDate') }}</label>
          <flat-pickr id="fromDate" v-model="fromDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t('toDate') }}</label>
          <flat-pickr id="toDate" v-model="toDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="types">{{ $t('types') }}</label>
          <b-form-select id="types" v-model="type" :options="types" @change="loadData"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <label>{{ $t('totalIncomes') }}:&nbsp;</label>
          <span class="text-bold text-success">{{ incomes.toLocaleString() }}</span>
        </b-col>
        <b-col>
          <label>{{ $t('totalOutcomes') }}:&nbsp;</label>
          <span class="text-bold text-danger">{{ outcomes.toLocaleString() }}</span>
        </b-col>
      </b-row>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="contractNumber" slot-scope="props">
          <router-link
              v-if="props.row.type === 'Expenses'"
              :to="{name: 'expenses'}"
          >
            {{ props.row.contractNumber }}
          </router-link>
          <router-link
              v-else-if="props.row.type === 'Prop Expenses'"
              :to="{name: 'properties.edit', params: {id: props.row.navId}, hash: '#expenses'}"
          >
            {{ props.row.contractNumber }}
          </router-link>
          <router-link
              v-else-if="props.row.type === 'Cancel Contract'"
              :to="{name: 'cancelContracts'}"
          >
            {{ props.row.contractNumber }}
          </router-link>
          <router-link
              v-else-if="props.row.type === 'Extra Payments'"
              :to="{name: 'buyContracts.show', params: {id: props.row.navId}}"
          >
            {{ props.row.contractNumber }}
          </router-link>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="type" slot-scope="props">
          {{ $t(prepareTrans(props.row.type)) }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format('YYYY-MM-DD') }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import IsLoading from "@/components/IsLoading";
import flatPickr from "vue-flatpickr-component";
import {permissions, serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";

export default {
  name: "Transactions",
  components: {IsLoading, 'v-content': Content, ContentHeader, flatPickr, BootstrapTable},
  data() {
    return {
      isLoading: false,
      fromDate: this.$helpers.getCurrentDate(),
      toDate: this.$helpers.getCurrentDate(1),
      incomes: 0,
      outcomes: 0,
      type: 0,
      columns: [
        { label: this.$t('id'), name: 'id', visibility: process.env.VUE_APP_PROJECT_NAME === "MassHills" ? false : true, sort: true, uniqueId: true},
        { label: this.$t('contractNumber'), name: 'contractNumber', sort: true},
        { label: this.$t('price'), name: 'mcPrice', sort: true},
        { label: this.$t('type'), name: 'type', sort: true},
        { label: this.$t('detail'), name: 'detail', sort: true},
        { label: this.$t('date'), name: 'date', sort: true}
      ],
      rows: [],
      actions: tableActions(false),
      totalRecords: 0,
      config: tableConfigure(this.$t('transactions'), true, false),
      serverParams: serverParams,
      selectedRows: [],
      types: [
        {
          value: 0,
          text: this.$t('all')
        },
        {
          value: 1,
          text: this.$t('incomes')
        },
        {
          value: 2,
          text: this.$t('outcomes')
        }
      ],
      permissions: permissions
    }
  },
  watch: {
    fromDate: function () {
      this.loadData()
    },
    toDate: function () {
      this.loadData()
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(reset = false) {
      if (reset) {
        serverParams._start   = 0
        serverParams._end     = 10
        serverParams._sort    = ''
        serverParams._order   = ''
        serverParams._search  = ''
      }
      this.isLoading = true;
      this.serverParams.fromDate  = this.fromDate;
      this.serverParams.toDate    = this.toDate;
      this.serverParams.type      = this.type;
      this.$store.dispatch('reports/GET_TRANSACTIONS', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.incomes      = parseInt(response.headers['incomes'])
            this.outcomes     = parseInt(response.headers['outcomes'])
            this.isLoading    = false
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start   = (queryParams.page - 1) * queryParams.per_page
      serverParams._end     = queryParams.page * queryParams.per_page
      serverParams._sort    = queryParams.sort[0] ? queryParams.sort[0].name : ''
      serverParams._order   = queryParams.sort[0] ? queryParams.sort[0].order : ''
      serverParams._search  = queryParams.global_search ?? ''
      this.loadData();
    },
  }
}
</script>
