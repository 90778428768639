<template>
  <div>
    <content-header>{{ $t('editProperties') }}</content-header>
    <v-content>
      <b-card no-body>
        <b-tabs card :value="activeTabIndex" @input="changeRoute">
          <b-tab v-if="hasPermission(permissions.propertiesEdit)" :title="$t('edit')">
            <form class="forms-sample" @submit.prevent="onSubmit">
              <text-input id="name" :name="projectTenant() === 'MassHills' ? $t('villaNumber') : $t('name')" :validate="$v" :required="true" :max-length="true" :is-loading="isLoading" />
              <b-row v-if="projectTenant() !== 'MassHills'" class="mb-2">
                <b-col class="text-right" cols="2">
                  <label class="col-form-label text-right">{{ $t('additionalInfo') }}</label>
                </b-col>
                <b-col>
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.additional-info variant="info">{{ $t('additionalInfo') }}</b-button>
                      </b-card-header>
                      <b-collapse id="additional-info" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-row>
                            <b-col>
                              <text-input id="streetNumber" :name="$t('streetNumber')" :validate="$v" :is-loading="isLoading" />
                            </b-col>
                            <b-col>
                              <number-input id="streetWidth" :name="$t('streetWidth')" :validate="$v" :is-loading="isLoading" />
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col>
                              <text-input id="numberOfRooms" :name="$t('rooms')" :validate="$v" :is-loading="isLoading" />
                            </b-col>
                            <b-col>
                              <number-input id="floors" :name="$t('floors')" :validate="$v" :is-loading="isLoading" />
                            </b-col>
                          </b-row>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
              </b-row>
              <select-input
                  id="propertyType"
                  :name="projectTenant() === 'Candle' ? $t('apartmentType') : $t('villaType')"
                  :validate="$v"
                  :required="true"
                  api-link="propertyTypes/GET_ALL"
                  label="name"
                  :default-value="form.propertyType"
                  @itemSelected="e => form.propertyTypeId = e"
                  :is-loaded="isLoading"
              />
              <text-input id="zone" :name="projectTenant() === 'Candle' ? $t('tower') : $t('zone')" :validate="$v" :is-loading="isLoading" />
              <text-input id="block" :name="projectTenant() === 'Candle' ? $t('floor') : $t('block')" :validate="$v" :is-loading="isLoading" />
              <text-input v-if="projectTenant() === 'RoyalTower' || projectTenant() === 'Candle'" id="extraProperty1" :name="$t('extraProperty1')" :validate="$v" :is-loading="isLoading" />
              <text-input v-if="projectTenant() === 'RoyalTower' || projectTenant() === 'Candle'" id="extraProperty2" :name="$t('extraProperty2')" :validate="$v" :is-loading="isLoading" />
              <text-input v-if="projectTenant() === 'Candle'" v-show="false" id="note" :name="$t('realEstateAgent')" :validate="$v" :is-loading="isLoading" />
              <select-input
                  v-if="projectTenant() === 'Candle'"
                  id="note1"
                  :name="$t('realEstateAgent')"
                  :validate="$v"
                  api-link="leadSources/GET_ALL"
                  label="name"
                  @itemSelected="e => form.note = e.name"
                  :return-object="true"
              />
              <div :style="[projectTenant() !== 'Candle' ? {'display': 'block'} : {'display': 'none'}]">
                <number-input id="plotArea" :name="$t('plotArea')" :validate="$v" :is-loading="isLoading" />
              </div>
              <div v-if="projectTenant() !== 'MassHills'">
                <number-input
                    id="buildUpArea"
                    :name="projectTenant() !== 'MassHills' ? projectTenant() === 'Candle' ? $t('apartment') : $t('area') : $t('buildUpArea')"
                    :validate="$v"
                    :is-loading="isLoading"
                />
                <number-input
                    id="meterPrice"
                    :name="projectTenant() !== 'MassHills' ? projectTenant() === 'Candle' ? $t('apartmentPrice') : $t('priceMeter') : $t('meterPrice')"
                    :validate="$v"
                    :is-loading="isLoading"
                />
              </div>
              <div v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle'" class="form-group row">
                <label for="amount" class="col-sm-2 col-form-label text-right">{{ $t('amount') }}</label>
                <div class="col-sm-10">
                  <input
                      id="amount"
                      v-model.trim="form.initialPrice"
                      placeholder="Amount"
                      type="number"
                      autocomplete="off"
                      class="form-control"
                      @focus="$event.target.select()"
                      v-on:keypress="e => $helpers.isNumeric(e)"
                      step="any"
                  />
                </div>
              </div>
<!--              <div v-if="projectTenant() !== 'DiplomatiSafiran'" class="form-group row">-->
<!--                <label class="col-sm-2 col-form-label text-right">{{ $t('amount') }}</label>-->
<!--                <div class="col-sm-10">-->
<!--                  <p class="lead pt-1">{{ amount.toLocaleString() }}</p>-->
<!--                </div>-->
<!--              </div>-->
              <number-input v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle' && projectTenant() !== 'RoyalTower'" id="area" :name="$t('area')" :validate="$v" :is-loading="isLoading" />
              <div v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle' && projectTenant() !== 'RoyalTower'" class="form-group row">
                <label class="col-sm-2 col-form-label text-right">{{ $t('variance') }}</label>
                <div class="col-sm-10">
                  <p class="lead pt-1">{{ variance.toLocaleString() }}</p>
                </div>
              </div>
              <number-input v-if="projectTenant() !== 'DiplomatiSafiran' && projectTenant() !== 'Candle' && projectTenant() !== 'RoyalTower'" id="extraCost" :name="$t('extraCost')" :validate="$v" :is-loading="isLoading" :readonly="true" />
              <number-input v-if="projectTenant() !== 'MassHills'" id="initialPrice" :name="$t('initialPrice')" :validate="$v" :is-loading="isLoading" />
              <text-area-input id="note" :name="$t('note')" :validate="$v" :is-loading="isLoading" />
              <div v-if="projectTenant() !== 'DiplomatiSafiran'" class="form-group row">
                <label class="col-sm-2 col-form-label text-right"></label>
                <div class="col-sm-10">
                  <p class="lead pt-1">{{ finalPrice.toLocaleString() }}</p>
                </div>
              </div>
              <submit-input v-if="this.hasPermission(this.permissions.propertiesEdit)" icon="fa-save" :name="$t('save')" :disabled="isLoading"/>
              <is-loading v-if="isLoading" />
            </form>
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowAttachments)" :title="$t('attachment')">
            <b-button variant="outline-primary" class="float-right" @click.prevent="$router.push({
              name: 'property.attachment.create', params: {id: $route.params.id}
            })">
              <i class="fas fa-plus-circle"></i> {{ $t('create') }}
            </b-button>
            <PropertyAttachmentsList :attachments="propertyAttachments" @loadAll="loadOneData" />
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowExpenses)" :title="$t('expenses')">
            <PropertyExpensesList :expenses="propertyExpenses" @loadAll="loadOneData" />
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowFollowups)" :title="$t('followUp')">
            <b-button variant="outline-primary" class="float-right" @click.prevent="$router.push({
              name: 'property.follow.up.create', params: {id: $route.params.id}
            })">
              <i class="fas fa-plus-circle"></i> {{ $t('create') }}
            </b-button>
            <PropertyFollowUpList :follow-ups="propertyFollowUps" @loadAll="loadOneData" />
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowContracts)" :title="$t('contracts')">
            <PropertyContractsList :contracts="propertyContracts" />
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowFeatures)" :title="$t('features')">
            <PropertyFeaturesList :features="propertyFeatures" @loadAll="loadOneData" />
          </b-tab>
          <b-tab v-if="hasPermission(permissions.propertiesShowHolds)" :title="$t('holds')">
            <PropertyHoldsList :holds="propertyHolds" />
          </b-tab>
        </b-tabs>
      </b-card>
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {maxLength, required} from 'vuelidate/lib/validators'
import IsLoading from "@/components/IsLoading";
import {Properties} from "@/models/Properties";
import PropertyAttachmentsList from "./attachments/List";
import PropertyExpensesList from "./expenses/List";
import PropertyFollowUpList from "./followup/List";
import TextInput from "@/components/Inputs/TextInput";
import NumberInput from "@/components/Inputs/NumberInput";
import SubmitInput from "@/components/Inputs/SubmitInput";
import SelectInput from "@/components/Inputs/SelectInput";
import PropertyContractsList from "@/views/properties/contracts/List";
import PropertyFeaturesList from "@/views/properties/features/List";
import PropertyHoldsList from "@/views/properties/holds/List";
import {permissions} from "@/plugins/constants";
import TextAreaInput from "@/components/Inputs/TextAreaInput";

export default {
  name: "PropertiesEdit",
  components: {TextAreaInput, PropertyHoldsList, PropertyFeaturesList, PropertyContractsList, SelectInput, SubmitInput, NumberInput, TextInput, PropertyFollowUpList, PropertyExpensesList, ContentHeader, 'v-content': Content, IsLoading, PropertyAttachmentsList},
  data() {
    return {
      form: this.$helpers.convertArrayToObject(new Properties().fillAbles),
      features: [],
      firstFeatures: [],
      isLoading: false,
      propertyAttachments: [],
      propertyExpenses: [],
      propertyFollowUps: [],
      propertyContracts: [],
      propertyFeatures: [],
      propertyHolds: [],
      tabsPathsDictionary: ['#edit', '#attachments', '#expenses', '#followups', '#contracts', '#features', '#holds'],
      finalPrice: 0,
      amount: 0,
      variance: 0,
      lastFeatureLength: 0,
      varianceStored: localStorage.getItem('variance') ?? 10,
      buildUpAreaStored: localStorage.getItem('buildUpArea') ?? 300,
      permissions: permissions
    };
  },
  watch: {
    features: {
      handler() {
        this.calculatePropertyFinalPrice()
      },
      deep: true
    },
    form: {
      handler() {
        this.calculatePropertyFinalPrice()
      },
      deep: true
    }
  },
  computed: {
    activeTabIndex() {
      const route = this.$route.hash;
      return this.tabsPathsDictionary.findIndex(element => element === route) || 0;
    }
  },
  mounted() {
    this.loadOneData();
  },
  validations: {
    form: {
      name: {required, maxLength: maxLength(255)},
      propertyTypeId: {required},
      zone: {},
      block: {},
      streetNumber: {},
      area: {},
      streetWidth: {},
      numberOfRooms: {},
      floors: {},
      corner: {},
      note: {},
      initialPrice: {},
      plotArea: {},
      extraProperty1: {},
      extraProperty2: {},
      buildUpArea: {},
      meterPrice: {},
      finalPrice: {},
      extraCost: {required},
    }
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) return false;
      this.form.propertyFeatures = this.features.map(item => {
        return {
          featureId: item.id,
          extraPrice: item.extraPrice
        };
      });
      this.form.finalPrice = this.finalPrice
      this.isLoading = true;
      this.$store.dispatch('properties/UPDATE', {id: this.$route.params.id, form: this.form}).then(() => {
        this.$v.$reset();
        this.$router.push({name: "properties"})
      }).catch(() => this.isLoading = false);
    },
    loadOneData() {
      this.isLoading = true;
      this.$store.dispatch('properties/GET_ONE', this.$route.params.id).then((response) => {
        this.form                 = response.data;
        this.amount               = response.data.initialPrice;
        this.propertyFeatures     = response.data.propertyFeatures;
        this.propertyHolds        = response.data.propertyHolds;
        this.propertyAttachments  = response.data.propertyAttachments;
        this.propertyExpenses     = response.data.propertyExpenses;
        this.propertyFollowUps    = response.data.propertyFollowUps;
        this.propertyContracts    = this._.sortBy(response.data.buyContracts, ['buyingDate']).reverse();
        this.isLoading            = false;
      }).catch(() => this.isLoading = false)
    },
    changeRoute(value) {
      this.$router.push(this.tabsPathsDictionary[value])
    },
    calculatePropertyFinalPrice() {
      if (this.$helpers.projectTenant() !== 'MassHills') {
        this.form.initialPrice  = parseFloat(this.form.meterPrice) * parseFloat(this.form.buildUpArea);
        this.variance = 0;
        this.form.extraCost = 0;
      } else {
        this.variance       = parseFloat(this.form.area) - parseFloat(this.form.plotArea);
        this.amount         = this.form.initialPrice;
        if (this.variance >= this.varianceStored || this.variance === 0) {
          this.form.extraCost = this.variance > this.varianceStored ? this.buildUpAreaStored * (this.variance - this.varianceStored) : 0;
        } else if (this.variance < 0) {
          this.form.extraCost = this.variance * this.buildUpAreaStored;
        } else {
          this.form.extraCost = 0;
        }
        // this.form.extraCost = this.variance > this.varianceStored ? this.buildUpAreaStored * (this.variance - this.varianceStored) : 0;
      }
      this.finalPrice = parseFloat(this.amount) + parseFloat(this.form.extraCost);
    }
  }
}
</script>
