<template>
  <div v-if="hasPermission(permissions.reportsPropertyExpense)">
    <content-header>{{ $t('propertyExpense') }}</content-header>
    <v-content>
      <h5>{{ $t('totalPropertyExpense') }}: {{ totalPropertyExpense.toLocaleString() }}</h5>
      <b-row class="mb-4">
        <b-col>
          <label for="fromDate">{{ $t('fromDate') }}</label>
          <flat-pickr id="fromDate" v-model="fromDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col>
          <label for="toDate">{{ $t('toDate') }}</label>
          <flat-pickr id="toDate" v-model="toDate" class="form-control" :placeholder="$t('selectDate')" name="date" />
        </b-col>
        <b-col style="display: none">
          <label for="safeBox">{{ $t('safeBox') }}</label>
          <select id="safeBox" v-model="selectedSafeBoxId" class="form-control">
            <option value="0">{{ $t('all') }}</option>
            <option v-for="safeBox in safeBoxes" :value="safeBox.id" :key="safeBox.id">{{ safeBox.name }}</option>
          </select>
        </b-col>
        <b-col>
          <label for="total">{{ $t('total') }}</label>
          <input :value="total.toLocaleString()" id="total" class="form-control" disabled="disabled" />
        </b-col>
      </b-row>
      <BootstrapTable
          :rows="rows"
          :columns="columns"
          :config="config"
          :total-rows="totalRecords"
          :actions="actions"
          @on-change-query="onChangeQuery"
      >
        <template slot="empty-results">{{ $t('noResultsFound') }}</template>
        <template slot="pagination-info" slot-scope="props">
          <template v-if="props.currentPageRowsLength !== 0">{{totalRecords}} {{ $t('totalRecords') }}</template>
          <template v-else>{{ $t('noResultsFound') }}</template>
        </template>
        <template slot="mcPrice" slot-scope="props">
          {{ props.row.mcPrice.toLocaleString() }}
        </template>
        <template slot="date" slot-scope="props">
          {{ moment(props.row.date).format('YYYY-MM-DD') }}
        </template>
      </BootstrapTable>
      <is-loading v-if="isLoading" />
    </v-content>
  </div>
</template>

<script>
import Content from "@/components/Content";
import ContentHeader from "@/components/ContentHeader";
import {PropertyExpense} from "@/models/PropertyExpense";
import IsLoading from "@/components/IsLoading";
import {permissions, serverParams, tableActions, tableConfigure} from "@/plugins/constants";
import BootstrapTable from "@/components/Table/BootstrapTable";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "PropertyExpenseReport",
  components: {IsLoading, 'v-content': Content, ContentHeader, BootstrapTable, flatPickr},
  data() {
    return {
      isLoading: false,
      model: new PropertyExpense(),
      columns: [],
      rows: [],
      actions: tableActions(),
      totalRecords: 0,
      config: tableConfigure(this.$t('propertyExpenseList')),
      serverParams: serverParams,
      selectedRows: [],
      fromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      selectedSafeBoxId: 0,
      safeBoxes: [],
      total: "",
      permissions: permissions,
      totalPropertyExpense: 0,
    };
  },
  beforeMount() {
    this.columns  = this.model.getColumns();
  },
  mounted() {
    this.loadSafeBoxes();
  },
  watch: {
    fromDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    toDate: function () {
      this.resetServerParams();
      this.loadData();
    },
    selectedSafeBoxId: function () {
      this.resetServerParams();
      this.loadData();
    }
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.$store.dispatch('propertyExpenses/GET_ALL', this.serverParams)
          .then((response) => {
            this.rows         = response.data
            this.totalRecords = parseInt(response.headers['x-total-count'])
            this.totalPropertyExpense  = parseInt(response.headers['mctotal']);
            this.isLoading    = false
            this.total = parseInt(response.headers['mctotal'])
          })
          .catch(() => this.isLoading = false);
    },
    onChangeQuery(queryParams) {
      serverParams._start         = (queryParams.page - 1) * queryParams.per_page;
      serverParams._end           = queryParams.page * queryParams.per_page;
      serverParams._sort          = queryParams.sort[0] ? queryParams.sort[0].name : '';
      serverParams._order         = queryParams.sort[0] ? queryParams.sort[0].order : '';
      serverParams._search        = queryParams.global_search ?? '';
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate, subLevelId: this.selectedSafeBoxId, enableSubLevel: true}
      this.loadData()
    },
    loadSafeBoxes() {
      this.$store.dispatch('safeBoxes/GET_ALL', serverParams).then((response) => {
        this.safeBoxes = response.data
      })
    },
    resetServerParams() {
      serverParams._start = 0
      serverParams._end   = 10
      serverParams._sort  = ''
      serverParams._order = ''
      serverParams._search = ''
      serverParams.custom_params  = {startDate: this.fromDate, endDate: this.toDate, subLevelId: this.selectedSafeBoxId, enableSubLevel: true}
    },
  }
}
</script>
