<template>
  <!-- <div class="contract text-right" > -->
  <div
    class="text-right"
    dir="rtl"
    style="lineheight: 3; font-size: 20px !important"
  >
    <!-- <p style="lineheight: 3" class="p1 text-center" > -->
    <h4 class="p1 text-center mb-5">عقد بيع و شراء الوحدات السكنية</h4>
    <!-- </p> -->
    <p style="lineheight: 3" class="p2">&nbsp;</p>
    <b>
      <p style="lineheight: 3" class="p3">
        <span class="s2">رقم</span> <span class="s2">العقد</span>
        <span class="s3">:{{ " " + contract.contractNumber }}</span>
        <span class="Apple-converted-space">
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </span>
        <span class="s2">التأريخ</span>
        <span class="s3">
          : {{ moment(contract.buyingDate).format("YYYY/MM/DD") }}
        </span>
      </p>
      <p style="lineheight: 3" class="p4">
        <span>
          <strong>أولاً</strong>
        </span>
        <span class="s3">/ </span>الأطراف
      </p>
    </b>
    <p style="lineheight: 3" class="p5">
      ألطرف الأول<span> (</span>البائع
      <span> ) :<span class="Apple-converted-space">&nbsp; </span> </span>
      شركة شجرة الزيتون للتجارة العامة و الاستثمارات العقارية و الصناعية
      <span> .</span>
    </p>
    <p style="lineheight: 3" class="p6">
      <span class="s7">قسم</span> <span class="s7">المبيعات</span> :
      <span class="s7">رقم</span> <span class="s7">الموبايل</span> :
      <span class="Apple-converted-space">&nbsp; </span>07503005003 -
      07503005004
    </p>
    <p style="lineheight: 3" class="p5">
      ألطرف الثاني<span> (</span>المشتري
      <span>
        ) :&nbsp;
        {{
          contract.customer &&
          contract.customer.firstName +
            " " +
            contract.customer.middleName +
            " " +
            contract.customer.lastName
        }}
      </span>
    </p>
    <p style="lineheight: 3" class="p5">
      ألعنوان
      <span> : {{ contract.customer && contract.customer.address }} </span>
    </p>
    <p style="lineheight: 3" class="p5">
      رقم الموبايل
      <span>
        :
        {{
          contract.customer &&
          `${contract.customer.phone.replace("+", "00")} - ${
            contract.customer.phone2 &&
            contract.customer.phone2.replace("+", "00")
          }`
        }}
      </span>
    </p>
    <p style="lineheight: 3" class="p5">الوثائق<span>:</span></p>
    <p style="lineheight: 3" class="p5">
      رقم هوية الأحوال المدنية
      <span> : {{ contract.customer && contract.customer.idcard }} </span>
    </p>
    <p style="lineheight: 3" class="p5">
      رقم الجنسية العراقية
      <span>
        :{{ contract.customer && contract.customer.jnsia }}
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span>
      </span>
    </p>
    <p style="lineheight: 3" class="p5">
      رقم بطاقة المعلومات
      <span>
        : {{ contract.customer && contract.customer.informationCard }}
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp;</span>
      </span>
    </p>
    <p style="lineheight: 3" class="p5">
      رقم البطاقة التموينية
      <span>
        <span class="Apple-converted-space">&nbsp; </span>:
        {{ contract.customer && contract.customer.foodCard }}
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span>
      </span>
    </p>
    <p style="lineheight: 3" class="p5">
      رقم بطاقة الإقامة
      <span>
        : {{ contract.customer && contract.customer.placementCard }}
      </span>
    </p>
    <p style="lineheight: 3">
      تأييد الآسايش
      <span>
        :&nbsp;{{ contract.customer && contract.customer.securitySupport }}
      </span>
    </p>
    <p style="lineheight: 3; marginbottom: 116px">
      أربع<span> (4) </span>صور شخصية ملونة
      <span> : </span>
    </p>
    <b>
      <p style="lineheight: 3">
        <span>
          <strong>ثانياً</strong>
        </span>
        <span> / </span>معلومات المشروع<span>:</span>
      </p>
    </b>
    <ul style="list-style-type: none">
      <li>
        ١- اسم المشروع
        <span> :<span class="Apple-converted-space">&nbsp; </span>( </span>
        مدينة زيتون بلص <span>).</span>
      </li>
      <li class="li8">
        <span class="s7">٢- رقم إجازة الاستثمار</span> :
        <span class="Apple-converted-space">&nbsp; </span>(221)
        <span class="s7">في</span> (29/12/2009).
      </li>
    </ul>
    <p style="lineheight: 3">
      موقع المشروع
      <span> :<span class="Apple-converted-space">&nbsp; </span> </span>
      محافظة أربيل<span> / </span>ناحية دارتو
      <span>.</span>
    </p>
    <b>
      <p style="lineheight: 3">
        <span>
          <strong>ثالثاً</strong>
        </span>
        <span> / </span>موضوع العقد<span> :</span>
      </p>
    </b>
    <p style="lineheight: 3">
      شركة<span> (</span>شجرة الزيتون للتجارة العامة و الإستثمارات العقارية و
      الصناعية<span>) </span>و حسب إجازة الإستثمار المرقمة
      <span> (221) </span>في<span> (29/12/2009) </span>
      الصادرة من هيئة الإستثمار في حكومة أقليم كوردستان
      <span>, </span>تقوم ببناء الوحدات السكنية في الموقع المخصص للمشروع بمحافظة
      أربيل<span> / </span>ناحية دارتو <span>, </span>و بيعها للمواطنين و بناءاً
      على رغبة الطرف الثاني و موافقته بعد معاينتها و النظر إلى تصميم الوحدة و
      كذلك خارطة موقع المشروع<span> (Site Plan) </span>وقطعة الأرض و قيمة الوحدة
      و آلية دفع الكلفة و موافقة الطرف الأول على هذا البيع <span>, </span>و إتفق
      الطرفان الرسميان للعقد على الإلتزام القانوني بمضمون العقد و بالشكل
      التالي<span>:</span>
    </p>
    <ul>
      <li>
        قام الطرف الأول ببيع الوحدة السكنية المرقمة
        <span> ({{ contract.property && contract.property.name }}) </span>
        وفي زون
        <span> ({{ contract.property && contract.property.zone }}) </span>
        <!-- على شـــــــــــــارع
          <span >
            
            ({{contract.property && contract.property.streetNumber}})
          </span>
          بلوك
          <span >
            
            ({{contract.property && contract.property.block}})
          </span> -->
        الى الطرف الثاني و التي تم إنشاءها في مشروع<span> (</span>
        مدينة زيتون بلص
        <span>) </span>و التي تقع علــــى قطعة من قطع أراضي مدينة الزيتون بمساحة
        <span>
          (
          <span class="Apple-converted-space">
            {{ contract.property && contract.property.area }} m<sup>2</sup>
          </span>
          ) {{ areaText }}
        </span>
        متر مربع و مكونة من طابقين كما ورد في تصميم البناء المرفق
        <span>, </span>و سيتم تثبيت رقم الملكية بشكل نهائي بعد إكمال الإفراز و
        التوحيد و تسجيلها لدى الدائرة المختصة للتسجيل العقاري بشكل رسمي<span
          >.</span
        >
      </li>
    </ul>
    <b>
      <br />
      <br />
      <br />
      <br />
      <p style="lineheight: 3">
        <strong>مواصفات</strong> <strong>الوحدة</strong>
        <strong>السكنية</strong>
        <span>
          <strong>:</strong>
        </span>
      </p>
    </b>
    <ul style="list-style-type: none; padding: 0">
      <li class="li11"><span class="s2">ا</span>- Raft foundation</li>
      <li class="li12">
        ب<span class="s1">- </span>الشبابيك مصنوعة من المينيوم
        <span> .</span>
      </li>
      <li class="li12">
        ج<span class="s1">- </span>سقف<span> (</span>جبسن بورد
        <span>).</span>
      </li>
      <li class="li12">د<span class="s1">- </span>واجهة فوكا<span> .</span></li>
      <li class="li12">
        ه<span class="s1">- </span>الارضية بورسيلين<span> .</span>
      </li>
      <li class="li12">
        و<span class="s1">- </span>ابواب صناعة تركية<span> .</span>
      </li>
    </ul>
    <ul style="list-style-type: none">
      <li class="li12">
        2- يجب على المشترى دفع اجور الخدمات وبخلاف ذلك يحق للشركة قطع الخدمات
        <span> .</span>
      </li>
      <li class="li12">
        3- لايحق للمشتري القيام بأي تغير بالشكل خارجي الوحدة السكنية.
        <span>.</span>
      </li>
      <li class="li13">
        4- سعر الوحــــــــدة
        <span>
          (
          {{ contract && formatter.format(contract.buyingPrice) }},
          {{ contract && unitPrice }}
          )
        </span>
        <span>, </span>و يدفع الطرف الثاني المبلغ إلى الطرف الأول على النحو
        اللتالي<span>:-</span>
      </li>
    </ul>
    <b>
      <p style="lineheight: 3">
        <span>
          <strong>رابعا</strong>
        </span>
        <span> / </span>شروط الدفـــــع<span> :</span>
      </p>
    </b>
    <ul style="list-style-type: none">
      <li
        v-for="(contractPrePayment, index) in contractPrePayments"
        :key="index"
      >
        <div v-if="index == 0">
          {{ bulletclasses[index] }} ) الدفعة {{ bulletclassesDays[index] }} :
          دفع مبلغ
          <span>
            ({{ formatter.format(contractPrePayment.currentPayPrice) }})
          </span>
          {{ contractPrePayment.arPriceText }} عند توقيع عقد البيع و الشراء بين
          الطرفين <span>, </span>أي يدفع الطرف الثاني إلى الطرف الأول
          <span> </span>
          <span>بتأريخ</span>
          <span>
            {{
              moment(contractPrePayment.currentPayDate).format("YYYY/MM/DD")
            }}.
          </span>
        </div>
        <div v-else>
          {{ bulletclasses[index] }} ) الدفعة {{ bulletclassesDays[index] }} :
          دفع مبلغ
          <span>
            ({{ formatter.format(contractPrePayment.currentPayPrice) }})
          </span>
          {{ contractPrePayment.arPriceText }}
          <span>بتأريخ</span>
          <span>
            {{
              moment(contractPrePayment.currentPayDate).format("YYYY/MM/DD")
            }}.
          </span>
        </div>
      </li>
      <!--Delivery key-->
      <li v-if="contractDeliveryKeyPayments !== null">
        {{ bulletclasses[contractPrePayments.length] }} ) الدفعة
        {{ bulletclassesDays[contractPrePayments.length] }} : عند انتهاء الوحدة
        السكنية بمدة أقصر وأقل من المدة المحددة عندها يجب على الطرف الثاني ومع
        استلامه المفتاح الوحدة السكنية (فيلا) تسديد مبلغ المتبقي وقدره
        <span>
          ({{contractDeliveryKeyPayments&& formatter.format(contractDeliveryKeyPayments.currentPayPrice) }})
        </span>
        {{contractDeliveryKeyPayments&& contractDeliveryKeyPayments.arPriceText }} الى الطرف الاول
        <span> ( </span>الشركة <span> ) </span>وايضا على الطرف الثاني الالتزام
        بتسديد جميع الاقساط المتبقية الى الطرف الاول والموضحة في الفقرة
        <span> (الرابعة) , </span>اي يجب أن لايبقى اية مستحقات في ذمة الطرف
        الثاني<span> . </span>
      </li>
    
      <!-- <li className="p1" >
          <br />
          فى حين اكمال هيكل الوحدة السكنية المرقمة (
          {{ contract.property && contract.property.name}})اى بعد اكمال عمل
          كونكريت للطابق الاول و الثانى من الوحدة السكنية ، على الطرف الثاني دفع
          مبلغ قدره (
          {{contract.isItPayment &&
            formatter.format(contract.isItPayment.currentPayPrice)}}
          ) {{contract.isItPayment && contract.isItPayment.arPriceText}} للطرف الاول
          (الشركة)
        </li> -->
      <li v-for="(paymentProccesFiltered, key) in contractPayments" :key="key">
        {{ bulletclasses[key + contractPrePayments.length + 1] }}) القسط
        {{ numToOrderString(key + 1) }}
        <!-- {{ removeAfterMonth !== true &&
            `
            بعد 
          ${
            calculateDate(paymentProccesFiltered.currentPayDate, contract.prePaymentDate)
          }
          شهرا بعد التوقيع على العقد
          `}} -->
        حيث يبلغ
        <span class="s1">
          ({{ formatter.format(paymentProccesFiltered.currentPayPrice) }})
        </span>
        {{ paymentProccesFiltered.arPriceText }} بتأريخ
        <span class="s1">
          {{
            moment(paymentProccesFiltered.currentPayDate).format("YYYY-MM-DD")
          }}
        </span>
        <br />
        <br />
      </li>
    </ul>

    <p>
      5) هذا العقد هو بمثابة وثيقة ضمان للطرف الثاني حيث يمنحه الحق و الملكية في
      وحدة السكنية.
      <span>.</span>
    </p>
    <p>
      6) يكون تسليم الوحدة السكنية بعد (14) اربعة عشر شهرا ( ايام عمل فقط ) من
      تأريخ 1/10/2022
    </p>
    <p>
      7) في حالة تأخر الطرف الثاني من دفع القسط المطلوب حسب جدول هذا العقد و بعد
      إشعاره من قبل الشركة<span>, </span>يجب على الطرف الثاني دفع مبلغ<span>
        (100$) </span
      >مئة دولار أمريكي عن كل يوم تأخير إلى الطرف الأول<span>, </span>و يستمر
      الحال على هذا النحو لمدة شهر واحد و بعد هذه المدة يحق للطرف الأول ان يقوم
      بإشعار الطرف الثاني بفسخ العقد و ابلاغ المديرية العامة للإستثمار/اربيل
      بذالك, و يحق الطرف الاول بيع الوحدة لشخص ثالث و هذة متفق عليه من قبل
      الطرفين هذه العقد.
    </p>
    <p>
      8) في حالة طلب فسخ العقد من قبل الطرف الثاني لأي سبب كان
      <span>, </span>يجب على الطرف الثاني دفع <span> (30%) </span>ثلاثون بالمئة
      من سعر الوحدة إلى الطرف الأول كغرامة بغرض التعويض عن المصروفات الإدارية و
      الخدمات المتعلقة بإجراءات العقد<span>, </span>و يتم إرجاع المبلغ المتبقي
      إليه بعد بيع الوحدة السكنية إلى شخص آخر<span>.</span>
    </p>
    <p>
      9) في حالة عدم دفع الطرف الثاني المسحقات التي وقعت على عاتقه في غضون شهر
      واحد سيتم اتخاذ الإجراءات القانونية بحقه و يعتبر العقد باطلاَ, و في هذه
      الحالة يقوم الطرف الاول بغرامة مالية الى الطرف الثاني و قدره (30%) ثلاثين
      بالمئة من سعر الإجمالى الوحدة السكنية كتعويض عن المصاريف الإدارية و
      الأضرار التي لحقت بالطرف الأول جراء ذلك و يتم إرجاع متبقي المبلغ إلى الطرف
      الثاني بعد بيع الوحدة إلى مشتري جديد , و للطرف الأول (الشركة) إتخاذ تلك
      الإجراءات.
    </p>
    <p>
      10) في حالة تأخر الطرف الأول<span> (</span>الشركة <span>) </span>عن تسليم
      الوحدة السكنية للطرف الثاني <span> (</span>المشتري<span>) </span>في الموعد
      المحدد لهذا الغرض<span>, </span>كما ورد في النقطة
      <span> (6) السادسة </span>من هذا العقد و كان الإهمال بسبب الطرف الأول
      <span>, </span>ولكن ماعدا في حالة الظروف القاهرة <span>, </span>عندها يكون
      الطرف الأول ملزم بدفع مبلغ <span> (100$) </span>مئة دولار أمريكي يومياً
      الى الطرف الثاني <span> (</span>المشتري<span>) </span>لحين بلوغ نسبة<span>
        (5%) </span
      >من قيمة الوحدة و يتم تسوية هذه الغرامة عند دفع القسط الأخير<span>.</span>
    </p>
    <p>
      11) يقوم الطرف الأول بتسليم الوحدة السكنية إلى الطرف الثاني (المشتري) حسب
      بنود هذا العقد و الطرف الثاني ملزم بإستلام الوحدة و التوقيع على إستمارة
      إستلام الوحدة قبل موعد إنتهاء مدة هذه العقد, و اتمام دفع جميع المستحقات
      المتبقية من قيمة الوحدة كما هو وارد في هذا العقد , مع مراعاتهم للتعليمات
      العامة للمديرية العامة للإستثمار/اربيل .
    </p>
    <p>
      12) يأخذ الطرف الثاني على عاتقه كافة المصروفات و الرسومات و الأتعاب فيما
      تخص الدائرة المختصة لتسجيل العقاري و الدوائر و المؤسسات المعنية ضمن إطار
      هذا العقد<span>.</span>
    </p>
    <p>
      13) لا يحق للطرف الثاني التنازل عن هذا العقد لطرف ثالث دون إشعار و الحصول
      على موافقة سبقية من الطرف الأول (الشركة ) و بشكل خطي, و على الطرف الثاني
      أن يكون قد دفع قيمة وحدته كاملة إلى الطرف الأول (الشركة).
    </p>
    <p>
      14) لا يتم تسجيل الوحدة السكنية بإسم الطرف الثاني إلا بعد إكمال بناء
      الوحدة و دفع كامل قيمة الوحدة من قبل الطرف الثاني
      <span> (</span>المشتري<span>) </span>إلى الطرف الأول<span>.</span>
    </p>
    <p>
      15) تكون مدة صيانة الوحدة السكنية سنة واحدة من تأريخ إكمال الوحدة السكنية
      من قبل الطرف الأول, و يكون الطرف الأول مسؤولاً عن الأضرار التي تلحق
      بالوحدة السكنية (البناء) جراء التنفيذ, و لا تتحمل الشركة الاضرار التي تلحق
      بالوحدة (بسبب الكوارث الطبيعية) او سبب سوء استخدام الطرف الثاني بالوحدة و
      في هذه الحالة يقوم الطرف الثاني هو المسؤل عنه و لا يتحمل الطرف الاول
      (الشركة) اي مصاريف.
    </p>
    <p>
      16) في حالة حدوث أي نوع من الخلافات بين طرفي هذا العقد الرسميين
      <span>, </span>فيتم أولاً حسمه بطريقة ودية <span>, </span>ولكن إن لم يتم
      حسمه فستكون محكمة بداءة أربيل هي الجهة المختصة و الحاسمة لتسوية الخلاف بين
      الطرفين الرسميين لهذا العقد
      <span>.</span>
    </p>
    <p style="lineheight: 3">
      يتكون هذا العقد من إسم و عنوان و مقدمة و ({{ pageNumber }}
      {{ pageNumberText }}) صفحات

      <span> (16) </span> ستة عشرة نقطة

      <span>, </span>حيث تعتبر كل من الصفحات
      <span>
        <span class="Apple-converted-space">&nbsp; </span>
      </span>
      ({{ pageNumber }} {{ pageNumberText }}) أصلية و تمتلك نفس القوة القانونية
      قانوناً <span>, </span>وكذلك بعد القراءة الدقيقة و الفهم من قبل الطرفين
      الرسميين لهذا العقد على مجمل محتوى هذا العقد <span>, </span>تم توقيع العقد
      بين الطرفين في مدينة أربيل بتأريخ
      <span> ({{ moment(contract.buyingDate).format("YYYY-MM-DD") }}). </span>
    </p>
    <p style="line-height: 3" class="p10">&nbsp;</p>
    <p style="line-height: 3" class="p10">&nbsp;</p>
    <p style="line-height: 3" class="p10">&nbsp;</p>
    <div style="display: flex; justify-content: space-around; height: 120px">
      <div
        style="
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: space-between;
        "
      >
        <b>
          <span class="s2">الطرف الثاني</span>
        </b>
        <span class="s2">
          {{
            contract.customer &&
            `ألإسم: ${contract.customer.firstName} ${contract.customer.middleName} ${contract.customer.lastName}`
          }}
        </span>
        <span class="s2">
          العنوان: {{ contract.customer && contract.customer.address }}
        </span>
        <!-- <span class="s2">:التوقيع</span> -->
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: space-between;
        "
      >
        <b>
          <span class="s2">الطرف الأول</span>
        </b>
        <span class="s2">شركة شجرة الزيتون</span>
        <span class="s2">للتجارة العامة و الإستثمار العقاري و الصناعي</span>
        <!-- <span class="s2">:التوقيع</span> -->
      </div>
    </div>
    <!-- <p style="lineHeight: 3" class="p10" >
        &nbsp;
      </p>
      <p style="lineHeight: 3" class="p10" >
        &nbsp;
      </p>
      <p style="lineHeight: 3" class="p10" >
        &nbsp;
      </p>
      <p style="lineHeight: 3" class="p16" >
        <strong>الطرف</strong> <strong>الأول</strong> <strong>الطرف</strong>
        <strong>الثاني</strong>
      </p>
      <p style="lineHeight: 3"  >
        ألإسم<span >: </span>
        <strong>شركة</strong> <strong>شجرة</strong> <strong>الزيتون</strong>
      </p>
      <p style="lineHeight: 3"  >
        العنوان<span >: </span>
        <strong>للتجارة</strong> <strong>العامة</strong> <strong>و</strong>
        <strong>الإستثمار</strong> <strong>العقاري</strong> <strong>و</strong>
        <strong>الصناعي</strong>
      </p>
      <p style="lineHeight: 3"  >
        التوقيع<span >: </span>التوقيع<span >:</span>
      </p>
      <p style="lineHeight: 3" class="p10" >
        &nbsp;
      </p> -->
    <br />
    <br />
    <br />
    <br />
    <b>
      <p style="line-height: 3">
        <span class="s12">
          <strong>المرفقات</strong>
        </span>
        <span class="s13">
          <strong>:</strong>
        </span>
      </p>
    </b>
    <ul>
      <li>خارطة المشروع<span>.</span></li>
      <li>
        خارطة<span> (</span>تصميم<span>) </span>الوحدة
        <span>.</span>
      </li>
      <li>
        المستمسكات<span> (</span>هوية الأحوال المدنية <span>, </span>الجنسية
        العراقية<span>, </span> بطاقة المعلومات<span>, </span>البطاقة التموينية
        <span> , </span>جواز السفر<span>, </span>بطاقة الإقامة<span>, </span
        >تأييد الآسايش <span>, </span>تأييد المختار<span>) .</span>
      </li>
    </ul>
    <br />
    <br />
    <div>
      <p style="line-height: 3" class="p17 text-center">
        المديرية العامة الإستثمار<span> / </span>أربيل
      </p>
      <p style="lineheight: 3" class="p18 text-center">
        ( <span class="s7">التصديق</span> ) <span class="s7">بتأريخ</span>
        <span class="Apple-converted-space">&nbsp; &nbsp; &nbsp; </span>/
        <span class="Apple-converted-space">&nbsp; &nbsp; </span>/ 2020
      </p>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import moment from "moment";
import { HTTP } from "../../plugins/axios";

export default {
  name: "ArabicContractZaitonPlusCity",
  props: {
    contract: {},
    removeAfterMonth: {
      type: Boolean,
    },
    pageNumber: {
      type: Number,
    },
    contractPayments: [],
    contractPrePayments: [],
    contractDeliveryKeyPayments: [],
  },
  data() {
    return {
      bulletclasses: [
        "ا",
        "ب",
        "پ",
        "ت",
        "ث",
        "ج",
        "چ",
        "ح",
        "خ",
        "د",
        "ذ",
        "ر",
        "ز",
        "ژ",
        "ص",
        "ض",
        "ط",
        "ظ",
        "ع",
        "غ",
        "ف",
        "ق",
        "ک",
        "گ",
        "ل",
        "م",
        "ن",
        "و",
        "ه",
        "ی",
      ],
      bulletclassesDays: [
        "الاولى",
        "الثانية",
        "الثالثة",
        "الرابعة",
        "الخامسة",
        "السادسة",
        "السابعة",
        "الثامنة",
        "التاسعة",
        "العاشرة",
        "الحادي عشر",
        "الثاني عشر",
        "الثالث عشر",
        "الرابع عشر",
        "الخامس عشر",
        "السادس عشر",
        "السابع عشر",
        "الثامن عشر",
        "التاسع عشر",
      ],
      paymentProccessFiltered: [],
      prePaymentText: 0,
      keyRecieveText: 0,
      unitPrice: 0,
      pageNumberText: 0,
      formatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }),
      areaText: 0,
    };
  },
  methods: {
    numToOrderString(num) {
      var returnedvalue;
      switch (num) {
        case 1:
          returnedvalue = "الأول";
          break;
        case 2:
          returnedvalue = "الثاني";
          break;
        case 3:
          returnedvalue = "الثالث";
          break;
        case 4:
          returnedvalue = "الرابع";
          break;
        case 5:
          returnedvalue = "الخامس";
          break;
        case 6:
          returnedvalue = "السادس";
          break;
        case 7:
          returnedvalue = "السابع";
          break;
        case 8:
          returnedvalue = "الثامن";
          break;
        case 9:
          returnedvalue = "التاسع";
          break;
        case 10:
          returnedvalue = "العاشر";
          break;
        case 11:
          returnedvalue = "احدة عشر";
          break;
        case 12:
          returnedvalue = "اثنا عشر";
          break;
        case 13:
          returnedvalue = "ثلاثة عشر";
          break;
        case 14:
          returnedvalue = "اربعة عشر";
          break;
        case 15:
          returnedvalue = "خمسة عشر";
          break;
        case 16:
          returnedvalue = "ستة عشر";
          break;
        case 17:
          returnedvalue = "سبعة عشر";
          break;
        case 18:
          returnedvalue = "ثمانية عشر";
          break;
        case 19:
          returnedvalue = "تسعة عشر";
          break;
        case 20:
          returnedvalue = "عشرون";
          break;
        case 21:
          returnedvalue = "واحد وعشرون";
          break;
        case 22:
          returnedvalue = "اثنان وعشرون";
          break;
        case 23:
          returnedvalue = "ثلاثة وعشرون";
          break;
        case 24:
          returnedvalue = "أربعة وعشرون";
          break;
        case 25:
          returnedvalue = "خمسة وعشرون";
          break;
        case 26:
          returnedvalue = "ستة وعشرون";
          break;
        case 27:
          returnedvalue = "سبعة وعشرون";
          break;
        case 28:
          returnedvalue = "ثمانية وعشرون";
          break;
        case 29:
          returnedvalue = "تسعة وعشرون";
          break;
        case 30:
          returnedvalue = "ثلاثون";
          break;
        case 31:
          returnedvalue = "واحد وثلاثون";
          break;
        case 32:
          returnedvalue = "اثنان وثلاثون";
          break;
        case 33:
          returnedvalue = "ثلاثة وثلاثون";
          break;
        case 34:
          returnedvalue = "أربعة وثلاثون";
          break;
        case 35:
          returnedvalue = "خمسة وثلاثون";
          break;
        case 36:
          returnedvalue = "ستة وثلاثون";
          break;
        case 37:
          returnedvalue = "سبعة وثلاثون";
          break;
        case 38:
          returnedvalue = "ثمانية وثلاثون";
          break;
        case 39:
          returnedvalue = "تسعة وثلاثون";
          break;
        case 40:
          returnedvalue = "أربعون";
          break;
        case 41:
          returnedvalue = "واحد وأربعون";
          break;
        case 42:
          returnedvalue = "اثنان وأربعون";
          break;
        case 43:
          returnedvalue = "ثلاثة وأربعون";
          break;
        case 44:
          returnedvalue = "أربعة وأربعون";
          break;
        case 45:
          returnedvalue = "خمسة وأربعون";
          break;
        case 46:
          returnedvalue = "ستة وأربعون";
          break;
        case 47:
          returnedvalue = "سبعة وأربعون";
          break;
        case 48:
          returnedvalue = "ثمانية وأربعون";
          break;
        case 49:
          returnedvalue = "تسعة وأربعون";
          break;
        case 50:
          returnedvalue = "خمسون";
          break;
        case 51:
          returnedvalue = "واحد وخمسون";
          break;
        case 52:
          returnedvalue = "اثنان وخمسون";
          break;
        case 53:
          returnedvalue = "ثلاثة وخمسون";
          break;
        case 54:
          returnedvalue = "أربعة وخمسون";
          break;
        case 55:
          returnedvalue = "خمسة وخمسون";
          break;
        case 56:
          returnedvalue = "ستة وخمسون";
          break;
        case 57:
          returnedvalue = "سبعة وخمسون";
          break;
        case 58:
          returnedvalue = "ثمانية وخمسون";
          break;
        case 59:
          returnedvalue = "تسعة وخمسون";
          break;
        case 60:
          returnedvalue = "ستون";
          break;
        default:
          returnedvalue = "ستون +";
      }
      return returnedvalue;
    },
    calculateDate(date1, date2) {
      console.log(date1, date2);
      Math.ceil(moment(date1).diff(moment(date2), "months", true));
    },
  },
  async mounted() {
    console.log(this.contract);
    if (this.contract.paymentProcess) {
      this.contract.paymentProcess.filter((el) =>
        el.isItKeyPayment === false ? this.paymentProccessFiltered.push(el) : ""
      );
    }
    const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
    HTTP.defaults.headers.common = { Authorization: `Bearer ${token}` };
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.contract.prePaymentPrice}`
    )
      .then((resp) => {
        this.prePaymentText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.contract.keyRecevePrice}`
    )
      .then((resp) => {
        this.keyRecieveText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.contract.buyingPrice}`
    )
      .then((resp) => {
        this.unitPrice = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.pageNumber}&suffix=false`
    )
      .then((resp) => {
        this.pageNumberText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
    await HTTP.get(
      `${process.env.VUE_APP_BASE_API_URL}/Dashboard/NumberToTextAr?number=${this.contract.property.area}&suffix=false`
    )
      .then((resp) => {
        this.areaText = resp.data;
      })
      .catch((error) => {
        console.log(error.response.status);
      });
  },
};
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

p {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
span {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
li {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
div {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
strong {
  font-family: Noto, Speda, sans-serif !important;
  font-size: 17px !important;
}
@font-face {
  font-family: "Noto";
  font-style: normal;
  font-weight: 400;
  src: url(../../assets/fonts/NotoNaskhArabic-VariableFont_wght.ttf)
    format("truetype");
}
</style>
