<template>
  <transition name="fade">
    <div key="1" v-if="showMap">
      <b-row>
        <b-col
          v-for="map in maps"
          v-bind:key="map.id"
          xl="3"
          lg="6"
          md="6"
          sm="12"
        >
          <b-card
            class="text-center mb-2 map p-0"
            v-on:click.once="mapClicked(map.name)"
          >
            <b-card-header>
              {{ map.name }}
              <b-progress
                :value="
                  calculatePercentage(map.totalProperties, map.soldProperties)
                "
                max="100"
                show-progress
                variant="primary"
                height="2rem"
              ></b-progress>
            </b-card-header>
            <b-card-text
              >{{ $t("total") }}: {{ map.totalProperties }}</b-card-text
            >
            <b-row>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-success"
                  >{{ $t("remain") }} <br />
                  {{ map.remainProperties }}</b-card-text
                >
              </b-col>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-blue"
                  >{{ $t("sold") }} <br />
                  {{ map.soldProperties }}</b-card-text
                >
              </b-col>
              <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
                <b-card-text class="mapElement bg-gradient-secondary"
                  >{{ $t("hold") }} <br />
                  {{ map.holdProperties }}</b-card-text
                >
              </b-col>
            </b-row>
          </b-card>
          <b-row class="text-center" v-if="projectTenant() == 'Candle'">
            <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
              <b-card-text class="mapElement bg-gradient-info">
                {{ $t("totalArea") }} <br />
                {{ map.totalBuildUpArea.toLocaleString() }}&nbsp;{{ $t('m') }}<sup>2</sup>
              </b-card-text>
            </b-col>
            <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
              <b-card-text class="mapElement bg-gradient-info">
                {{ $t("holdArea") }} <br />
                {{ map.holdBuildUpArea.toLocaleString() }}&nbsp;{{ $t('m') }}<sup>2</sup>
              </b-card-text>
            </b-col>
            <b-col class="my-1" xl="4" lg="12" md="12" sm="12">
              <b-card-text class="mapElement bg-gradient-info">
                {{ $t("soldArea") }} <br />
                {{ map.soldBuildUpArea.toLocaleString() }} &nbsp;{{ $t('m') }}<sup>2</sup>
              </b-card-text>
            </b-col>
            <b-col class="my-1" xl="12" lg="12" md="12" sm="12">
              <b-card-text class="mapElement bg-gradient-info">
                {{ $t("totalRemainArea") }} <br />
                {{ (parseFloat(map.totalBuildUpArea) - parseFloat(map.holdBuildUpArea) - parseFloat(map.soldBuildUpArea)).toLocaleString() }} &nbsp;{{ $t('m') }}<sup>2</sup>
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div key="2" v-else class="houses">
      <b-row class="mb-2">
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <div class="col-sm-12">
            <input
              id="searchProperty"
              v-model.trim="searchProperty"
              :placeholder="$t('search')"
              type="text"
              autocomplete="off"
              class="form-control"
              @focus="$event.target.select()"
            />
          </div>
        </b-col>
        <b-col lg="1" md="2" sm="6" class="text-center mb-2">
          <button
            type="button"
            style="width: max-content !important"
            class="btn btn-primary"
            @click="backToMaps"
          >
            {{ $t("back") }}
          </button>
        </b-col>
        <b-col lg="1" md="2" sm="6" class="text-center mb-2">
          <button
            type="button"
            style="width: max-content !important"
            class="btn btn-info"
            @click="filterMaps(1)"
          >
            {{ $t("soldProperties") }}
            <span class="badge badge-light">{{ sold }}</span
            ><span class="sr-only"> </span>
          </button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <button
            type="button"
            style="width: max-content !important"
            class="btn btn-success"
            @click="filterMaps(2)"
          >
            {{ $t("remainedProperties") }}
            <span class="badge badge-light">{{ remained }}</span
            ><span class="sr-only"> </span>
          </button>
        </b-col>
        <b-col lg="1" md="2" sm="6" class="text-center mb-2">
          <button
            type="button"
            style="width: max-content !important"
            class="btn btn-secondary"
            @click="filterMaps(3)"
          >
            {{ $t("onHoldProperties") }}
            <span class="badge badge-light">{{ onHold }}</span
            ><span class="sr-only"> </span>
          </button>
        </b-col>
        <b-col lg="1" md="2" sm="6" class="text-center mb-2">
          <button
            type="button"
            style="width: max-content !important"
            class="btn btn-danger"
            @click="filterMaps(0)"
          >
            {{ $t("reset") }}
          </button>
        </b-col>
        <b-col lg="2" md="4" sm="12" class="text-center mb-2">
          <b-form-select
            v-model="typeId"
            :options="types"
            @change="typeSelected"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="mapHouse in searchMap"
          v-bind:key="mapHouse.name"
          cols="2"
        >
          <b-card
            style="max-width: 20rem; height: 81px; position: relative"
            class="text-white allCards"
            :style="{
              'background-color': mapHouse.isSold
                ? '#17A2B8'
                : mapHouse.isHold
                ? '#5a6268'
                : '#28A745',
            }"
          >
            <div class="container">
              <span
                @click="
                  $router.push({
                    name: 'properties.edit',
                    params: { id: mapHouse.id },
                  })
                "
                style="cursor: pointer"
              >
                <b style="font-size: 20px"> {{ mapHouse.name }} </b><br />
                <span
                  >{{ mapHouse.typeName }} &nbsp;
                  <span>{{ mapHouse.buildUpArea }}m<sup>2</sup></span></span
                ><br />
                <span :title="mapHouse.customerName">{{
                  mapHouse.customerName !== null
                    ? mapHouse.customerName.length > 17
                      ? "..." + mapHouse.customerName.substr(0, 16)
                      : mapHouse.customerName
                    : ""
                }}</span>
              </span>
              <div :class="langCheck === 'en' ? 'icons' : 'iconsRTL'">
                <div class="p-0">
                  <p v-if="mapHouse.isSold" class="d-block m-1 mr-2">
                    <router-link
                      class="text-white"
                      :to="{
                        name: 'buyContracts.show',
                        params: { id: mapHouse.contractId },
                      }"
                    >
                      <i class="fas fa-file-contract fa-lg"></i>
                    </router-link>
                  </p>
                  <p v-if="mapHouse.isSold" class="d-block m-1">
                    <router-link
                      class="text-white"
                      :to="{
                        name: 'customers.edit',
                        params: { id: mapHouse.cutomerId },
                      }"
                    >
                      <i class="fas fa-user fa-lg"></i>
                    </router-link>
                  </p>
                  <p v-if="mapHouse.isHold" class="d-block m-1">
                    <span
                      style="cursor: pointer"
                      class="text-white"
                      v-b-modal.hold-info-modal
                      @click="getHoldInfo(mapHouse.holdId)"
                    >
                      <i class="fas fa-hand-holding-usd fa-lg"></i>
                    </span>
                  </p>
                  <p class="d-block">
                    <a
                      class="text-white"
                      :href="getImageUrl(mapHouse.typeAttachment)"
                      target="_blank"
                    >
                      <i class="fas fa-map fa-lg"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-modal id="hold-info-modal" :title="$t('holdInfo')" size="lg">
        <div class="d-block">
          <p class="lead">{{ $t("name") }}: {{ holdInfo && holdInfo.name }}</p>
          <p class="lead">
            {{ $t("holdPayment") }}:
            {{ holdInfo.holdPayment && holdInfo.holdPayment.toLocaleString() }}
          </p>
          <p class="lead">
            {{ $t("date") }}: {{ holdInfo && formatDate(holdInfo.date) }}
          </p>
          <p class="lead">
            {{ $t("deadline") }}:
            {{ holdInfo && formatDate(holdInfo.deadline) }}
          </p>
          <p class="lead">
            {{ $t("note") }}:
            {{ holdInfo && holdInfo.note ? holdInfo.note : "-" }}
          </p>
        </div>
        <template #modal-footer="{ cancel }">
          <b-button variant="secondary" @click="cancel()">Close</b-button>
        </template>
      </b-modal>
    </div>
  </transition>
</template>

<script>
import lodash from "lodash";

export default {
  name: "CandleMap",
  props: {},
  data() {
    return {
      maps: [],
      showMap: true,
      sold: 0,
      remained: 0,
      onHold: 0,
      mapHouses: [],
      searchMap: [],
      typeId: 0,
      types: [],
      holdInfo: [],
      searchProperty: null,
      langCheck: localStorage.getItem("locale") || "",
    };
  },
  mounted() {
    this.loadMapInfo();
  },
  watch: {
    searchProperty: function (newVal) {
      if (newVal !== "" && newVal !== undefined && newVal !== null) {
        this.searchMap = lodash.filter(
          this.mapHouses,
          (x) => x.name.indexOf(newVal) > -1
        );
      } else {
        this.searchMap = this.mapHouses;
      }
    },
  },
  methods: {
    loadMapInfo() {
      this.$store.dispatch("dashboard/GET_MAP_INFO").then((response) => {
        this.maps = response.data;
      });
    },
    mapClicked(zone) {
      this.$store
        .dispatch("dashboard/GET_PROPERTY_INFO_BY_ZONE", zone)
        .then((response) => {
          document.getElementById("hideOnClick").style.display = "none";
          document
            .getElementById("changeClassOnClick")
            .classList.remove("col-xl-9");
          document
            .getElementById("changeClassOnClick")
            .classList.add("col-xl-12");
          document.getElementById("");
          this.types = [];
          this.mapHouses = response.data;
          this.searchMap = response.data;
          this.types = lodash.map(
            lodash.uniqBy(response.data, "typeName"),
            function (o) {
              return {
                value: o.typeId,
                text: o.typeName,
              };
            }
          );
          this.sold = lodash.filter(response.data, (x) => x.isSold).length;
          this.remained = lodash.filter(
            response.data,
            (x) => !x.isSold && !x.isHold
          ).length;
          this.onHold = lodash.filter(response.data, (x) => x.isHold).length;
          this.showMap = false;
        });
    },
    filterMaps(type) {
      if (type === 1) {
        this.searchMap = lodash.filter(this.mapHouses, (x) => x.isSold);
      } else if (type === 2) {
        this.searchMap = lodash.filter(
          this.mapHouses,
          (x) => !x.isSold && !x.isHold
        );
      } else if (type === 3) {
        this.searchMap = lodash.filter(this.mapHouses, (x) => x.isHold);
      } else {
        this.searchMap = this.mapHouses;
      }
    },
    backToMaps() {
      this.mapHouses = [];
      document.getElementById("hideOnClick").style.display = "block";
      document.getElementById("changeClassOnClick").classList.add("col-xl-9");
      document
        .getElementById("changeClassOnClick")
        .classList.remove("col-xl-12");
      this.showMap = true;
    },
    calculatePercentage(total, value) {
      return (value / total) * 100;
    },
    typeSelected() {
      this.searchMap = lodash.filter(
        this.mapHouses,
        (x) => x.typeId === this.typeId
      );
    },
    getHoldInfo(holdId) {
      this.$store.dispatch("propertyHolds/GET_ONE", holdId).then((response) => {
        this.holdInfo = response.data;
      });
    },
  },
};
</script>
<style scoped>
.map {
  transition: 0.3s;
}

.map:hover {
  background-color: #236398;
  transform: scale(1.05);
  color: #ffffff;
  cursor: pointer;
}

.mapElement {
  border-radius: 5px;
  padding: 4px;
  box-shadow: 1px 1px 1px 1px #000000;
  color: white;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.icons {
  width: 38px;
  position: absolute;
  right: -10px;
  top: 4px;
}
.icons a i {
  opacity: 0.7;
}
.icons a:hover i {
  opacity: 1;
}
.iconsRTL {
  width: 38px;
  position: absolute;
  left: -10px;
  top: 4px;
}
.iconsRTL a i {
  opacity: 0.7;
}
.iconsRTL a:hover i {
  opacity: 1;
}
.allCards .card-body {
  padding: 1px !important;
}
</style>
