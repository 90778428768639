<template>
  <div class="contract text-right" dir="rtl">
    <h1
      style="text-align: center !important; font-size: 25px"
      class="text-center text-bold"
    >
      عقد بيع شقة بالأقساط
      <!-- {{ buyType === "Installment" ? "بالأقساط" : "نقد" }} -->
    </h1>
    <p class="lead" style="text-align: right">
      <strong>رقم العقد/</strong> {{ buyContract.contractNumber }}
    </p>
    <p class="lead" style="text-align: right">
      <strong>التاریخ/</strong> {{ formatDate(buyContract.buyingDate) }}
    </p>
    <p class="lead" style="text-align: right">
      إنه في يوم الموافق ( {{ formatDate(buyContract.buyingDate) }} ) وفي محافظة
      اربيل / اقليم كوردستان / العراق تم الاتفاق بين كل من:
    </p>
    <p class="lead" style="text-align: right">
      <strong>الطرف الأول/</strong> البائع/ <strong>شركة (Candle)</strong>، و
      يمثله المدير المفوض السيد (رزگار عمر قادر) إضافة لوظيفته ، و المسجل برقم
      (3103) لدى دائرة تسجيل الشركات أربيل بتاريخ (30/8/2006)
    </p>
    <p v-if="buyContract.showDiscount">
      <strong>الطرف الثاني/</strong>
      شركة داري للتطوير و التسويق و الاستثمار العقاري، شركة مسجلة في العراق و
      مرخصة من قبل مسجل شركات للعمل كمسوق و مطور و مستثمر عقاري وفق الرخصة
      المرقمة (م.ش أ/02-16356) بتاريخ 07/03/2022، يمثلة السيد معن عبدالستار
      غفورى مدير مفوض للشركة داري إضافة لوظيفته، يشار لة فيما بعد بطرف الثاني.
      عنوانه: بغداد العرصات الهندية حي بابل، رقم التليفون:07812002054.
    </p>

    <div v-else>
      <p
        class="lead"
        style="text-align: right"
        v-if="!customer.isSharedCustomer"
      >
        <span
          ><strong>الطرف الثاني/</strong> المشتري / السيد/السيدة
          <strong
            >({{
              customer.firstName +
              " " +
              customer.middleName +
              " " +
              customer.lastName
            }})</strong
          ></span
        >
        <span> تولد /{{ formatDate(customer.dob) }}</span>
        <span> يحمل ({{ customer.idcard }})</span>
        <span> العنوان الدائم ({{ customer.address }})</span>
        <span> رقم الموبايل: {{ replacePhone(customer.phone) }}</span>
      </p>
      <p class="lead" style="text-align: right" v-else>
        <strong>الطرف الثاني/</strong> <br />
        1- المشتري / السيد/السيدة
        <strong>({{ customer.firstName }})</strong>
        <span> تولد /{{ formatDate(customer.dob) }}</span>
        <span> يحمل ({{ separatorString(customer.idcard)[0] }})</span>
        <span>
          العنوان الدائم ({{ separatorString(customer.address)[0] }})</span
        >
        <span> رقم الموبايل: {{ replacePhone(customer.phone) }}</span>
        <br />

        2- المشتري / السيد/السيدة
        <strong>({{ customer.lastName }})</strong>
        <span> تولد /{{ formatDate(customer.howCame) }}</span>
        <span> يحمل ({{ separatorString(customer.idcard)[1] }})</span>
        <span>
          العنوان الدائم ({{ separatorString(customer.address)[1] }})</span
        >
        <span> رقم الموبايل: {{ replacePhone(customer.phone2) }}</span>
      </p>
    </div>

    <h4 style="text-align: right">
      بعد أن أقر الطرفان بأهليتهما للتعاقد والتصرفات القانونية،اتفقا على البنود
      المدرجة أدناه :-
    </h4>
    <p class="lead" style="text-align: right">
      <strong>البند الأول:</strong> باع الطرف الأول إلى الطرف الثاني في مشروع
      (Candle) في البناية (<strong>{{ property.zone }}</strong
      >) في الطابق (<strong>{{ property.block }}</strong
      >) الشقة رقم (<strong>{{ property.name.split("-")[1] }}</strong
      >) والمشيدة على جزء من القطعة المرقمة (32/8853 ك 44 وارش) والبالغ
      مساحتها(<strong>{{ property.buildUpArea }}</strong
      >) متراً مربعا وحسب الخريطة المرفقة بهذا العقد وهي جزء لا يتجزأ من هذا
      العقد بمبلغ اجمالي وقدره (<strong
        >${{ buyContract.buyingPrice.toLocaleString() }}</strong
      >) لاغير بالتقسيط ويتم تسديدها كما مذكور في البند الثاني.
    </p>
    <p v-if="buyType === 'Installment'" class="lead" style="text-align: right">
      <span v-if="customer.isSharedCustomer">
        <strong>البند الثاني:</strong>
        أتفق الطرفان على ان الطرف الثاني يشكلون طرفاَ واحداَ ( تكافلاَ وتضامناَ
        ) في الالتزامات والحقوق على أن يسدد الطرف الثاني المبلغ المذكور اعلاه
        إلى الطرف الأول على رقم الحساب المصرفي الذي يحدده الطرف الأول موزعة على
        المقدمة و (2) قسطا اضافة الى القسط الأخير الذي يتم دفعه حسب المواعيد
        المذكورة أدناه عند تسليم المفتاح وعلى الطرف الثاني تسلم قسط المفتاح بعد
        (30) يوما من تاريخ تبليغه بتسلم الشقة
      </span>
      <span v-else>
        <strong>البند الثاني:</strong> أتفق الطرفان على أن يسدد الطرف الثاني
        المبلغ المذكور اعلاه إلى الطرف الأول على رقم الحساب المصرفي الذي يحدده
        الطرف الأول موزعة على المقدمة و (2) قسطا اضافة الى القسط الأخير الذي يتم
        دفعه حسب المواعيد المذكورة أدناه عند تسليم المفتاح وعلى الطرف الثاني
        تسليم قسط المفتاح بعد (30) يوما من تاريخ تبليغه بتسليم الشقة.
      </span>
      <span style="display: block">وتدفع الأقساط على الشكل الاتي:- </span>
      <span style="display: block"
        >1.القسط المقدمة البالغ
        <strong
          >({{
            contractPrePayments &&
            contractPrePayments[0].currentPayPrice.toLocaleString()
          }}
          $)</strong
        >
        لاغير يدفع عند توقيع العقد.</span
      >
      <span style="display: block"
        >2.القسط الأول البالغ
        <strong
          >({{
            contractPrePayments &&
            contractPrePayments[1].currentPayPrice.toLocaleString()
          }}
          $)</strong
        >
        لاغير يدفع (عند إتمام الهيكل الشقة).</span
      >
      <span style="display: block"
        >3.القسط الثاني البالغ
        <strong
          >({{
            contractDeliveryKeyPayments &&
            contractDeliveryKeyPayments.currentPayPrice.toLocaleString()
          }}
          $)</strong
        >
        لاغير يدفع (عند إستلام المفتاح الشقة).</span
      >
      <span style="display: block">
        4.القسط الأخير
        <strong
          >({{
            contractDeliveryKeyPayments &&
            contractDeliveryKeyPayments.currentPayPrice.toLocaleString()
          }}$)</strong
        >
        دولار امریکی يدفع خلال

        <strong>({{ contractPayments && contractPayments.length }})</strong>

        <span v-if="contractPayments.length == 48"> ثمانيه و اربعون </span>

        <span v-else> ثنين و سبعون </span>

        شهراً بواقع

        <strong
          >({{
            contractPayments &&
            contractPayments[0].currentPayPrice.toLocaleString()
          }}$)</strong
        >
        دولار امریکی وبشكل شهري و يبدء سريانه بعد إستلام المفتاح الشقة.
      </span>
    </p>
    <p v-else>
      <strong>البند الثاني</strong>
      : قبض الطرف الأول کامل بدل شقة الباغ ({{
        buyContract.buyingPrice.toLocaleString()
      }}) دولارا أمریکی نقدا و المشار الیە فی البند الأول
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثالث</strong>: يلتزم الطرف الأول بتسيلم الوحدة السكنية الي
      الطرف الثاني مجهزة (بتبرید و غاز مرکزی و کاونتر مطبخ و طباخ و فرن و مرشحة
      مطبخ مع تجهیزات الحمامات و الصحیات اللازمة).
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الرابع:</strong> على الطرف الثاني دفع الأقساط المترتبة بذمته
      حسب مواعيد استحقاقها لدى المصرف او لدى مركز مبيعات الطرف الاول وفي حالة
      رغبة الطرف الأول بتغيير المصرف فعلى الطرف الثاني الالتزام بذلك وايداع الأ
      قساط لدى المصرف الذي يحدده الطرف الأول.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الخامس:</strong> على الطرف الثاني تسديد و تسليم قسط المفتاح
      بعد (30) ثلاثون يوما من تأريخ تبلغه باستلام الشقة وإذا امتنع عن تسديد هذا
      القسط خلال هذه المدة فيحق للطرف الأول المطالبة بحقوقه المذكورة في البند
      الثاني من هذا العقد.
    </p>
    <p v-if="!customer.isSharedCustomer" class="lead" style="text-align: right">
      <strong>البند السادس:</strong> إذا دفع الطرف الثاني المقدمة وجزء من
      الأقساط وأمتنع عن دفع الباقي لأي سبب كان خلال (7) سبعة أيام من تاريخ
      استحقاقه حسب جدول الأقساط المذكورة في البند الثاني، فللطرف الأول الخيار
      بين فسخ العقد والاكتفاء بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف
      الثاني بباقي الأقساط أو مطالبة الطرف الثاني بالغرامة التأخيرية وبمبلغ
      (15$) خمسة عشرة دولار أمريكي لاغير عن كل يوم تأخير.
    </p>
    <p v-else>
      <strong>البند السادس:</strong> إذا دفع الطرف الثاني المقدمة وجزء من
      الأقساط وأمتنع عن دفع الباقي لأي سبب كان خلال (7) سبعة أيام من تاريخ
      استحقاقه حسب جدول الأقساط المذكورة في البند الثاني، فللطرف الأول الخيار
      بين فسخ العقد والاكتفاء بما قبض تعويضا أو طلب تنفيذ البيع ومطالبة الطرف
      الثاني بباقي الأقساط ( تكافلا وتضامنا ) أو مطالبة الطرف الثاني ( تكافلا
      وتضامنا ) بالغرامة التأخيرية وبمبلغ (15$) خمسة عشرة دولار أمريكي لاغير عن
      كل يوم تأخير.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند السابع:</strong> يلتزم الطرف الأول بتسليم الشقة بعد (36) ستة
      و ثلاثون شهراً من تاريخ توقيع العقد، ويحق للطرف الثاني ان يطالب الطرف
      الأول بالغرامات التأخيرية وبمبلغ (10$) عشرة دولار أمريكي لاغير عن كل يوم
      تأخير بعد مرور (6) أشهر من الموعد المتفق عليه للتسليم على أن لا تزيد عن
      نسبة (2%) إثنان بالمئة من المبلغ الاجمالي للشقة أن ينفذ الطرف الثاني كامل
      التزاماته ولم يتأخر في دفع الأقساط ولو ليوم واحد.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثامن:</strong> يقر الطرف الثاني بأنه عاين الشقة موضوع
      البيع المعاينة التامة على الخريطة النافية للجهالة وأنه وضع يده عليها
      بحالتها على الخريطة دون أن يحق له الرجوع على الطرف الأول بأي شيء بسبب ذلك.
    </p>
    <!-- <p class="lead" style="text-align: right">
      <strong>البند التاسع:</strong> لا يحق للطرف الثاني بيع الشقة الى الغير الا
      بموافقة وشروط يحددها الطرف الأول
    </p> -->
    <p class="lead" style="text-align: right">
      <strong>البند التاسع:</strong> يتعهد الطرف الثاني باحترام جميع القواعد
      والتعليمات التي يحددها الطرف الأول وبالأخص المتعلقة باللوحات التعريفية
      للشقق والمحلات التجارية ولوحات الأعلانات والنشرات في الممرات والطوابق
      والطرق الخارجية والحدائق داخل المشروع وعدم إحداث أية تعديلات في الأجزاء
      والممرات المشتركة بغير موافقة الطرف الأول وكذلك عدم اتخاد أي إجراء يؤثر
      على الجمالية والمظهر العام للبناية والمدخل والسلالم وكذلك مراعاة الحرص على
      مظهر المبنى الخارجي مع المحافظة التامة على سلامة المرافق والحدائق
      العامة،كما لا يحق للطرف الثاني تغيير التصميم الداخلي للشقة و تعميرها دون
      إستحصال موافقة تحريرية من الطرف الأول و عند موافقته فتتم تحت إشرافه و
      مراقبته وبخلافه يكون للطرف الأول الحق في اتخاذ الإجراءات القانونية كافة ضد
      الطرف الثاني مع تحمله كافة المصروفات لإعادة الوضع إلى ما كان عليه ويسري
      هذا البند حتى بعد تسجيل الشقة لدى مديرية التسجيل العقـاري،ولا يجوز للطرف
      الثاني نصب أية أجهزة تؤثر على عمل وخدمات منظومة (IBS) الموجودة لتغطية
      البناية بشبكة الهاتف النقال ويجب عليه عدم المساس بأجهزة ال (Pico Cell)
      الموجودة لنفس الغرض في الواجهة الداخلية من سقف البناية، ويجب عليه وبعد
      إعلامه تحريريا بتمكين الفنيين التابعين لشركات الهاتف النقال (كورك و
      اسياسيل) من دخول المكتب بصورة دورية أو في الحالات الضرورية والسماح لهم
      بنصب اجهزة اخرى اضافية تتعلق بالموقع متى ما اقتضى الأمر ذلك في المستقبل
      دون معارضة شرط أن يكون تنصيب هذه الأجهزة من أجل تقوية أو تطوير أو إصلاح
      خلل في الشبكة).
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند العاشر:</strong> على الطرف الثاني استلام الشقة من تأريخ
      ارسال الطرف الأول بريد الكتروني الى الطرف الثاني باستلام الشقة ووضع اشارة
      (تم الأرسال) أو من تاريخ توجية انذار الى الطرف الثاني بواسطة كاتب العدل
      باستلام الشقة أو تاريخ نشر استلام الشقق بواسطة صحيفتين يوميتين محليتين.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الحادي عشر:</strong> يتعهد الطرف الأول بتسجيل (الشقة)
      المباعة موضوع العقد باسم الطرف الثاني في مديرية التسجيل العقاري المختصة
      بعد اكمال الأجراءات الرسمية بتمليك القطعة في دوائر الدولة وذلك بعد اكمال
      (الشقة) واستلام الطرف الأول كامل بدل البيع المتفق عليه وتكون كافة رسوم
      التسجيل والأتعاب على الطرف الثاني.
    </p>
    <p class="lead" style="text-align: right">
      <strong
        >البند الثاني عشر: (خدمات ما بعد تسليم الشقة قبل وبعد تسجيلها باسم الطرف
        الثاني لدى مديرية التسجيل العقاري) :-</strong
      >
      يقوم الطرف الأول بتقديم خدمات الصيانة الى الطرف الثاني لمدة ستة أشهر
      اعتباراً من تأريخ تبليغه باستلام الشقة وهي تشمل أي خلل يطرأ على البناية ما
      لم يكن ناجماً عن سوء استخدام الطرف الثاني.

      <span
        >كما يقوم الطرف الأول بالأشراف والأتفاق مع الشركات الخدمية لتقديم
        الخدمات والأدارة الى الطرف الثاني مقابل مبلغ شهري يدفعه الطرف الثاني
        سلفاً الى الطرف الأول في بداية كل شهر وتشمل الفقرات التالية عدا اجور
        الماء والكهرباء:</span
      >
      <br />
      <span
        >1.التنظيف الخارجي للشقة ومنها تنظيف الممرات والسلام المشتركة والشوارع
        العامة والسرداب وكراج السيارات وتنظيف الحدائق العامة للمجمع وادامتها
        وتنظيف المجاري و جمع النفايات ورميها خارج المجمع.</span
      >
      <br />
      <span
        >2.الحراسة الأمنية للبوابات الرئيسية لمشروع
        <strong>(Candle)</strong> والأماكن العامة داخل المجمع.</span
      >
      <br />
      <span
        >3.صيانة المصاعد الكهربائية وتنظيفها وصيانة الانارة الخارجية للشقة و
        داخل الممرات وكراج وقوف السيارات وتنظيم السير في شوارع المجمع وفي كراج
        وقوف السيارات الداخلي وادامة الهياكل الخارجية لبنايات الشقق وادامة
        منظومة اطفاء الحرائق وادامة ملاعب الأطفال.</span
      >
      <br />
      <span
        >4.على الطرف الأول تقديم الخدمات المذكورة في هذا العقد الى الطرف الثاني
        بانتظام الا في حالة الظروف القاهرة كالكوارث الطبيعية أو حدوث تقلبات
        سياسية أو اقتصادية أو عسكرية.</span
      >
      <br />
      <span
        >5.على الطرف الثاني الألتزام بكل ما جاء في هذا البند حتى بعد تسجيل الشقة
        بأسمه رسميا،وأن هذا الالتزام يبقى على الطرف الثاني الى أن يقرر الطرف
        الأول عدم الحاجة اليه.</span
      >
      <br />
      <span
        >6.لا يحق للطرف الثاني التنصل من تنفيذ هذا البند وذلك بأبرام عقود
        الخدمات أو الأتفاق مع الشركات أو الأشخاص لتأمين تلك الخدمات.</span
      >
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الثالث عشر (أحكام عامة):</strong> تشكل هذه الأتفاقية
      الألتزامات الصحيحة والملزمة النافذة من قبل الطرفين وفقا لشروطها، ويمتثل
      الطرفان من كافة النواحي المتعلقة بالعقد لأحكام هذه الأتفاقية والقواعد
      وكافة القوانين والتعليمات والمراسيم والنظم والموافقات والرخص السارية حاليا
      في إقليم كوردستان/ العراق.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الرابع عشر (حل الخلافات والنزاعات) :</strong>
      <br />
      <span
        >1.في حال نشوء أي نزاع خلاف بين الطرفين فيما يتعلق بتفسير اية مادة أو
        بند من مواد وبنود هذا العقد فيتم تسويته بالطرق الودية و بعكسه تكون محاكم
        بداءة أربيل هي المختصة بفض النزاع.</span
      >
      <br />
      <span
        >2.يتحمل الطرف الثاني كافة النفقات التي يتكبدها الطرف الأول مثل أجور
        المحاماة والمعقبين نتيجة عدم التزامه ببنود هذا العقد.</span
      >
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند الخامس العشر (المراسلات) :</strong> وافق الطرف الثاني أن يتم
      توجيه أي إشعار أو مراسلة من قبل الطرف ألأول بموجب هذا العقد بشكل خطي و
      باللغة العربية أو الأنكليزية أو الكردية وتسلم شخصياً أو ترسل من خلال خدمة
      إرسال الطرود أو <strong>(SMS)</strong> له عند الحاجة ويعد الأشعار أو
      الأنذار الصادر من الكاتب العدل من قبل الطرف الأول إلى الطرف الثاني قد تم
      استلامة إذا:
      <br />
      <span>1.تم تسليمه بشكل شخصي.</span>
      <br />
      <span
        >2.بعد ثلاثة أيام من إرساله من خلال خدمة أرسال الطرود أو البريد
        المسجل.</span
      >
      <br />
      <span
        >3.عند إرساله <strong>SMS</strong> حسب ما يظهر في تقرير الأرسال (تم
        الأرسال).</span
      >
      <br />
      <span
        >4.عند إرساله بالبريد الألكتروني حسب ما يظهر في تقرير الأرسال (تم
        الأرسال).</span
      >
      <br />
      ويقر ويوافق الطرف الثاني بأنه اتخذ من العناوين المذكورة أدناه في هذا العقد
      محلا له واية مراسلات أو إنذارات أو إخطارات ترسل عليها تعتبر صحيحه و نافذه
      ومنتجة لاثارها القانونية.
    </p>
    <p class="lead" style="text-align: right">
      <strong>البند السادس العشر:</strong> يقر كل طرف بأنه أطلع على بنود هذا
      الأتفاقية إطلاعا نافيا للجهالة قانونا وأنه ملتزم بتنفيذ ما جاء فيها من
      بنود و عليه قام الطرفان بتوقيع هذا العقد بعد الأطلاع على كافة بنود وقد حرر
      بثلاث نسخ بيد كل طرف نسخة للعمل بموجبها عند الأقتضاء ونسخة لدى المستشار
      القانوني وكيل الطرف الأول.
    </p>
    <!-- this is a condition for Dari(bank) asked by kak chalak to we ued show discount just to make it work  -->
    <div v-if="buyContract.showDiscount">
      <p class="lead" style="text-align: right">
        <strong>البند السابع العشر:</strong>
        لا يحق للمشتري بيع الوحدة السكنية الا بعد اشعار مصرف التنمية في حال
        اقتراض (100) مائة مليون دينار عراقي والحصول على براءة الذمة من المصرف
        حصرا والا يعتبر العقد ناكلا وتصادر جميع المبالغ المدفوعة لصالح مصرف
        التنمية.
      </p>
      <p class="lead" style="text-align: right">
        <strong>البند الثامن العشر:</strong>
        يتم ايداع الدفعات حصرا في حساب شركة داري في مصرف التنمية الدولي او في
        مقر شركة داري حصرا وبخلافه يعتبر العقد ناكلا وتصادر جميع المبالغ
        المدفوعة لصالح المصرف
      </p>
    </div>

    <table class="table table-bordered">
      <tr>
        <td v-if="!customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري/
          <strong>{{
            customer.firstName +
            " " +
            customer.middleName +
            " " +
            customer.lastName
          }}</strong>
          <br />
          <br />
          العنوان الدائم/ <strong>{{ customer.address }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/ <strong>{{ customer.idcard }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          رقم الموبايل عند الحاجة/
          <strong>{{ replacePhone(customer.phone_2) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 1 /
          <strong>{{ customer.firstName }}</strong>
          <br />
          <br />
          العنوان الدائم 1 /
          <strong>{{ separatorString(customer.address)[0] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[0] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td v-if="customer.isSharedCustomer" style="text-align: right">
          الاسم المشتري 2 /
          <strong>{{ customer.lastName }}</strong>
          <br />
          <br />
          العنوان الدائم 2 /
          <strong>{{ separatorString(customer.address)[1] }}</strong>
          <br />
          <br />
          رقم البطاقة الشخصية/
          <strong>{{ separatorString(customer.idcard)[1] }}</strong>
          <br />
          <br />
          رقم الموبايل الشخصي/
          <strong>{{ replacePhone(customer.phone2) }}</strong>
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
        <td style="text-align: right">
          شركة <strong>(Candle)</strong>
          <br />
          <br />
          المدير المفوض السيد <strong>(رزگار عمر قادر)</strong>
          <br />
          <br />
          إضافة لوظيفته.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          التوقيع
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "ArabicContractCandleInstallment",
  props: {
    accountant: {
      type: String,
      default: "عمر اسماعیل صالح",
    },
    accountantPhone: {
      type: String,
      default: "07504452330",
    },
    customer: {
      type: Object,
    },
    property: {
      type: Object,
    },
    buyType: {},
    buyContract: {},
    contractPayments: {},
    contractPrePayments: {},
    contractDeliveryKeyPayments: {},
  },
};
</script>

<style scoped>
.w-32 {
  width: 32% !important;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
}

strong {
  font-weight: bold;
}

* {
  font-size: large;
  text-align: justify !important;
}
</style>
